<!-- <form [formGroup]="masterSearchForm" (submit)="submit()">
    <div fxLayout="row" class="search">
        <mat-form-field class="w-100" appearance="outline">
            <input matInput placeholder="Search" formControlName="masterSearch" #sInput autocomplete="off" autofill="off">
            <mat-icon *ngIf="sInput.value.length > 0" matSuffix class="hover" (click)="clear()">clear</mat-icon>
            <mat-icon *ngIf="sInput.value.length == 0" class="hover" matSuffix (click)="submit()">search</mat-icon>
        </mat-form-field>
    </div>
</form>
 -->

<mat-icon *ngIf="!searchbar" class="searchIcon hover" (click)="searching()">search</mat-icon>

<div class="search" [@searchbars]=currentState>
    <form [formGroup]="masterSearchForm" (submit)="submit()">
        <mat-form-field appearance="outline" class="outlined">
            <input matInput placeholder="Your search terms here" formControlName="masterSearch" #sInput
                autocomplete="off" autofill="off">
            <mat-icon class="hover" (click)="clear()">clear</mat-icon>
        </mat-form-field>
    </form>
</div>