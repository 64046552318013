import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BulkUsersService } from 'src/app/services/bulk-users.service';
import { UploadBulkUsersFormComponent } from '../upload-bulk-users-form/upload-bulk-users-form.component';

@Component({
    selector: 'app-bulk-user',
    templateUrl: './bulk-user.component.html',
    styleUrls: ['./bulk-user.component.scss']
})
export class BulkUserComponent implements OnInit {
    displayedColumns = ['id', 'batch_name', 'created_at', 'total_successful', 'total_failed', 'status', 'actions'];
    isFetch = false;
    uploadedFiles;
    empty = false;
    failed;
    constructor(
        public service: BulkUsersService,
        private sb: MatSnackBar,
        private dialog: MatDialog,
    ) { }

    ngOnInit() {
        this.get();
    }
    get() {
        this.isFetch = false;
        this.service.list().subscribe(res => {
            this.uploadedFiles = res.data;
            this.isFetch = true;

            if (res.data == '') {
                this.empty = true;
            } else {
                this.empty = false;
            }
        })
    }
    import(data) {
        this.service.import(data.id).subscribe(res => {
            if (res) {
                this.sb.open(res.message, 'OK', {
                    duration: 2000,
                });
            }
            this.get();
            // this.getFailedCount();
        })
    }
    getFailedCount() {
        this.service.getCountFailed().subscribe(res => {
            if (res) {
                if (res.data > 0) {
                    this.failed = res.data;
                    this.sb.open(res.data + ' users were not imported successfully, Please download the failed data and try again', 'OK');
                }
                // else {
                //     console.log('success');
                //     this.sb.open(res.message, 'OK', {
                //         duration: 2000,
                //     });
                // }
            }
        })

    }
    addOrUpdateUsers(data?) {
        const dialogRef = this.dialog.open(UploadBulkUsersFormComponent, {
            minWidth: '400px',
            data: {
                ...data,
                // region_manager_id: this.region_manager_id,
                // countries: this.filteredCountries
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.get();
                this.sb.open(result.message, 'Ok', {
                    duration: 2000,
                });
            }
        });
    }
    deleted() {
        this.get();
    }
    downloadFailed(id) {
        const token = localStorage.getItem('token');

        window.location.href = `/api/bulk_users/${id}/download_failed?token=${token}`;


        // this.service.downloadFailed().subscribe(res => {
        //     if (res) {
        //         this.sb.open(res.message, 'OK');
        //     }
        // })
    }

}
