<!-- <div class="search" fxflex fxLayoutAlign="center" fxLayout style="float:right;">
<mat-form-field class="w-100" appearance="outline">
</mat-form-field>
</div> -->

<div class="search" style="position: relative;" fxflex>
    <form class="searchForm">
        <mat-form-field *ngIf="searchbar" appearance="outline" class="outlined">
            <input matInput placeholder="Your search terms here" formControlName="masterSearch"  #sInput autocomplete="off" autofill="off" >
            <mat-icon  matSuffix class="hover">clear</mat-icon>
        </mat-form-field>
    </form>
</div>


<!-- <mat-icon *ngIf="sInput.value.length > 0" matSuffix class="hover" (click)="clear()">clear</mat-icon>
<mat-icon *ngIf="sInput.value.length == 0" class="hover" matSuffix (click)="submit()">search</mat-icon> -->
