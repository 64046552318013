import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    public getToken(): string {
        return localStorage.getItem('token');
    }

    isLoggedIn() {
        return (localStorage.getItem('token') !== null) || (localStorage.getItem('token') !== 'null');
    }

    logout() {
        localStorage.clear();
    }

}
