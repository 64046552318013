import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';

@Component({
    selector: 'app-delete-button',
    templateUrl: './delete-button.component.html',
    styleUrls: ['./delete-button.component.styl']
})
export class DeleteButtonComponent implements OnInit {

    @Input('icon') icon: boolean;
    @Input('id') id: number;
    @Input('api') api: string;
    @Input('title') title: string;
    @Input('description') description: string;
    @Input('with_remarks') with_remarks: boolean;
    @Output() deleted = new EventEmitter();


    constructor(public dialog: MatDialog,
        private sb: MatSnackBar) {
    }

    ngOnInit() {
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(DeleteDialogComponent, {
            width: '500px',
            data: { api: this.api, with_remarks: this.with_remarks, id: this.id, title: this.title, description: this.description }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.sb.open(result.message, 'Ok', {
                    duration: 2000,
                });
                this.deleted.emit(result);
            }
        });
    }

}
