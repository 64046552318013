import {  Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { state, style, transition, animate, trigger } from '@angular/animations';

@Component({
    selector: 'app-master-search',
    templateUrl: './master-search.component.html',
    styleUrls: ['./master-search.component.scss'],
    animations: [
        trigger('searchbtn', [
            state('in', style({
                opacity: 1,
                width: '100%'
            })),
            transition('void => *', [
                style({
                    opacity: 0,
                    //   width: 0
                }),
                animate(400)
            ]),
            transition('* => void', [
                animate(400, style({
                    width: '20%',
                    // transform: 'translateX(20px)',
                }))
            ])
        ]),
        trigger('searchbars', [
            state('initial', style({
                opacity: 0,
                width: '0',
            })),
            state('final', style({
                opacity: 1,

                width: '100%',

            })),
            transition('initial=>final', animate('400ms')),
            transition('final=>initial', animate('600ms'))
        ]),



    ]
})
export class MasterSearchComponent implements OnInit  {

    masterSearchForm: FormGroup;
    searchbar = false;
    currentState = 'initial';
    search;
    qs;
    @ViewChild('sInput', { static: true }) sInput: ElementRef;

    constructor(private router: Router,
        private fb: FormBuilder,
        private activatedRoute: ActivatedRoute) {
    }

    ngOnInit() {

        this.masterSearchForm = this.fb.group({
            masterSearch: ''
        })
        this.activatedRoute.queryParamMap.subscribe((params) => {
            this.masterSearchForm.controls['masterSearch'].patchValue(params.get('search'));
        });
    }
    changeState() {
        this.currentState = this.currentState === 'initial' ? 'final' : 'initial';

    }

    clear() {
        this.masterSearchForm.patchValue({ masterSearch: '' });
        let queryParams = this.activatedRoute.snapshot.queryParams;
        queryParams = {
            ...queryParams,
            search: ''
        };
        this.router.navigate([], {
            queryParams,
            relativeTo: this.activatedRoute
        });
    }

    submit() {
        this.qs = { ...this.activatedRoute.snapshot.queryParams };
        if (this.qs.hasOwnProperty('page')) {

            this.qs.page = "1";
        }

        this.router.navigate([], {
            queryParams: { ...this.qs, search: this.masterSearchForm.controls['masterSearch'].value },
            relativeTo: this.activatedRoute
        });
    }
    searching() {
        this.currentState = this.currentState === 'initial' ? 'final' : 'initial';
        this.sInput.nativeElement.focus();
        this.searchbar = !this.searchbar;
    }

}
