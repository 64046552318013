import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-shimmer-loading',
    templateUrl: './shimmer-loading.component.html',
    styleUrls: ['./shimmer-loading.component.scss']
})
export class ShimmerLoadingComponent implements OnInit {
    @Input("line") lineCount: number = 1;
    @Input("description") descriptionCount: number = 3;
    @Input("avator") avatorCount: number = 0;
    @Input("box") boxCount: number = 0;
    @Input("lineArrange") lineArrange: string = "vt";
    @Input("smallAvator") smallAvatorCount: number = 0;
    @Input("avatorLine") avatorLineCount: number = 0;
    @Input("table") tableCount: number = 0;
    @Input("row") rowCount: number = 0;
    @Input("col") colCount: number = 0;

    line: Array<any>;
    description: Array<any>;
    avator: Array<any>;
    smallAvator: Array<any>;
    avatorLine: Array<any>;
    box: Array<any>;
    table: Array<any>;
    row: Array<any>;
    col: Array<any>;


    constructor() { }

    ngOnInit(): void {
        if (this.avatorLineCount) {
            this.avatorLine = new Array(this.avatorLineCount);
            this.line = new Array(this.lineCount);

        }
        else if (this.tableCount) {
            this.row = new Array(this.rowCount);
            this.col = new Array(this.colCount);
        }
        else {
            this.line = new Array(this.lineCount);
            this.description = new Array(this.descriptionCount);
            this.avator = new Array(this.avatorCount);
            this.box = new Array(this.boxCount);
            this.smallAvator = new Array(this.smallAvatorCount);
        }

    }

}
