import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NxDataService } from '../decorators/NxDataService';

export interface UserConnectService extends NxDataService<any> {
    test;
}

@Injectable({
    providedIn: 'root'
})

@NxDataService({
    url: '/api/user_connects'
})

export class UserConnectService {
    constructor(public http: HttpClient) {
    }
    // id is acceptor_id
    public getFollowing(id) {
        return this.http.get<any>(`/api/connect/user/${id}/requests`);
    }
    // id is requester_id
    public getFollowers(id) {
        return this.http.get<any>(`/api/connect/user/${id}/requested`);
    }
    public accept(id){
        return this.http.get<any>(`/api/connect/accept/${id}`);

    }
    public reject(id){
        return this.http.get<any>(`/api/connect/deny/${id}`);

    }
}
