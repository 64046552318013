<div fxLayout="column" fxLayoutGap="30px" class="dashboard-stat">
    <div fxFlex fxLayout>
        <mat-card fxFlex style="position: relative; 
            width: 100%;">
            <mat-card-header>
                <div fxFlex fxLayout.xs="column" fxLayout="row" class="chartHeader">
                    <div fxFlex>
                        <div class="card-category">Total Enrollments</div>
                        <div class="card-title"><span *ngIf="enrollment_user_type =='student'">Students</span>
                            <span *ngIf="enrollment_user_type =='teacher'">Teachers</span>
                        </div>
                    </div>
                    <div fxFlex fxLayout.xs="column" fxLayout fxLayoutGap="20px" class="filter-container" fxLayoutAlign="flex-end">
                        <div>
                            <mat-form-field fxFlex>
                                <mat-select placeholder="User Type" [(value)]="enrollment_user_type" (selectionChange)="submitUserTypeEnrollment($event.value)">
                                    <mat-option value="student">Student</mat-option>
                                    <mat-option value="teacher">Teacher</mat-option>

                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field fxFlex>
                                <mat-select placeholder="Filter by" [(value)]="enrollment_criteria" (selectionChange)="submitCriteriaEnrollment($event.value)">
                                    <mat-option value="daily">Daily</mat-option>
                                    <mat-option value="monthly">Monthly</mat-option>
                                    <mat-option value="yearly">Yearly</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div *ngIf="enrollment_criteria=='daily'">
                            <mat-form-field fxFlex>
                                <mat-select placeholder="Month" [(value)]="selectedDailyMonth" (selectionChange)="submitDailyMonthYear($event.value)">
                                    <mat-option [value]="1">January</mat-option>
                                    <mat-option [value]="2">February</mat-option>
                                    <mat-option [value]="3">March</mat-option>
                                    <mat-option [value]="4">April</mat-option>
                                    <mat-option [value]="5">May</mat-option>
                                    <mat-option [value]="6">June</mat-option>
                                    <mat-option [value]="7">July</mat-option>
                                    <mat-option [value]="8">August</mat-option>
                                    <mat-option [value]="9">September</mat-option>
                                    <mat-option [value]="10">October</mat-option>
                                    <mat-option [value]="11">November</mat-option>
                                    <mat-option [value]="12">December</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div *ngIf="enrollment_criteria=='daily' && selectedDailyMonth">
                            <form [formGroup]="dailyYearForm" (submit)="submitDailyMonthYear($event.value)" fxLayout="row" style="align-items: flex-end;" fxLayoutGap="20px">
                                <mat-form-field fxFlex>
                                    <input matInput placeholder="Year" type="number" formControlName="selectedDailyYear" (change)="submitDailyMonthYear($event.value)">
                                </mat-form-field>
                            </form>
                        </div>
                        <div *ngIf="enrollment_criteria=='monthly'">
                            <form [formGroup]="monthlyYearForm" (submit)="submitMonthlyYear($event.value)" fxLayout="row" style="align-items: flex-end;" fxLayoutGap="20px">
                                <mat-form-field fxFlex>
                                    <input matInput placeholder="Year" type="number" formControlName="selectedMonthlyYear" (change)="submitMonthlyYear($event.value)">
                                </mat-form-field>
                            </form>
                        </div>
                        <div *ngIf="enrollment_criteria=='yearly'">
                            <form [formGroup]="yearlyForm" (submit)="submitYearly($event.value)" fxLayout="row" style="align-items: flex-end;" fxLayoutGap="20px">
                                <mat-form-field fxFlex="60px">
                                    <input matInput placeholder="From Year" type="number" formControlName="fromYear" (change)="submitYearly($event.value)">
                                </mat-form-field>
                                <mat-form-field fxFlex="60px">
                                    <input matInput placeholder="To Year" type="number" formControlName="toYear" (change)="submitYearly($event.value)">
                                </mat-form-field>
                            </form>
                        </div>
                    </div>
                </div>
            </mat-card-header>
            <div class=" chart-container" style="position: relative; height:250px; width:100%">
                <canvas #adminEnrollmentCanvas id="canvas">{{ enrollChart }}</canvas>
            </div>
        </mat-card>
    </div>
    <div fxFlex fxLayout="column" fxLayoutGap="30px" fxLayout.gt-md="row">
        <mat-card fxFlex style="position: relative; 
            width: 100%;">
            <mat-card-header fxLayout="column">
                <div fxFlex fxLayout="row" class="chartHeader">
                    <div fxFlex>
                        <div class="card-category">Total Revenues</div>
                        <div class="card-title"><span *ngIf="user_revenue_user_type =='student'">Students</span>
                            <span *ngIf="user_revenue_user_type =='teacher'">Teachers</span>
                        </div>
                    </div>
                </div>

                <div fxFlex fxLayout fxLayout.xs="column" fxLayoutGap="20px" class="filter-container" fxLayoutAlign="flex-end">
                    <div>

                        <mat-form-field fxFlex="150px">
                            <mat-select placeholder="User Type" [(value)]="user_revenue_user_type" (selectionChange)="submitUserTypeUserRevenue($event.value)">
                                <mat-option value="student">Student</mat-option>
                                <mat-option value="teacher">Teacher</mat-option>

                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field fxFlex="150px">
                            <mat-select placeholder="Filter by" [(value)]="user_revenue_criteria" (selectionChange)="submitCriteriaUserRevenue($event.value)">
                                <mat-option value="daily">Daily</mat-option>
                                <mat-option value="monthly">Monthly</mat-option>
                                <mat-option value="yearly">Yearly</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="user_revenue_criteria=='daily'">
                        <mat-form-field fxFlex>
                            <mat-select placeholder="Month" [(value)]="selectedDailyMonthUserRevenues" (selectionChange)="submitDailyMonthYearUserRevenues($event.value)">
                                <mat-option [value]="1">January</mat-option>
                                <mat-option [value]="2">February</mat-option>
                                <mat-option [value]="3">March</mat-option>
                                <mat-option [value]="4">April</mat-option>
                                <mat-option [value]="5">May</mat-option>
                                <mat-option [value]="6">June</mat-option>
                                <mat-option [value]="7">July</mat-option>
                                <mat-option [value]="8">August</mat-option>
                                <mat-option [value]="9">September</mat-option>
                                <mat-option [value]="10">October</mat-option>
                                <mat-option [value]="11">November</mat-option>
                                <mat-option [value]="12">December</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="user_revenue_criteria=='daily' && selectedDailyMonthUserRevenues">
                        <form [formGroup]="revenueDailyYearForm" (submit)="submitDailyMonthYearUserRevenues($event.value)" fxLayout="row" style="align-items: flex-end;" fxLayoutGap="20px">
                            <mat-form-field fxFlex="60px">
                                <input matInput placeholder="Year" type="number" formControlName="selectedDailyYear" (change)="submitDailyMonthYearUserRevenues($event.value)">
                            </mat-form-field>
                        </form>
                    </div>
                    <div *ngIf="user_revenue_criteria=='monthly'">
                        <form [formGroup]="revenueMonthlyYearForm" (submit)="submitMonthlyYearUserRevenues($event.value)" fxLayout="row" style="align-items: flex-end;" fxLayoutGap="20px">
                            <mat-form-field fxFlex="60px">
                                <input matInput placeholder="Year" type="number" formControlName="selectedMonthlyYear" (change)="submitMonthlyYearUserRevenues($event.value)">
                            </mat-form-field>
                        </form>
                    </div>
                    <div *ngIf="user_revenue_criteria=='yearly'">
                        <form [formGroup]="revenueYearlyForm" (submit)="submitYearlyUserRevenues($event.value)" fxLayout="row" style="align-items: flex-end;" fxLayoutGap="20px">
                            <mat-form-field fxFlex="60px">
                                <input matInput placeholder="From Year" type="number" formControlName="fromYear" (change)="submitYearlyUserRevenues($event.value)">
                            </mat-form-field>
                            <mat-form-field fxFlex="60px">
                                <input matInput placeholder="To Year" type="number" formControlName="toYear" (change)="submitYearlyUserRevenues($event.value)">
                            </mat-form-field>
                        </form>
                    </div>
                </div>
            </mat-card-header>
            <div class=" chart-container" style="position: relative; height:250px; width:100%">
                <canvas #adminUserRevenueCanvas id="canvas">{{ userRevenueChart }}</canvas>
            </div>

        </mat-card>
        <mat-card fxFlex style="position: relative; 
        width: 100%;">
            <mat-card-header fxLayout="column">
                <div fxFlex fxLayout="row" class="chartHeader">
                    <div fxFlex>
                        <div class="card-category">Revenues</div>
                        <div class="card-title"><span>Country</span>
                        </div>

                    </div>
                </div>
                <div fxFlex fxLayout fxLayout.xs="column" fxLayoutGap="20px" class="filter-container" fxLayoutAlign="flex-end">
                    <div>
                        <mat-form-field fxFlex="150px">
                            <mat-select placeholder="Country" [(value)]="country_revenue_country" (selectionChange)="submitCountryCountryRevenue($event.value)">

                                <mat-option *ngFor="let country of countryList" [value]="country.id">{{country.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field fxFlex="150px">
                            <mat-select placeholder="Filter by" [(value)]="country_revenue_criteria" (selectionChange)="submitCriteriaCountryRevenue($event.value)">
                                <mat-option value="daily">Daily</mat-option>
                                <mat-option value="monthly">Monthly</mat-option>
                                <mat-option value="yearly">Yearly</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="country_revenue_criteria=='daily'">
                        <mat-form-field fxFlex>
                            <mat-select placeholder="Month" [(value)]="selectedDailyMonthCountryRevenues" (selectionChange)="submitDailyMonthYearCountryRevenues($event.value)">
                                <mat-option [value]="1">January</mat-option>
                                <mat-option [value]="2">February</mat-option>
                                <mat-option [value]="3">March</mat-option>
                                <mat-option [value]="4">April</mat-option>
                                <mat-option [value]="5">May</mat-option>
                                <mat-option [value]="6">June</mat-option>
                                <mat-option [value]="7">July</mat-option>
                                <mat-option [value]="8">August</mat-option>
                                <mat-option [value]="9">September</mat-option>
                                <mat-option [value]="10">October</mat-option>
                                <mat-option [value]="11">November</mat-option>
                                <mat-option [value]="12">December</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="country_revenue_criteria=='daily' && selectedDailyMonthCountryRevenues">
                        <form [formGroup]="countryDailyYearForm" (submit)="submitDailyMonthYearCountryRevenues($event.value)" fxLayout="row" style="align-items: flex-end;" fxLayoutGap="20px">
                            <mat-form-field fxFlex="60px">
                                <input matInput placeholder="Year" type="number" formControlName="selectedDailyYear" (change)="selectedDailyMonthCountryRevenues($event.value)">
                            </mat-form-field>
                        </form>
                    </div>
                    <div *ngIf="country_revenue_criteria=='monthly'">
                        <form [formGroup]="countryMonthlyYearForm" (submit)="submitMonthlyYearCountryRevenues($event.value)" fxLayout="row" style="align-items: flex-end;" fxLayoutGap="20px">
                            <mat-form-field fxFlex="60px">
                                <input matInput placeholder="Year" type="number" formControlName="selectedMonthlyYear" (change)="submitMonthlyYearCountryRevenues($event.value)">
                            </mat-form-field>
                        </form>
                    </div>
                    <div *ngIf="country_revenue_criteria=='yearly'">
                        <form [formGroup]="countryYearlyForm" (submit)="submitYearlyCountryRevenues($event.value)" fxLayout="row" style="align-items: flex-end;" fxLayoutGap="20px">
                            <mat-form-field fxFlex="60px">
                                <input matInput placeholder="From Year" type="number" formControlName="fromYear" (change)="submitYearlyCountryRevenues($event.value)">
                            </mat-form-field>
                            <mat-form-field fxFlex="60px">
                                <input matInput placeholder="To Year" type="number" formControlName="toYear" (change)="submitYearlyCountryRevenues($event.value)">
                            </mat-form-field>
                        </form>
                    </div>
                </div>

            </mat-card-header>
            <div class=" chart-container" style="position: relative; height:250px; width:100%">
                <canvas #adminCountryRevenueCanvas id="canvas">{{ countryRevChart }}</canvas>
            </div>

        </mat-card>
        <mat-card fxFlex style="position: relative; 
        width: 100%;">
            <mat-card-header>
                <div fxFlex fxLayout="row" class="chartHeader">
                    <div fxFlex>
                        <div class="card-category">Localized Experiences</div>
                        <div class="card-title"><span>{{localized_experiences_criteria}}</span>

                        </div>
                    </div>
                </div>

                <div fxFlex fxLayout fxLayout.xs="column" fxLayoutGap="20px" class="filter-container" fxLayoutAlign="flex-end">
                    <mat-form-field fxFlex>
                        <mat-select placeholder="Filter by" [(value)]="localized_experiences_criteria" (selectionChange)="submitCriteriaLocalizedExp($event.value)">
                            <mat-option value="languages">Language</mat-option>
                            <mat-option value="countries">Country</mat-option>
                            <mat-option value="syllabus">Syllabus</mat-option>
                            <mat-option value="subject">Subject</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </mat-card-header>
            <div class=" chart-container" style="position: relative; height:250px; width:100%">
                <canvas #adminLocalizedExperienceCanvas id="canvas">{{ expChart }}</canvas>
            </div>

        </mat-card>
    </div>
</div>