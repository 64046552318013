import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None

})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    token: any;
    public recaptchaStr = '';

    constructor(private fb: FormBuilder,
        private sb: MatSnackBar,
        private router: Router,
        private service: UserService) { }

    ngOnInit() {
        this.loginForm = this.fb.group({
            'email': ['', [Validators.required, Validators.email]],
            'password': ['', Validators.required],
            'g_recaptcha_response': ['']
        });
        this.service.roles.subscribe(res => {
        });
    }

    submit() {
        this.service.login(this.loginForm.value).subscribe(res => {
            if (res.success) {
                if ((res.data.user.user_type !== 'admin') && (res.data.user.user_type !== 'school') && (res.data.user.user_type !== 'region_manager')) {
                    this.sb.open('Unauthorized user type', 'Ok', {
                        duration: 2000,
                    });
                } else {
                    localStorage.setItem('token', res.data.token);
                    localStorage.setItem('user', res.data.user.name);
                    this.service.setUserType(res.data.user.user_type);

        // this.userService.getPermissions();


                    // if (res.data.user.user_type === 'region_manager') {
                    //     this.router.navigateByUrl('/dashboard');
                    //     this.sb.open('Logged in Succesfully', 'Ok', {
                    //         duration: 2000,
                    //     });
                    // }
                    // if (res.data.user.user_type === 'school') {
                    //     this.router.navigateByUrl('/school-dashboard');
                    //     this.sb.open('Logged in Succesfully', 'Ok', {
                    //         duration: 2000,
                    //     });
                    // }
                    // if (res.data.user.user_type === 'admin') {
                    //     this.router.navigateByUrl('/');
                    //     this.sb.open('Logged in Succesfully', 'Ok', {
                    //         duration: 2000,
                    //     });
                    // }
                    this.router.navigateByUrl('/');
                    this.sb.open('Logged in Succesfully', 'Ok', {
                        duration: 1000,
                    });
                }

            } else {
                this.sb.open('Login Error', 'Ok', {
                    duration: 2000,
                });
            }

        });
    }

}
