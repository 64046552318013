import { Component, OnInit, Input, Inject } from '@angular/core';
import { UserConnectService } from '../../services/user-connect.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { debounceTime, startWith } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-add-following-form',
    templateUrl: './add-following-form.component.html',
    styleUrls: ['./add-following-form.component.scss']
})
export class AddFollowingFormComponent implements OnInit {
    form: FormGroup;
    filteredUser;
    allStudents;

    constructor(public dialogRef: MatDialogRef<AddFollowingFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private sb: MatSnackBar,
        private service: UserConnectService,
        private userService: UserService,
        private fb: FormBuilder

    ) { }

    ngOnInit() {
        this.form = this.fb.group({
            requester_id: this.data.requester_id,
            acceptor_id: [null,],
            status: 0,
            user: [null]

        });

        this.form.controls.user.valueChanges
            .pipe(
                debounceTime(300),
                startWith(null)
            )
            .subscribe(value => {
                if (value) {
                    this.userService.list({ search: value, 'type': this.data.user_type })
                        .subscribe(res => {
                            this.filteredUser = res.data;
                        });

                }

            });

        if (this.data) {
            // this.userService.list({ search: 'user_type:' + this.data.user_type, 'withRelations': 'true' })
            //     .subscribe(res => {
            //         this.allStudents = res.data.data;
            //     });

            this.form.addControl('id', new FormControl(this.data.id));
            this.form.patchValue(this.data);
        }
    }
    selectedUser(value) {
        return value ? value.name + ' , ' + value.email : value;
    }

    submit() {

        this.form.value.acceptor_id = this.form.value.user.id;

        delete this.form.value.user;

        this.service.save(this.form.value).subscribe(res => {
            this.dialogRef.close(res);

        });
    }
}

