import { Component, OnInit, Inject } from '@angular/core';
import { BulkUsersService } from 'src/app/services/bulk-users.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-upload-bulk-users-form',
    templateUrl: './upload-bulk-users-form.component.html',
    styleUrls: ['./upload-bulk-users-form.component.scss']
})
export class UploadBulkUsersFormComponent implements OnInit {
    selectedFile: File[] = [];
    form: FormGroup;

    constructor(public dialogRef: MatDialogRef<UploadBulkUsersFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        public service: BulkUsersService) { }

    ngOnInit() {
        this.form = this.fb.group({
            batch_name: [null, Validators.required],
            bulk_users: [null, Validators.required]
        });
    }
    select(ev) {
        this.selectedFile = this.SetPreviewUrl(Array.from(ev.target.files));
        // this.uploadFile();
    }
    SetPreviewUrl(files) {
        for (const file of files) {
            const reader = new FileReader();
            reader.addEventListener('load', function () {
                file.src = reader.result;
            }, false);
            reader.readAsDataURL(file);
        }
        return files;
    }
    submit() {
        const formData = new FormData();
        this.selectedFile.map((file: File) => {
            formData.append('batch_name', this.form.value.batch_name);
            formData.append('bulk_users', file);
        });

        this.service.save(formData).subscribe((res: any) => {
            this.dialogRef.close(res);
        });;
    }

}