<!-- <div class="card br">
    <div class="wrapper">
        <div class="profilePic animate din"></div>
        <div class="comment br animate w80"></div>
        <div class="comment br animate w40"></div>
        <div class="comment br animate"></div>
        <div class="comment br animate"></div>
        <div class="comment br animate"></div>
    </div>
</div> -->
<ng-container *ngIf="avatorLineCount">
    <div fxLayout *ngFor="let shimmer of avatorLine" class="shimContainer">
        <div class="shine smallAvator right-margin"></div>
        <div fxLayout="column" class="center-align" fxLayoutGap="8px">
            <div *ngFor="let shimmer of line" class="shine"
                [ngClass]="{'lines-vt': lineArrange == 'vt', 'lines-hr': lineArrange != 'vt'}"></div>
            <!-- <div class="shine" [ngClass]="{'lines-vt': lineArrange == 'vt', 'lines-hr': lineArrange != 'vt'}"></div>
            <div class="shine" [ngClass]="{'lines-vt': lineArrange == 'vt', 'lines-hr': lineArrange != 'vt'}"></div> -->
        </div>

    </div>
</ng-container>
<ng-container *ngIf="tableCount">
    <div fxLayout fxLayoutGap="20px" *ngFor="let shimmer of row" class="shimContainer">
        <div fxFlex fxLayout="row" *ngFor="let shimmer of col" class="center-align" fxLayoutGap="20px">
            <div fxFlex class="shine lines-table"></div>
        </div>
    </div>
</ng-container>
<div *ngIf="!avatorLineCount && !tableCount">

    <ng-container *ngIf="avatorCount">
        <div *ngFor="let shimmer of avator" class="shine avator"></div>
    </ng-container>
    <ng-container *ngIf="smallAvatorCount">
        <div *ngFor="let shimmer of smallAvator" class="shine smallAvator"></div>
    </ng-container>
    <ng-container *ngIf="lineCount">
        <div *ngFor="let shimmer of line" class="shine"
            [ngClass]="{'lines-vt': lineArrange == 'vt', 'lines-hr': lineArrange != 'vt'}"></div>
    </ng-container>

    <ng-container *ngIf="descriptionCount">
        <div *ngFor="let shimmer of description" class="shine description"></div>
    </ng-container>

    <ng-container *ngIf="boxCount">
        <div *ngFor="let shimmer of box" class="shine box"></div>
    </ng-container>
</div>