import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { debounceTime, startWith } from 'rxjs/operators';
import { CountryService } from '../../services/country.service';
import { CountryLanguageService } from '../../services/country-language.service';
import * as moment from 'moment';
import { RegionManagerService } from '../../services/region-manager.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-users-list-form',
    templateUrl: './users-list-form.component.html',
    styleUrls: ['./users-list-form.component.scss']
})
export class UsersListFormComponent implements OnInit {
    form: FormGroup;
    allLocations = [];
    filteredLanguages;
    showLanguage = false;
    isRegionManager = false;
    isSchool = false;
    filteredCountries;
    filteredRegionManagers;
    changePassword = false;
    selectedCountryID = null;
    constructor(public dialogRef: MatDialogRef<UsersListFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private sb: MatSnackBar,
        private service: UserService,
        private countryService: CountryService,
        private fb: FormBuilder) {
    }

    ngOnInit() {
        // 'region_manager_id', 'expired_at', 'amount', 'number_teachers', 'number_students', 'status' 
        this.form = this.fb.group({
            name: [null, Validators.required],
            email: [null, [Validators.required, Validators.email]],
            password: [null,],
            verified: [false],
            contact_number: ['',],
            language_id: [null, Validators.required],
            country: [null, Validators.required],
            user_type: [null, [Validators.required]],
            // region_manager
            number_of_schools: [null],
            number_of_users: [null],
            remarks: [null],
            active: [false],
            expire_at: [null],
            // school
            region_manager_id: [null],
            expired_at: [null],
            amount: [null,],
            number_teachers: [null],
            number_students: [null],
            status: [false],
            // region_manager: [null],
            user: [null]

        });
        // this.form.controls.language.valueChanges
        //     .pipe(
        //         debounceTime(300),
        //         startWith(null)
        //     )
        //     .subscribe(value => {
        //         if (value) {
        //             this.languageService.list({ search: value }).subscribe(res => {
        //                 this.filteredLanguages = res.data.data;
        //             });
        //         }

        //     });



        this.form.controls.country.valueChanges
            .pipe(
                debounceTime(300),
                startWith(null)
            )
            .subscribe(value => {
                if (value) {
                    this.countryService.list({ search: value }).subscribe(res => {
                        this.filteredCountries = res.data.data;
                    });
                }

            });

        // this.form.controls.region_manager.valueChanges
        //     .pipe(
        //         debounceTime(300),
        //         startWith(null)
        //     )
        //     .subscribe(value => {
        //         if (value) {
        //             this.regionManagerService.list({ search: value }).subscribe(res => {
        //                 this.filteredRegionManagers = res.data.data;
        //             });
        //         }

        //     });
        this.form.controls.user.valueChanges
            .pipe(
                debounceTime(300),
                startWith(null)
            )
            .subscribe(value => {

                if (value) {
                    if (value.region_manager) {
                        this.form.value.region_manager_id = value.region_manager.id;
                    }
                    this.service.list({ search: value, 'type': 'region_manager' })
                        .subscribe(res => {
                            this.filteredRegionManagers = res.data;
                        });

                }

            });

        // this.setTypeBaseValidators();

        if (this.data) {
            this.form.addControl('id', new FormControl(this.data.user_id));
            if (this.data.language_id) {
                this.getLanguages(this.data.country_id);
            }
            this.userTypeSelected(this.data.user_type);
            if (this.data.user_type === 'region_manager') {
                if (this.data.region_manager !== null) {
                    this.data.active = this.data.region_manager.active;
                    this.data.number_of_schools = this.data.region_manager.number_of_schools;
                    this.data.number_of_users = this.data.region_manager.number_of_users;
                    this.data.remarks = this.data.region_manager.remarks;
                    this.data.expire_at = this.data.region_manager.expire_at;
                    this.data.expire_at = moment(this.data.expire_at).format('YYYY-MM-DD');
                }
            }
            if (this.data.user_type === 'school') {
                if (this.data.school !== null) {
                    if (this.data.school.region_manager !== null) {
                        this.data.user = this.data.school.region_manager.user;
                    }
                    this.data.region_manager_id = this.data.school.region_manager_id;
                    this.data.amount = this.data.school.amount;
                    this.data.number_of_users = this.data.school.number_of_users;
                    this.data.number_teachers = this.data.school.number_teachers;
                    this.data.number_students = this.data.school.number_students;
                    this.data.status = this.data.school.status;
                    this.data.expired_at = moment(this.data.school.expired_at).format('YYYY-MM-DD');
                }
            }
            this.form.patchValue(this.data);
            if (!this.data.id) {

                this.form.get('password').setValidators([Validators.required, this.removeSpaces, Validators.minLength(6)]);
                this.form.get('password').updateValueAndValidity();

                // this.form.get('verified').setValidators(Validators.required);
            }
        }

        // this.form.controls['location'].valueChanges
        //     .pipe(
        //         debounceTime(300),
        //         startWith(null)
        //     )
        //     .subscribe(value => {
        //         if (value) {
        //             this.service.list({ search: value }, '/api/open/locations/island').subscribe(res => {
        //                 this.allLocations = res.data;
        //             });
        //         }

        //     });

    }


    selectedUser(value) {

        return value ? value.name : value;
    }
    // displayWith(value) {
    //     return value ? value.name : value;
    // }
    public removeSpaces(control: AbstractControl) {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    }


    selectedCountry(value) {
        if (value) {
            this.selectedCountryID = value.id;
            this.showLanguage = true;

        }
        return value ? value.name : value;
    }
    // selectedLanguage(value) {
    //     return value ? value.name : value;
    // }
    // getLanguages(value) {
    //     this.countryLanguageService.getLanguagebyCountryID(value.id).subscribe(res => {
    //         this.filteredLanguages = res.data;
    //     });
    // }
    getLanguages(id) {
        this.showLanguage = true;
        this.countryService.getLanguages(id).subscribe(res => {
            this.filteredLanguages = res.data.language;
        });
    }

    toggleChangePassword(ev) {
        this.changePassword = ev.checked;
        if (this.changePassword) {
            this.form.get('password').setValidators([Validators.required, this.removeSpaces, Validators.minLength(6)]);
            this.form.get('password').updateValueAndValidity();

        }
        else {
            this.form.get('password').setValidators(null);
            this.form.get('password').setErrors(null);
            this.form.get('password').updateValueAndValidity();

        }
    }

    userTypeSelected(value) {
        if (value === 'region_manager') {
            this.isRegionManager = true;
            this.form.get('number_of_schools').setValidators([Validators.required]);
            this.form.get('number_of_users').setValidators([Validators.required]);
            this.form.get('number_of_schools').updateValueAndValidity();
            this.form.get('number_of_users').updateValueAndValidity();
        }
        else {
            this.isRegionManager = false;
            this.form.get('number_of_schools').setValidators(null);
            this.form.get('number_of_schools').setErrors(null);
            this.form.get('number_of_schools').updateValueAndValidity();
            this.form.get('number_of_users').setValidators(null);
            this.form.get('number_of_users').setErrors(null);
            this.form.get('number_of_users').updateValueAndValidity();
        }
        if (value === 'school') {
            this.isSchool = true;
            this.form.get('amount').setValidators([Validators.required]);
            this.form.get('amount').updateValueAndValidity();
            this.form.get('number_teachers').setValidators([Validators.required]);
            this.form.get('number_teachers').updateValueAndValidity();
            this.form.get('number_students').setValidators([Validators.required]);
            this.form.get('number_students').updateValueAndValidity();
        }
        else {
            this.isSchool = false;
            this.form.get('amount').setValidators(null);
            this.form.get('amount').setErrors(null);
            this.form.get('amount').updateValueAndValidity();
            this.form.get('number_teachers').setValidators(null);
            this.form.get('number_teachers').setErrors(null);
            this.form.get('number_teachers').updateValueAndValidity();
            this.form.get('number_students').setValidators(null);
            this.form.get('number_students').setErrors(null);
            this.form.get('number_students').updateValueAndValidity();
        }
    }

    submit() {
        if (this.form.value.country) {
            this.form.value.country_id = this.form.value.country.id;

            delete this.form.value.country;
        }
        if (this.form.value.expire_at) {
            this.form.value.expire_at = moment(this.form.value.expire_at).format('YYYY-MM-DD');
        }
        if (this.form.value.expired_at) {
            this.form.value.expired_at = moment(this.form.value.expired_at).format('YYYY-MM-DD');
        }
        if (this.form.value.school) {
            if (this.form.value.school.region_manager) {
                this.form.value.region_manager_id = this.form.value.school.region_manager.id
                delete this.form.value.school.region_manager;
            }
        }
        if (this.form.value.user) {
            if (this.form.value.user.region_manager) {
                this.form.value.region_manager_id = this.form.value.user.region_manager.id
            }
            delete this.form.value.user;

        }

        // if (this.form.value.language) {
        //     this.form.value.language_id = this.form.value.language.id;

        //     delete this.form.value.language;
        // }
        if (this.form.value.password) {
            if (this.data) {
                if (this.data.id && this.form.value.password.trim() == '') {
                    delete this.form.value.password;
                }

            }


        }
        if (this.form.value.id) {
            // updating users
            if (this.form.value.user_type == 'region_manager') {
                // updating region manager
                delete this.form.value.user;
                this.service.updateRegionManager(this.form.value, this.data.region_manager.id).subscribe(res => {
                    this.dialogRef.close(res);
                });
            }
            if (this.form.value.user_type == 'school') {
                // updating school
                this.service.updateSchool(this.form.value, this.data.school.id).subscribe(res => {
                    this.dialogRef.close(res);
                });
            } else {
                this.service.update(this.form.value).subscribe(res => {
                    this.dialogRef.close(res);
                });
            }

        }
        if (!this.form.value.id) {
            this.service.save(this.form.value).subscribe(res => {
                this.dialogRef.close(res);
            });
        }
    }
}
