import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NxDataService } from '../decorators/NxDataService';

export interface BulkUsersService extends NxDataService<any> {
    test;
}

@Injectable({
    providedIn: 'root'
})

@NxDataService({
    url: '/api/bulk_users'
})

export class BulkUsersService {
    constructor(public http: HttpClient) {
    }

    public import(id) {
        return this.http.get<any>(`/api/bulk_users/${id}/import`);
    }
    public downloadFailed(id) {
        return this.http.get<any>(`/api/bulk_users/${id}download_failed`);
    }
    public getCountFailed() {
        return this.http.get<any>(`/api/bulk_users/total_failed`);
    }
}
