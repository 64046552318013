import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

    transform(items: any, filter: any): any {

        // check if search term is undefined
        if (filter === undefined) { return items; }

        // return updates items array
        // return items.filter(function(item){
        //     return item.name.toLowerCase().includes(filter.toLowerCase());
        // });

        // To search in values of every variable of your object(item)
        return items.filter(item => JSON.stringify(item).toLowerCase().indexOf(filter.toLowerCase()) !== -1);

    }

}
