import { NxDataService } from './../../../decorators/NxDataService';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { DeleteService } from './../../../services/delete.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-delete-dialog',
    templateUrl: './delete-dialog.component.html',
    styleUrls: ['./delete-dialog.component.styl']
})
export class DeleteDialogComponent implements OnInit {

    form: FormGroup;
    dialogData: {
        title: 'Are you sure?',
        description: 'You won\'t be able to revert this!'
    };
    constructor(public dialogRef: MatDialogRef<DeleteDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private sb: MatSnackBar,
        private http: HttpClient,
        private deleteService: DeleteService,
        private fb: FormBuilder) {

        const { title, description } = this.data;
        this.dialogData = {
            title: title ? title : 'Are you sure?',
            description: description ? description : 'You won\'t be able to revert this!',
        };
    }

    ngOnInit() {
        if (this.data.with_remarks) {
            this.form = this.fb.group({
                remarks: [null, Validators.required],
            });
        } else {
            this.form = this.fb.group({
                remarks: [null],
            });
        }
    }

    submit() {
        this.deleteService.delete(this.data, this.form).subscribe(res => {
            this.dialogRef.close(res);
        });
    }
    //     if (this.data.second_variable_id) {
    //         this.http.delete('api/' + this.data.api + '/' + this.data.group_code + '/' + this.data.second_variable_id + '?body=' + this.form.value.remarks).subscribe(res => {
    //             this.dialogRef.close(res);
    //         });

    //     } else {
    //         this.http.delete('api/' + this.data.api + '/' + this.data.id + '?body=' + this.form.value.remarks).subscribe(res => {
    //             this.dialogRef.close(res);
    //         });
    //     }

    // }

}
