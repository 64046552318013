import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import { FilePondModule, registerPlugin } from 'ngx-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { FileManagerService } from './file-manager.service';

@Component({
    selector: 'app-file-manager',
    templateUrl: './file-manager.component.html',
    styleUrls: ['./file-manager.component.styl']
})
export class FileManagerComponent implements OnInit {
    // @ViewChild('myPond') myPond: any;
    public pondOptions;
    public pondFiles;
    public api_end_point;
    public files;
    public name;
    public urlString;
    public label;

    @Input() type;
    @Input() id_name;
    @Input() platform;
    @Input() title;



    @Input() model_id: number;
    @Input() model_type: string;
    @Input() attachable_category_id: number;
    @Input() multiple: boolean;
    @Input() position: string;
    @Output() file_uploaded = new EventEmitter();


    constructor(private auth: AuthService, public file_manager: FileManagerService) {
        this.api_end_point = 'http://127.0.0.1:8000/api/';
    }


    ngOnInit() {

        this.initialize();

        this.pondOptions = {
            name: this.name,
            class: 'my-filepond',
            multiple: this.multiple,
            method: 'POST',
            url: this.api_end_point,
            credits:false,
            server: {
                process: {
                    url: this.urlString,
                    method: 'POST',
                    withCredentials: false,
                    headers: {
                        Authorization: `Bearer ${this.auth.getToken()}`,
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                    ondata: (formData) => {
                        return this.setFormdata(formData);
                    },
                    timeout: 0,
                    onload: (data) => {
                        this.file_uploaded.emit(JSON.parse(data));
                    },
                    onerror: null,
                },
            },
            labelIdle: this.label,
            allowFileTypeValidation: false,
            allowFileSizeValidation: false,
            maxFileSize: 50000000000000,
            acceptedFileTypes: 'image/jpeg, image/png, application/octet-stream '
        };
        this.pondFiles = [];
    }

    pondHandleInit() {
    }

    pondHandleAddFile(event: any) {
    }

    initialize() {

        this.name = 'filepond';
        this.urlString = './api/attachables'
        this.label = 'Drop files here'

        if (this.type == 'asset_bundle') {
            this.name = 'asset_bundle';
            this.urlString = '/api/experiences/upload_asset_bundle';
        }
        else if (this.type == 'local_asset_bundle') {
            this.name = 'local_asset_bundle';
            this.urlString = '/api/experiences/upload_localized_experience_group';
        }
        else if (this.type == 'language_setting') {
            this.name = 'setting';
            this.urlString = '/api/settings/upload';
        }
        else if (this.type == 'image') {
            this.name = 'image';
            this.urlString = '/api/attachables/upload_image';
            this.label = 'Drop Images here'
        }

    }
    setFormdata(formData) {

        formData.append('attachable_type', this.model_type);
        formData.append('attachable_id', this.model_id);

        if (this.type == 'asset_bundle' || this.type == 'local_asset_bundle') {
            formData.append(this.id_name, this.model_id);
            formData.append('title', this.title);
            formData.append('type', this.type);
            formData.append('platform', this.platform);
        }
        else if (this.type == 'language_setting') {
            formData.append('type', this.type);
            formData.append('platform', this.platform);
        }
        else if (this.type == 'image') {
            formData.append('type', this.type);
            formData.append('title', this.title);
        }

        return formData;
    }
}
