import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NxDataService } from '../decorators/NxDataService';
export interface CountryService extends NxDataService<any> {
    test;
}
@Injectable({
  providedIn: 'root'
})
@NxDataService({
    url: '/api/countries'
})
export class CountryService {

    constructor(public http: HttpClient) { }

    public getLanguages(id) {
        return this.http.get<any>(`/api/countries/${id}/languages`);
    }
    public getExperiences(id) {
        return this.http.get<any>(`/api/experiences/by_country/${id}`);
    }

    
}

