<mat-toolbar color='primary'>
        <span>Follow {{data?.user_type}}s</span>
        <span fxFlex=""></span>
        <button mat-icon-button="" mat-dialog-close matTooltip="close">
            <mat-icon class="c-w">close</mat-icon>
        </button>
    </mat-toolbar>
    
    <form [formGroup]="form" (submit)="submit()" fxLayout="column" fxLayoutGap="10px" class="mat-padding">
        <div fxLayout="row">
            <mat-form-field fxFlex>
                <input matInput placeholder="Select {{data?.user_type}} " aria-label="User" [matAutocomplete]="auto"
                    formControlName="user">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="selectedUser">
                    <mat-option *ngFor="let user of filteredUser" [value]="user">
                        <span>{{user?.name}} , {{user?.email}} </span>
                    </mat-option>
                </mat-autocomplete>
    
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="10px">
            <button type="button" (click)="form.reset()" mat-raised-button="" color="accent">
                Reset
            </button>
            <button [disabled]="form.invalid" mat-raised-button="" color="primary">
                Save
            </button>
        </div>
    </form>