import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-photo',
    templateUrl: './photo.component.html',
    styleUrls: ['./photo.component.styl']
})
export class PhotoComponent implements OnInit {
    @Input() card_image: boolean;
    @Input() attachable: any;
    @Input() size: number;
    public url;

    constructor() {
        this.url = 'https://via.placeholder.com/200x148';
    }

    ngOnInit() {
        if (this.attachable) {
            this.url = '/api/attachables/download/' + this.attachable.id + '?size=' + this.size;
        }
    }

}
