import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpParams,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { HideLoader, ShowLoader } from '../state/actions/layout.action';
import { UserService } from './user.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class NxHttpService implements HttpInterceptor {

    constructor(private auth: AuthService,
        private router: Router,
        private snackBar: MatSnackBar,
        private service: UserService,
        private store: Store) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${this.auth.getToken()}`
            }
        });
        this.store.dispatch(new ShowLoader);

        return next.handle(request)
            .pipe(
                tap(event => {
                    if (event instanceof HttpResponse) {
                        this.store.dispatch(new HideLoader);
                    }
                }),
                catchError((err, caught) => {
                    if (err instanceof HttpErrorResponse) {
                        // if (err.error.message == 'token_absent') {
                        //     this.router.navigate(['/login']);
                        // }
                        // if (err.error.message == 'token_expired') {
                        //     this.router.navigate(['/login']);
                        // }
                        // if (err.error.message == 'token_invalid') {
                        //     this.router.navigate(['/login']);
                        // }
                        if (err.status == 401) {
                            // this.router.navigate(['/login']);
                            this.service.logout();

                        }
                        if (err.error.message == 'Unauthenticated') {
                            this.service.logout();
                            // this.router.navigate(['/login']);
                        }
                        this.store.dispatch(new HideLoader);
                        this.snackBar.open(this.getErrorMessage(err), 'OK', {
                            duration: 3000
                        });

                        return EMPTY;
                    }
                })
            ) as any;
    }

    private getErrorMessage(err: HttpErrorResponse): string {
        return this.firstError(err.error.errors) || err.error.message || err.error.error || 'Unknown Server Error';
    }

    private firstError(err: HttpErrorResponse): string {
        try {
            return err[Object.keys(err)[0]];
        } catch (e) {
            return null;
        }

    }

}

export function MakeParams(data): HttpParams {
    let httpParams = new HttpParams();
    if (data) {
        Object.keys(data).forEach(function (key) {
            httpParams = httpParams.append(key, data[key]);
        });
        return httpParams;
    }
}

