import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';

@Component({
    selector: 'app-stats',
    templateUrl: './stats.component.html',
    styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {
    user_type;
    constructor(private userService: UserService) {
        // this.userService.getPermissions();
    }

    ngOnInit() {
        // this.userService.roles.subscribe(res => {


        // });
        this.user_type = this.userService.user_type;
    }

}
