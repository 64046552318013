<!-- <div class="wrapper">
    <div class="sidebar" data-color="danger" data-background-color="white" data-image="./assets/img/sidebar-1.jpg">
        <app-main-nav></app-main-nav>
        <div class="sidebar-background" style="background-image: url(./assets/img/sidebar-4.jpg)"></div>
    </div>
    <div class="main-panel"> -->
        <!-- <app-navbar></app-navbar> -->
        <!-- <router-outlet></router-outlet> -->

    <!-- </div> -->
    <router-outlet></router-outlet>
    <!-- <div class="fixed-plugin">
        <div class="dropdown show-dropdown open show">
            <a href="#" data-toggle="dropdown" aria-expanded="true">
                <i class="fa fa-cog fa-2x"> </i>
            </a>
            <ul class="dropdown-menu show" x-placement="bottom-start"
                style="position: absolute; top: 41px; left: -231px; will-change: top, left;">
                <li class="header-title"> Sidebar Filters</li>
                <li class="adjustments-line">
                    <a href="javascript:void(0)" class="switch-trigger active-color">
                        <div class="ml-auto mr-auto">
                            <span class="badge filter badge-purple" data-color="purple"></span>
                            <span class="badge filter badge-azure" data-color="azure"></span>
                            <span class="badge filter badge-green" data-color="green"></span>
                            <span class="badge filter badge-orange" data-color="orange"></span>
                            <span class="badge filter badge-danger active" data-color="danger"></span>
                        </div>
                        <div class="clearfix"></div>
                        <div class="ripple-container"></div>
                    </a>
                </li>
                <li class="header-title">Images</li>
                <li>
                    <a class="img-holder switch-trigger" href="javascript:void(0)">
                        <img src="./assets/img/sidebar-1.jpg" alt="">
                    </a>
                </li>
                <li>
                    <a class="img-holder switch-trigger" href="javascript:void(0)">
                        <img src="./assets/img/sidebar-2.jpg" alt="">
                        <div class="ripple-container"></div>
                    </a>
                </li>
                <li>
                    <a class="img-holder switch-trigger" href="javascript:void(0)">
                        <img src="./assets/img/sidebar-3.jpg" alt="">
                    </a>
                </li>
                <li class="active">
                    <a class="img-holder switch-trigger" href="javascript:void(0)">
                        <img src="./assets/img/sidebar-4.jpg" alt="">
                    </a>
                </li>
                <li class="button-container">
                    <div>
                        <button class="btn btn-info btn-block btn-fill" data-toggle="modal" data-target="#buy">
                            Download Free
                        </button>
                    </div>
                </li>
                <li class="button-container">
                    <div>
                        <button class="btn btn-warning btn-block btn-fill" data-toggle="modal" data-target="#buy">
                            Buy Pro
                        </button>
                    </div>
                </li>
                <li class="button-container text-center" routerLinkActive="active">
                    <div>
                        <a class="btn btn-default btn-block"
                            href="https://demos.creative-tim.com/material-dashboard-angular2/#/documentation/tutorial">
                            View Documentation
                        </a>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div> -->
<!-- Buy-Modal-angular -->
<!-- <div class="modal modal-angular fade" id="buy" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body text-center">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">&times;</span></button>
                <h4 class="margin-top">
                    Free Version
                </h4>
                <div class="separator"></div>
                <a href="https://www.creative-tim.com/product/material-dashboard" class="modal-button" target="_blank">
                    <div class="wrapper-card">
                        <div class="image-container">
                            <img src="./assets/img/html.png" alt="unloaded">
                        </div>
                        Html5
                        <div class="separator"></div>
                        <div class="product-type">
                            FREE
                        </div>
                    </div>
                </a>
                <a href="https://www.creative-tim.com/product/material-dashboard-angular2" class="modal-button"
                    target="_blank">
                    <div class="wrapper-card">
                        <div class="image-container image-angular-cli">
                            <img src="./assets/img/angular.png" alt="unloaded">
                        </div>
                        Angular
                        <div class="separator"></div>
                        <div class="product-type">
                            FREE
                        </div>
                    </div>
                </a>
                <h4>
                    PRO Version
                </h4>
                <div class="separator"></div>
                <a href="https://www.creative-tim.com/product/material-dashboard-pro" class="modal-button"
                    target="_blank">
                    <div class="image-container">
                        <img src="./assets/img/html.png" alt="unloaded">
                    </div>
                    Html5
                    <div class="separator"></div>
                    <div class="price">
                        from
                        <span>
                            49
                            <i class="fa fa-usd" aria-hidden="true"></i>
                        </span>
                    </div>
                </a>
                <a href="https://www.creative-tim.com/product/material-dashboard-pro-angular2" class="modal-button"
                    target="_blank">
                    <div class="image-container image-angular-cli">
                        <img src="./assets/img/angular.png" alt="unloaded">
                    </div>
                    Angular
                    <div class="separator"></div>
                    <div class="price">
                        from
                        <span>
                            59
                            <i class="fa fa-usd" aria-hidden="true"></i>
                        </span>
                    </div>
                </a>
            </div>
        </div>
    </div>

</div> -->