<mat-accordion>
    <mat-expansion-panel *ngFor="let file of files" (afterExpand)="accordion_open(file)">
        <mat-expansion-panel-header>
            {{file.name}}
        </mat-expansion-panel-header>
        <mat-divider></mat-divider>

        <!-- <mat-nav-list> -->
            <a *ngFor="let item of file.files; let in=index">

                <span fxFlex *ngIf="!item?.edit_ready" matLine>{{item?.title}} </span>

                <!-- <form fxLayout="row" fxFlex *ngIf="item?.edit_ready" class="example-form"> -->
                    <mat-form-field *ngIf="item?.edit_ready" fxFlex floatLabel="never" class="example-full-width">
                        <input [(ngModel)]="item.title"  matInput placeholder="Favorite food">
                    </mat-form-field>
                <!-- </form> -->

                <button (click)="delete_file(item)" mat-icon-button>
                    <mat-icon>delete</mat-icon>
                </button>
                <button *ngIf="!item?.edit_ready" (click)="read_edit(item)" mat-icon-button>
                    <mat-icon>edit</mat-icon>
                </button>

                <button *ngIf="item?.edit_ready" (click)="save_edit(item)" mat-icon-button>
                    <mat-icon>save</mat-icon>
                </button>
                <mat-divider [inset]="true"></mat-divider>
            </a>
        <!-- </mat-nav-list> -->
    </mat-expansion-panel>
</mat-accordion>

