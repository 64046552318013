import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ChangeDetectorRef, OnChanges} from '@angular/core';
import {FileManagerService} from '../file-manager.service';
import { DeleteDialogComponent } from '../../delete/delete-dialog/delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-files',
    templateUrl: './files.component.html',
    styleUrls: ['./files.component.styl']
})
export class FilesComponent implements OnInit, OnChanges {

    @Input() model_id: number;
    @Input() model_type: string;
    @Output() accordion_opened = new EventEmitter();
    @Output() deleted = new EventEmitter();

    public opened;
    @Input() files;
    @Input() uploadedFile;
    @Input() type;

    constructor(public service: FileManagerService, private ref: ChangeDetectorRef, public dialog: MatDialog) {
    }

    ngOnInit() {
        this.get_files();

        // setTimeout(() => {
        //     this.get_files();
        // }, 5000);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.uploadedFile) {
            this.get_files();
        }
    }

    get_files() {
        const params = {
            attachable_type: this.model_type,
            attachable_id: this.model_id,
            type:this.type
        };
        this.service.list(params, '/api/attachables/').subscribe(res => {
            this.files = res.data;
            // this.ref.detectChanges();
        });
    }

    accordion_open(file) {
        this.accordion_opened.emit(file);
    }

    read_edit(file) {
        file.edit_ready = true;
    }

    save_edit(file) {
        this.service.update(file).subscribe(res => {
            file.edit_ready = false;
        });
    }
    delete_file(file) {
        const dialogRef = this.dialog.open(DeleteDialogComponent, {
            width: '500px',
            data: {
                api: 'attachables',
                with_remarks: false,
                id: file.id,
                title: 'Do you want delete this',
                description: 'this will remove lesson'
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.get_files();
        });
    }
}
