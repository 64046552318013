<mat-toolbar color='primary'>
    <span>{{ data?.id ? "Edit" : "Add"}} Attachables</span>
    <span fxFlex=""></span>
    <button mat-icon-button="" mat-dialog-close matTooltip="close">
        <mat-icon class="c-w">close</mat-icon>
    </button>
</mat-toolbar>
<div class="main-content-form ">

    <mat-tab-group mat-stretch-tabs [dynamicHeight]="false" (selectedTabChange)="togglePlatform($event)">
        <mat-tab fxFlex label="IOS ">
            <div *ngIf="!attachables?.asset_bundle_ios" class="mat-padding">

                <app-file-manager [id_name]="'experience_id'" [title]="data.name" [type]="'asset_bundle'"
                    [platform]="'ios'" (file_uploaded)="get_files($event)" [multiple]="false" [model_id]="data.id"
                    [model_type]="'Experience'">
                </app-file-manager>

            </div>
            <div class="mat-padding" *ngIf="!attachables?.image">
                <app-file-manager (file_uploaded)="ImageUploaded($event)" [multiple]="true" [id_name]="'image'"
                    [title]="data.name" [type]="'image'" [platform]="'multi'" [multiple]="false" [model_id]="data.id"
                    [model_type]="'Experience'">
                </app-file-manager>
                <!-- <app-image (deleted)="refresh()" [size]="6" *ngIf="data?.images" [id]="data?.images?.id">
                </app-image> -->
            </div>


            <mat-accordion *ngIf="attachables?.asset_bundle_ios">
                <mat-expansion-panel>
                    <mat-expansion-panel-header [collapsedHeight]="'140px'">
                        Asset Bundle
                    </mat-expansion-panel-header>
                    <mat-divider></mat-divider>
                    <div fxLayout>
                        <div fxFlex>
                            <span fxFlex
                                *ngIf="!attachables?.asset_bundle_ios?.edit_ready && attachables?.asset_bundle_ios?.title"
                                matLine>{{attachables?.asset_bundle_ios?.title}} </span>
                            <span fxFlex
                                *ngIf="!attachables?.asset_bundle_ios?.edit_ready &&  !attachables?.asset_bundle_ios?.title"
                                matLine>{{attachables?.asset_bundle_ios?.file_name}} </span>

                            <form [formGroup]="form" fxLayout="column" fxLayoutGap="10px"
                                *ngIf="attachables?.asset_bundle_ios?.edit_ready" class="formContainer example-form">

                                <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="flex-end center">
                                    <mat-slide-toggle formControlName="featured" class="example-margin">
                                        Featured
                                    </mat-slide-toggle>
                                    <mat-slide-toggle formControlName="status" class="example-margin">
                                        Status
                                    </mat-slide-toggle>

                                </div>
                                <mat-form-field fxFlex class="example-full-width">
                                    <input matInput type="text" placeholder="Title" formControlName="title">
                                </mat-form-field>
                                <mat-form-field fxFlex class="example-full-width">
                                    <input matInput type="number" placeholder="CRC" formControlName="crc">
                                </mat-form-field>
                                <mat-form-field fxFlex class="example-full-width">
                                    <input matInput type="number" placeholder="Size" formControlName="size">
                                </mat-form-field>
                                <mat-form-field fxFlex class="example-full-width">
                                    <input matInput type="number" placeholder="Version" formControlName="version">
                                </mat-form-field>

                                <div fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="10px">
                                    <button type="button" (click)="togglePlatform()" mat-raised-button="">
                                        Cancel
                                    </button>
                                    <button type="button" (click)="form.reset()" mat-raised-button="" color="accent">
                                        Reset
                                    </button>
                                    <button (click)="save_edit(attachables?.asset_bundle_ios)" [disabled]="form.invalid"
                                        mat-raised-button="" color="primary">
                                        Save
                                    </button>
                                </div>

                            </form>
                        </div>
                        <button *ngIf="!attachables?.asset_bundle_ios?.edit_ready"
                            (click)="read_edit(attachables?.asset_bundle_ios)" mat-icon-button>
                            <mat-icon>edit</mat-icon>
                        </button>
                        <div fxFlex="20px">

                            <button (click)="delete_file(attachables?.asset_bundle_ios)" mat-icon-button>
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>

                    </div>



                </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion *ngIf="attachables?.image">
                <mat-expansion-panel (collapsedHeight)="'140px'">
                    <mat-expansion-panel-header>
                        Images </mat-expansion-panel-header>
                    <mat-divider></mat-divider>
                    <div>
                        <img class="fullImg"
                            src="/api/public/attachables/download_experience_image/{{attachables?.id}}/" alt="Images">
                        <div fxFlex>
                            <form [formGroup]="form" fxLayout="column" fxLayoutGap="10px"
                                *ngIf="attachables?.image?.edit_ready" class="formContainer example-form">

                                <mat-form-field fxFlex class="example-full-width">
                                    <input matInput type="text" placeholder="Title" formControlName="title">
                                </mat-form-field>

                                <div fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="10px">
                                    <button type="button" (click)="togglePlatform()" mat-raised-button="">
                                        Cancel
                                    </button>
                                    <button type="button" (click)="form.reset()" mat-raised-button="" color="accent">
                                        Reset
                                    </button>
                                    <button (click)="save_edit(attachables.image)" [disabled]="form.invalid"
                                        mat-raised-button="" color="primary">
                                        Save
                                    </button>
                                </div>

                            </form>
                        </div>
                        <span fxFlex *ngIf="!attachables?.image?.edit_ready && attachables?.image?.title"
                            matLine>{{attachables?.image?.title}} </span>
                        <button *ngIf="!attachables?.image.edit_ready" (click)="read_edit(attachables.image)"
                            mat-icon-button>
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button (click)="delete_file(attachables.image)" mat-icon-button>
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                    <!-- <a *ngFor="let image of attachables.images">
                        <img class="fullImg" src="/api/attachables/{{image?.id}}/download_attachment/" alt="Images">
                        <div fxFlex>
                            <form [formGroup]="form" fxLayout="column" fxLayoutGap="10px" *ngIf="image?.edit_ready"
                                class="formContainer example-form">

                                <mat-form-field fxFlex class="example-full-width">
                                    <input matInput type="text" placeholder="Title" formControlName="title">
                                </mat-form-field>

                                <div fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="10px">
                                    <button type="button" (click)="togglePlatform()" mat-raised-button="">
                                        Cancel
                                    </button>
                                    <button type="button" (click)="form.reset()" mat-raised-button="" color="accent">
                                        Reset
                                    </button>
                                    <button (click)="save_edit(image)" [disabled]="form.invalid" mat-raised-button=""
                                        color="primary">
                                        Save
                                    </button>
                                </div>

                            </form>
                        </div>
                        <button *ngIf="!image.edit_ready" (click)="read_edit(image)" mat-icon-button>
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button (click)="delete_file(image)" mat-icon-button>
                            <mat-icon>delete</mat-icon>
                        </button>
                        <mat-divider [inset]="true"></mat-divider>
                    </a> -->
                </mat-expansion-panel>
            </mat-accordion>

        </mat-tab>
        <mat-tab fxFlex label="Android">
            <div *ngIf="!attachables?.asset_bundle_android" class="mat-padding">

                <app-file-manager [id_name]="'experience_id'" [title]="data.name" [type]="'asset_bundle'"
                    [platform]="'android'" (file_uploaded)="get_files($event)" [multiple]="true" [model_id]="data.id"
                    [model_type]="'Experience'">
                </app-file-manager>

            </div>
            <div class="mat-padding" *ngIf="!attachables?.image">
                <app-file-manager (file_uploaded)="ImageUploaded($event)" [id_name]="'image'" [title]="data.name"
                    [type]="'image'" [platform]="'multi'" [multiple]="false" [model_id]="data.id"
                    [model_type]="'Experience'">
                </app-file-manager>
                <app-image (deleted)="refresh()" [size]="6" *ngIf="data?.images" [id]="data?.images?.id">
                </app-image>
            </div>

            <mat-accordion *ngIf="attachables?.asset_bundle_android">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        Asset Bundle
                    </mat-expansion-panel-header>
                    <mat-divider></mat-divider>

                    <div fxLayout>
                        <div fxFlex>
                            <span fxFlex
                                *ngIf="!attachables?.asset_bundle_android?.edit_ready && attachables?.asset_bundle_android?.title"
                                matLine>{{attachables?.asset_bundle_android?.title}} </span>
                            <span fxFlex
                                *ngIf="!attachables?.asset_bundle_android?.edit_ready &&  !attachables?.asset_bundle_android?.title"
                                matLine>{{attachables?.asset_bundle_android?.file_name}} </span>
                            <form [formGroup]="form" fxLayout="column" fxLayoutGap="10px"
                                *ngIf="attachables?.asset_bundle_android?.edit_ready"
                                class="formContainer example-form">

                                <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="flex-end center">
                                    <mat-slide-toggle formControlName="featured" class="example-margin">
                                        Featured
                                    </mat-slide-toggle>
                                    <mat-slide-toggle formControlName="status" class="example-margin">
                                        Status
                                    </mat-slide-toggle>

                                </div>
                                <mat-form-field fxFlex class="example-full-width">
                                    <input matInput type="text" placeholder="Title" formControlName="title">
                                </mat-form-field>
                                <mat-form-field fxFlex class="example-full-width">
                                    <input matInput type="number" placeholder="CRC" formControlName="crc">
                                </mat-form-field>
                                <mat-form-field fxFlex class="example-full-width">
                                    <input matInput type="number" placeholder="Size" formControlName="size">
                                </mat-form-field>
                                <mat-form-field fxFlex class="example-full-width">
                                    <input matInput type="number" placeholder="Version" formControlName="version">
                                </mat-form-field>

                                <div fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="10px">
                                    <button type="button" (click)="togglePlatform()" mat-raised-button="">
                                        Cancel
                                    </button>
                                    <button type="button" (click)="form.reset()" mat-raised-button="" color="accent">
                                        Reset
                                    </button>
                                    <button (click)="save_edit(attachables?.asset_bundle_android)"
                                        [disabled]="form.invalid" mat-raised-button="" color="primary">
                                        Save
                                    </button>
                                </div>

                            </form>

                        </div>
                        <!-- <span fxFlex *ngIf="!attachables?.image?.edit_ready && attachables?.image?.title"
                            matLine>{{attachables?.image?.title}} </span> -->
                        <button *ngIf="!attachables?.asset_bundle_android?.edit_ready"
                            (click)="read_edit(attachables?.asset_bundle_android)" mat-icon-button>
                            <mat-icon>edit</mat-icon>
                        </button>
                        <div fxFlex="20px">
                            <button (click)="delete_file(attachables?.asset_bundle_android)" mat-icon-button>
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>

            <mat-accordion *ngIf="attachables?.image !== null">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        Images </mat-expansion-panel-header>
                    <mat-divider></mat-divider>
                    <div>
                        <img class="fullImg"
                            src="/api/public/attachables/download_experience_image/{{attachables?.id}}/" alt="Images">
                        <div fxFlex>
                            <form [formGroup]="form" fxLayout="column" fxLayoutGap="10px"
                                *ngIf="attachables?.image?.edit_ready" class="formContainer example-form">

                                <mat-form-field fxFlex class="example-full-width">
                                    <input matInput type="text" placeholder="Title" formControlName="title">
                                </mat-form-field>

                                <div fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="10px">
                                    <button type="button" (click)="togglePlatform()" mat-raised-button="">
                                        Cancel
                                    </button>
                                    <button type="button" (click)="form.reset()" mat-raised-button="" color="accent">
                                        Reset
                                    </button>
                                    <button (click)="save_edit(attachables.image)" [disabled]="form.invalid"
                                        mat-raised-button="" color="primary">
                                        Save
                                    </button>
                                </div>

                            </form>
                        </div>
                        <span fxFlex *ngIf="!attachables?.image?.edit_ready && attachables?.image?.title"
                            matLine>{{attachables?.image?.title}} </span>
                        <button *ngIf="!attachables?.image.edit_ready" (click)="read_edit(attachables.image)"
                            mat-icon-button>
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button (click)="delete_file(attachables.image)" mat-icon-button>
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                    <!-- <a *ngFor="let image of attachables.images">
                        <img class="fullImg" src="/api/attachables/{{image?.id}}/download_attachment/" alt="Images">

                        <button *ngIf="!image.edit_ready" (click)="read_edit(image)" mat-icon-button>
                            <mat-icon>edit</mat-icon>
                        </button>

                        <button (click)="delete_file(image)" mat-icon-button>
                            <mat-icon>delete</mat-icon>
                        </button>
                        <mat-divider [inset]="true"></mat-divider>
                    </a> -->
                </mat-expansion-panel>
            </mat-accordion>

        </mat-tab>


    </mat-tab-group>
</div>