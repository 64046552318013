<div class="top-bar listing">
    <div fxFlex>Bulk User Upload </div>
    <div fxFlex fxLayoutAlign="flex-end" class="searchContainer">
        <div fxFlex fxLayoutAlign="flex-end" class="searchContainer listSearch">
            <span *ngIf="failed" style="font-size:18px">
                Total Failed: {{failed}} <br>
                <button mat-raised-button (click)="downloadFailed()">Download</button>
            </span>
            <!-- <button *ngIf="!searchbar" mat-icon-button class="minisearchbar" (click)="toggleSearch()">
                <mat-icon>search</mat-icon>
            </button>
            <div class="searchFilter" *ngIf="searchbar">
                <input type="text" placeholder="Filter Experiences " [(ngModel)]="filterExperiences"
                    (focusout)="toggleSearch()">
            </div> -->
        </div>
        <!-- <app-master-search></app-master-search> -->
    </div>
</div>
<div *ngIf="!isFetch" class="shimmerContainer shimmer-listing">
    <app-shimmer-loading [line]="0" [lineArrange]="'hr'" [description]="5">
    </app-shimmer-loading>
</div>
<div *ngIf="isFetch">
    <div *ngIf='empty' class="null listing">
        <div class="empty listing">
            There are currently no files uploaded under this user
        </div>
        <button (click)="addOrUpdateUsers({})" mat-mini-fab class="addButton empty" color="accent">
            <mat-icon class="example-icon" aria-hidden="false" aria-label="Example heart icon">
                add
            </mat-icon>
        </button>
    </div>
    <div class="containers listing " *ngIf='!empty' fxLayout fxLayoutGap="40px" fxLayout.xs="column">
        <div class="data-table">
            <div class="table">
                <button (click)="addOrUpdateUsers({})" mat-mini-fab class="addButton empty" color="accent">
                    <mat-icon class="example-icon" aria-hidden="false" aria-label="Example heart icon">
                        add
                    </mat-icon>
                </button>
                <mat-table [dataSource]="uploadedFiles">
                    <ng-container cdkColumnDef="id">
                        <mat-header-cell fxFlex="80px" *cdkHeaderCellDef>ID</mat-header-cell>
                        <mat-cell fxFlex="80px" *cdkCellDef="let row"> {{row.id}}</mat-cell>
                    </ng-container>

                    <ng-container cdkColumnDef="batch_name">
                        <mat-header-cell fxFlex *cdkHeaderCellDef>Batch Name</mat-header-cell>
                        <mat-cell fxFlex *cdkCellDef="let row">{{row.batch_name}}</mat-cell>
                    </ng-container>

                    <ng-container cdkColumnDef="created_at">
                        <mat-header-cell fxFlex *cdkHeaderCellDef>Uploaded at</mat-header-cell>
                        <mat-cell fxFlex *cdkCellDef="let row">{{row.created_at}}</mat-cell>
                    </ng-container>
                    <ng-container cdkColumnDef="total_successful">
                        <mat-header-cell fxFlex *cdkHeaderCellDef>Success Count</mat-header-cell>
                        <mat-cell fxFlex *cdkCellDef="let row">{{row.total_successful}}</mat-cell>
                    </ng-container>

                    <ng-container cdkColumnDef="total_failed">
                        <mat-header-cell fxFlex *cdkHeaderCellDef>Failed Count</mat-header-cell>
                        <mat-cell fxFlex *cdkCellDef="let row">{{row.total_failed}}</mat-cell>
                    </ng-container>

                    <ng-container cdkColumnDef="status">
                        <mat-header-cell fxFlex *cdkHeaderCellDef>Status</mat-header-cell>
                        <mat-cell fxFlex *cdkCellDef="let row">{{row.status}}</mat-cell>
                    </ng-container>

                    <!-- <ng-container cdkColumnDef="updated_at">
                    <mat-header-cell *cdkHeaderCellDef>Updated at</mat-header-cell>
                    <mat-cell *cdkCellDef="let row">{{row.updated_at}}</mat-cell>
                </ng-container>
                <ng-container cdkColumnDef="user_id">
                    <mat-header-cell *cdkHeaderCellDef>User</mat-header-cell>
                    <mat-cell *cdkCellDef="let row">{{row.user_id}}</mat-cell>
                </ng-container> -->

                    <ng-container cdkColumnDef="actions">
                        <mat-header-cell fxFlex="80px" fxLayoutAlign="flex-end center" *cdkHeaderCellDef>
                        </mat-header-cell>
                        <mat-cell fxFlex="80px" fxLayoutAlign="flex-end center" *cdkCellDef="let row">
                            <div>
                                <button mat-icon-button [matMenuTriggerFor]="menu">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item (click)="import(row)">
                                        <mat-icon>edit</mat-icon>
                                        <span>Import</span>
                                    </button>
                                    <button mat-menu-item (click)="downloadFailed(row?.id)"
                                        *ngIf="row?.status=='Completed' && row?.total_failed > 0">
                                        <mat-icon>file_download</mat-icon>
                                        <span>Download Failed</span>
                                    </button>
                                    <app-delete-button [icon]="'delete'" (deleted)="deleted()"
                                        description="'This will remove the bulk user file'"
                                        [title]="'Do you want to delete this?'" [with_remarks]="false"
                                        [api]="'bulk_users'" [id]="row.id">
                                    </app-delete-button>
                                </mat-menu>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *cdkRowDef="let row; columns: displayedColumns;"></mat-row>
                </mat-table>
            </div>

        </div>
    </div>
</div>