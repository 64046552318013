import { Injectable } from '@angular/core';
import { NxDataService } from '../decorators/NxDataService';
import { HttpClient } from '@angular/common/http';
export interface StatsService extends NxDataService<any> {
    test;
}

@Injectable({
    providedIn: 'root'
})

@NxDataService({
    url: '/api/stats'
})

export class StatsService {
    constructor(public http: HttpClient) {
    }
    getEnrollmentsDaily(user_type, criteria, month?, year?) {
        if (month && year) {
            return this.http.get<any>(`api/stats/number_of_enrollments?user_type=${user_type}&criteria=${criteria}&month=${month}&year=${year}`);
        }
        else if (month) {
            return this.http.get<any>(`api/stats/number_of_enrollments?user_type=${user_type}&criteria=${criteria}&month=${month}`);

        } else {
            return this.http.get<any>(`api/stats/number_of_enrollments?user_type=${user_type}&criteria=${criteria}`);
        }

    }
    getEnrollmentsMonthly(user_type, criteria, year?) {
        if (year) {
            return this.http.get<any>(`api/stats/number_of_enrollments?user_type=${user_type}&criteria=${criteria}&year=${year}`);

        } else {
            return this.http.get<any>(`api/stats/number_of_enrollments?user_type=${user_type}&criteria=${criteria}`);
        }

    }
    getEnrollmentsYearly(user_type, criteria, fromYear?, toYear?) {
        if (fromYear && toYear) {
            return this.http.get<any>(`api/stats/number_of_enrollments?user_type=${user_type}&criteria=${criteria}&fromYear=${fromYear}&toYear=${toYear}`);

        } else {
            return this.http.get<any>(`api/stats/number_of_enrollments?user_type=${user_type}&criteria=${criteria}`);
        }
    }


    getUserRevenuesDaily(user_type, criteria, month?, year?) {
        if (month && year) {
            return this.http.get<any>(`api/stats/users_revenues?user_type=${user_type}&criteria=${criteria}&month=${month}&year=${year}`);
        }
        else if (month) {
            return this.http.get<any>(`api/stats/users_revenues?user_type=${user_type}&criteria=${criteria}&month=${month}`);

        } else {
            return this.http.get<any>(`api/stats/users_revenues?user_type=${user_type}&criteria=${criteria}`);
        }

    }
    getUserRevenuesMonthly(user_type, criteria, year?) {
        if (year) {
            return this.http.get<any>(`api/stats/users_revenues?user_type=${user_type}&criteria=${criteria}&year=${year}`);

        } else {
            return this.http.get<any>(`api/stats/users_revenues?user_type=${user_type}&criteria=${criteria}`);
        }

    }
    getUserRevenuesYearly(user_type, criteria, fromYear?, toYear?) {
        if (fromYear && toYear) {
            return this.http.get<any>(`api/stats/users_revenues?user_type=${user_type}&criteria=${criteria}&fromYear=${fromYear}&toYear=${toYear}`);

        } else {
            return this.http.get<any>(`api/stats/users_revenues?user_type=${user_type}&criteria=${criteria}`);
        }
    }
    getCountryRevenuesDaily(country_id, criteria, month?, year?) {
        if (month && year) {
            return this.http.get<any>(`api/stats/country_revenues?criteria=${criteria}&country_id=${country_id}&month=${month}&year=${year}`);
        }
        else if (month) {
            return this.http.get<any>(`api/stats/country_revenues?criteria=${criteria}&country_id=${country_id}&month=${month}`);

        } else {
            return this.http.get<any>(`api/stats/country_revenues?criteria=${criteria}&country_id=${country_id}`);
        }

    }
    getCountryRevenuesMonthly(country_id, criteria, year?) {
        if (year) {
            return this.http.get<any>(`api/stats/country_revenues?criteria=${criteria}&country_id=${country_id}&year=${year}`);

        } else {
            return this.http.get<any>(`api/stats/country_revenues?criteria=${criteria}&country_id=${country_id}`);
        }

    }
    getCountryRevenuesYearly(country_id, criteria, fromYear?, toYear?) {
        if (fromYear && toYear) {
            return this.http.get<any>(`api/stats/country_revenues?criteria=${criteria}&country_id=${country_id}&fromYear=${fromYear}&toYear=${toYear}`);

        } else {
            return this.http.get<any>(`api/stats/country_revenues?criteria=${criteria}&country_id=${country_id}`);
        }
    }




    // public getEnrollments(user_type, criteria) {
    //     return this.http.get<any>(`api/stats/number_of_enrollments?user_type=${user_type}&criteria=${criteria}`);
    // }
    // public getCountryRevenues(country_id, criteria) {
    //     return this.http.get<any>(`api/stats/country_revenues?criteria=${criteria}&country_id=${country_id}`);
    // }
    // public getRevenues(user_type, criteria) {
    //     return this.http.get<any>(`api/stats/users_revenues?user_type=${user_type}&criteria=${criteria}`);
    // }
    public getLocalizedExperiences(criteria) {
        return this.http.get<any>(`api/stats/localised_experience?criteria=${criteria}`);
    }
    public getRegionManagerStats() {
        return this.http.get<any>(`api/stats/region_manager`);
    }
    public getRegionManagerSubscriptionStats(user_type, criteria) {
        console.log(user_type)
        if (user_type == 'all') {
            console.log('hit here')
            return this.http.get<any>(`api/stats/region_subscriptions?criteria=${criteria}`);
        } else {
            return this.http.get<any>(`api/stats/region_subscriptions?user_type=${user_type}&criteria=${criteria}`);
        }
    }

    getRegionManagerSubscriptionStatsDaily(user_type, criteria, month?, year?) {
        if (month && year) {
            if (user_type == 'all') {
                return this.http.get<any>(`api/stats/region_subscriptions?criteria=${criteria}&month=${month}&year=${year}`);
            } else {
                return this.http.get<any>(`api/stats/region_subscriptions?user_type=${user_type}&criteria=${criteria}&month=${month}&year=${year}`);
            }
        }
        else if (month) {
            if (user_type == 'all') {
                return this.http.get<any>(`api/stats/region_subscriptions?criteria=${criteria}&month=${month}`);
            } else {
                return this.http.get<any>(`api/stats/region_subscriptions?user_type=${user_type}&criteria=${criteria}&month=${month}`);
            }

        } else {
            if (user_type == 'all') {
                return this.http.get<any>(`api/stats/region_subscriptions?criteria=${criteria}`);
            } else {
                return this.http.get<any>(`api/stats/region_subscriptions?user_type=${user_type}&criteria=${criteria}`);
            }
        }
    }
    getRegionManagerSubscriptionStatsMonthly(user_type, criteria, year?) {
        if (year) {
            if (user_type == 'all') {
                return this.http.get<any>(`api/stats/region_subscriptions?criteria=${criteria}&year=${year}`);
            } else {
                return this.http.get<any>(`api/stats/region_subscriptions?user_type=${user_type}&criteria=${criteria}&year=${year}`);
            }
        } else {
            if (user_type == 'all') {
                return this.http.get<any>(`api/stats/region_subscriptions?criteria=${criteria}`);
            } else {
                return this.http.get<any>(`api/stats/region_subscriptions?user_type=${user_type}&criteria=${criteria}`);
            }
        }
    }
    getRegionManagerSubscriptionStatsYearly(user_type, criteria, fromYear?, toYear?) {
        if (fromYear && toYear) {
            if (user_type == 'all') {
                return this.http.get<any>(`api/stats/region_subscriptions?criteria=${criteria}&fromYear=${fromYear}&toYear=${toYear}`);
            } else {
                return this.http.get<any>(`api/stats/region_subscriptions?user_type=${user_type}&criteria=${criteria}&fromYear=${fromYear}&toYear=${toYear}`);
            }

        } else {
            if (user_type == 'all') {
                return this.http.get<any>(`api/stats/region_subscriptions?criteria=${criteria}`);
            } else {
                return this.http.get<any>(`api/stats/region_subscriptions?user_type=${user_type}&criteria=${criteria}`);
            }
        }
    }

// Paid Subscription
getRegionManagerPaidSubscriptionStatsDaily(user_type, criteria, month?, year?) {
    if (month && year) {
        if (user_type == 'all') {
            return this.http.get<any>(`api/stats/region_paid_subscriptions?criteria=${criteria}&month=${month}&year=${year}`);
        } else {
            return this.http.get<any>(`api/stats/region_paid_subscriptions?user_type=${user_type}&criteria=${criteria}&month=${month}&year=${year}`);
        }
    }
    else if (month) {
        if (user_type == 'all') {
            return this.http.get<any>(`api/stats/region_paid_subscriptions?criteria=${criteria}&month=${month}`);
        } else {
            return this.http.get<any>(`api/stats/region_paid_subscriptions?user_type=${user_type}&criteria=${criteria}&month=${month}`);
        }

    } else {
        if (user_type == 'all') {
            return this.http.get<any>(`api/stats/region_paid_subscriptions?criteria=${criteria}`);
        } else {
            return this.http.get<any>(`api/stats/region_paid_subscriptions?user_type=${user_type}&criteria=${criteria}`);
        }
    }
}
getRegionManagerPaidSubscriptionStatsMonthly(user_type, criteria, year?) {
    if (year) {
        if (user_type == 'all') {
            return this.http.get<any>(`api/stats/region_paid_subscriptions?criteria=${criteria}&year=${year}`);
        } else {
            return this.http.get<any>(`api/stats/region_paid_subscriptions?user_type=${user_type}&criteria=${criteria}&year=${year}`);
        }
    } else {
        if (user_type == 'all') {
            return this.http.get<any>(`api/stats/region_paid_subscriptions?criteria=${criteria}`);
        } else {
            return this.http.get<any>(`api/stats/region_paid_subscriptions?user_type=${user_type}&criteria=${criteria}`);
        }
    }
}
getRegionManagerPaidSubscriptionStatsYearly(user_type, criteria, fromYear?, toYear?) {
    if (fromYear && toYear) {
        if (user_type == 'all') {
            return this.http.get<any>(`api/stats/region_paid_subscriptions?criteria=${criteria}&fromYear=${fromYear}&toYear=${toYear}`);
        } else {
            return this.http.get<any>(`api/stats/region_paid_subscriptions?user_type=${user_type}&criteria=${criteria}&fromYear=${fromYear}&toYear=${toYear}`);
        }

    } else {
        if (user_type == 'all') {
            return this.http.get<any>(`api/stats/region_paid_subscriptions?criteria=${criteria}`);
        } else {
            return this.http.get<any>(`api/stats/region_paid_subscriptions?user_type=${user_type}&criteria=${criteria}`);
        }
    }
}


    public getRegionManagerPaidSubscriptionStats(user_type, criteria) {
        if (user_type == 'all') {
            return this.http.get<any>(`api/stats/region_paid_subscriptions?criteria=${criteria}`);
        } else {
            return this.http.get<any>(`api/stats/region_paid_subscriptions?user_type=${user_type}&criteria=${criteria}`);
        }
    }

    public getRegionManagerEnrollments(user_type, criteria) {
        return this.http.get<any>(`api/stats/region_manager_enrollments?user_type=${user_type}&criteria=${criteria}`);
    }
    public getRegionManagerUsersRevenues(criteria) {
        return this.http.get<any>(`api/stats/region_manager_users_revenue?criteria=${criteria}`);
    }
    public getRegionManagerSchoolRevenues(criteria) {
        return this.http.get<any>(`api/stats/region_manager_school_revenue?criteria=${criteria}`);
    }
    public getSchoolStats() {
        return this.http.get<any>(`api/stats/schools`);

    }
    // public getSchoolEnrollments(user_type, criteria) {
    //     return this.http.get<any>(`api/stats/school_enrollments?user_type=${user_type}&criteria=${criteria}`);
    // }

    getSchoolEnrollmentsDaily(user_type, criteria, month?, year?) {
        if (month && year) {
            return this.http.get<any>(`api/stats/school_enrollments?user_type=${user_type}&criteria=${criteria}&month=${month}&year=${year}`);
        }
        else if (month) {
            return this.http.get<any>(`api/stats/school_enrollments?user_type=${user_type}&criteria=${criteria}&month=${month}`);

        } else {
            return this.http.get<any>(`api/stats/school_enrollments?user_type=${user_type}&criteria=${criteria}`);
        }

    }
    getSchoolEnrollmentsMonthly(user_type, criteria, year?) {
        if (year) {
            return this.http.get<any>(`api/stats/school_enrollments?user_type=${user_type}&criteria=${criteria}&year=${year}`);

        } else {
            return this.http.get<any>(`api/stats/school_enrollments?user_type=${user_type}&criteria=${criteria}`);
        }

    }
    getSchoolEnrollmentsYearly(user_type, criteria, fromYear?, toYear?) {
        if (fromYear && toYear) {
            return this.http.get<any>(`api/stats/school_enrollments?user_type=${user_type}&criteria=${criteria}&fromYear=${fromYear}&toYear=${toYear}`);

        } else {
            return this.http.get<any>(`api/stats/school_enrollments?user_type=${user_type}&criteria=${criteria}`);
        }
    }


    public getRegionManagerSchoolStats(id) {
        return this.http.get<any>(`api/stats/schools/${id}`);

    }
}
