import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-empty-container',
    templateUrl: './empty-container.component.html',
    styleUrls: ['./empty-container.component.scss']
})
export class EmptyContainerComponent implements OnInit {
    @Input('button') button: boolean = false;
    @Input("data") data: string;

    constructor() { }

    ngOnInit() {
        this.data = this.data ? this.data : 'data';
    }


}
