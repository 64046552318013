import { Injectable } from '@angular/core';
import { NxDataService } from '../decorators/NxDataService';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class DeleteService {
    constructor(public http: HttpClient) { }
    public delete(data: any, form?) {
        if (data.second_variable_id) {
          return  this.http.delete('api/' + data.api + '/' + data.group_code + '/' + data.second_variable_id + '?body=' + form.value.remarks);
            
        } else {
           return this.http.delete('api/' + data.api + '/' + data.id + '?body=' + form.value.remarks);
        }


    }
}
