<div id="login" fxLayout="column">

    <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <mat-card id="login-form">

            <div class="logoContainer">
                <div class="logo">
                    <img src="assets/images/defaultImage.png">
                </div>

            </div>

            <!-- <div class="title">LOGIN TO YOUR ACCOUNT</div> -->

            <form (submit)="submit()" name="loginForm" [formGroup]="loginForm" novalidate>

                <mat-form-field class="email" appearance="outline">
                    <mat-label>Email</mat-label>

                    <input matInput formControlName="email">
                    <mat-error *ngIf="loginForm.get('email')?.errors?.required">
                        Email is required
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="password" appearance="outline">
                    <mat-label>Password</mat-label>

                    <input matInput type="password" formControlName="password">
                    <mat-error *ngIf="loginForm.get('password')?.errors?.required">
                        Password is required
                    </mat-error>
                </mat-form-field>

                <!-- <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column"
                         fxLayoutAlign="space-between center">
                        <mat-checkbox class="remember-me" aria-label="Remember Me">
                            Remember Me
                        </mat-checkbox>
    
                        <a class="forgot-password" [routerLink]="'/login'">
                            Forgot Password?
                        </a>
                    </div> -->

                <!-- <re-captcha
                        #captchaRef="reCaptcha"
                        (resolved)="resolved($event)"
                        size="invisible"
                    ></re-captcha> -->

                <button mat-raised-button color="primary" class="submit-button" aria-label="LOG IN"
                    [disabled]="loginForm.invalid">
                    <span>Login</span>
                </button>

            </form>

        </mat-card>

    </div>

</div>