<div fxLayout="column" fxLayoutGap="30px" class="dashboard-stat">
    <div fxFlex fxLayout>
        <mat-card fxFlex style="position: relative; 
            width: 100%;">
            <mat-card-header>
                <div fxFlex fxLayout="row" fxLayout.xs="column" class="chartHeader">
                    <div fxFlex>
                        <div class="card-category">Total Enrollments</div>
                        <div class="card-title"><span *ngIf="enrollment_user_type =='student'">Students</span>
                            <span *ngIf="enrollment_user_type =='teacher'">Teachers</span>
                        </div>
                    </div>
                    <div fxFlex fxLayout fxLayout.xs="column" fxLayoutGap="20px" class="filter-container" fxLayoutAlign="flex-end">
                        <div>
                            <mat-form-field fxFlex>
                                <mat-select placeholder="User Type" [(value)]="enrollment_user_type" (selectionChange)="submitUserTypeEnrollment($event.value)">
                                    <mat-option value="student">Student</mat-option>
                                    <mat-option value="teacher">Teacher</mat-option>

                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field fxFlex>
                                <mat-select placeholder="Filter by" [(value)]="enrollment_criteria" (selectionChange)="submitCriteriaEnrollment($event.value)">
                                    <mat-option value="daily">Daily</mat-option>
                                    <mat-option value="monthly">Monthly</mat-option>
                                    <mat-option value="yearly">Yearly</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div *ngIf="enrollment_criteria=='daily'">
                        <mat-form-field fxFlex>
                            <mat-select placeholder="Month" [(value)]="selectedDailyMonth" (selectionChange)="submitDailyMonthYear($event.value)">
                                <mat-option [value]="1">January</mat-option>
                                <mat-option [value]="2">February</mat-option>
                                <mat-option [value]="3">March</mat-option>
                                <mat-option [value]="4">April</mat-option>
                                <mat-option [value]="5">May</mat-option>
                                <mat-option [value]="6">June</mat-option>
                                <mat-option [value]="7">July</mat-option>
                                <mat-option [value]="8">August</mat-option>
                                <mat-option [value]="9">September</mat-option>
                                <mat-option [value]="10">October</mat-option>
                                <mat-option [value]="11">November</mat-option>
                                <mat-option [value]="12">December</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="enrollment_criteria=='daily' && selectedDailyMonth">
                        <form [formGroup]="dailyYearForm" (submit)="submitDailyMonthYear($event.value)" fxLayout="row" style="align-items: flex-end;" fxLayoutGap="20px">
                            <mat-form-field fxFlex>
                                <input matInput placeholder="Year" type="number" formControlName="selectedDailyYear" (change)="submitDailyMonthYear($event.value)">
                            </mat-form-field>
                        </form>
                    </div>
                    <div *ngIf="enrollment_criteria=='monthly'">
                        <form [formGroup]="monthlyYearForm" (submit)="submitMonthlyYear($event.value)" fxLayout="row" style="align-items: flex-end;" fxLayoutGap="20px">
                            <mat-form-field fxFlex>
                                <input matInput placeholder="Year" type="number" formControlName="selectedMonthlyYear" (change)="submitMonthlyYear($event.value)">
                            </mat-form-field>
                        </form>
                    </div>
                    <div *ngIf="enrollment_criteria=='yearly'">
                        <form [formGroup]="yearlyForm" (submit)="submitYearly($event.value)" fxLayout="row" style="align-items: flex-end;" fxLayoutGap="20px">
                            <mat-form-field fxFlex="60px">
                                <input matInput placeholder="From Year" type="number" formControlName="fromYear" (change)="submitYearly($event.value)">
                            </mat-form-field>
                            <mat-form-field fxFlex="60px">
                                <input matInput placeholder="To Year" type="number" formControlName="toYear" (change)="submitYearly($event.value)">
                            </mat-form-field>
                        </form>
                    </div>
                </div>
            </mat-card-header>
            <div class=" chart-container" style="position: relative; height:250px; width:100%">
                <canvas #enrollmentCanvas id="canvas">{{ enrollmentChart }}</canvas>
            </div>
            <mat-card-content>
            </mat-card-content>
        </mat-card>
    </div>

    <div fxFlex fxLayout fxLayoutGap="30px" class="row">
        <div fxFlex class="col-md-6 col-lg-3">
            <mat-card fxFlex>

                <div class="stats-card">
                    <div class="card-body">
                        <div fxLayout class="row">
                            <div fxFlex>
                                <div class="numbers">
                                    <div class="card-category">Total Users</div>
                                    <div class="card-title">{{stats?.total_users}}</div>
                                </div>
                            </div>
                            <div fxFlex="65px" fxLayoutAlign="flex-end" class="col-5">
                                <div class="stat-icon student">
                                    <i class="fal fa-user-graduate "></i>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </mat-card>
        </div>
        <div fxFlex class="col-md-6 col-lg-3">
            <mat-card fxFlex>

                <div class="stats-card">
                    <div class="card-body">
                        <div fxLayout class="row">
                            <div fxFlex>
                                <div class="numbers">
                                    <div class="card-category">Total Downloaded Lessons</div>
                                    <div class="card-title">{{stats?.total_lessons_download}}</div>
                                </div>
                            </div>
                            <div fxFlex="65px" fxLayoutAlign="flex-end" class="col-5">
                                <div class="stat-icon download">
                                    <i class="fal fa-arrow-alt-to-bottom"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </mat-card>
        </div>
        <div fxFlex class="col-md-6 col-lg-3">
            <mat-card fxFlex>

                <div class="stats-card">
                    <div class="card-body">
                        <div fxLayout class="row">
                            <div fxFlex>
                                <div class="numbers">
                                    <div class="card-category">Total Uploaded Lessons</div>
                                    <div class="card-title">{{stats?.total_lessons_uploaed}}</div>
                                </div>
                            </div>
                            <div fxFlex="65px" fxLayoutAlign="flex-end" class="col-5">
                                <div class="stat-icon upload">
                                    <i class="fal fa-arrow-alt-to-top"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </mat-card>
        </div>
        <div fxFlex class="col-md-6 col-lg-3">
            <mat-card fxFlex>

                <div class="stats-card">
                    <div class="card-body">
                        <div fxLayout class="row">
                            <div fxFlex>
                                <div class="numbers">
                                    <div class="card-category">Total Main Experiences</div>
                                    <div class="card-title">{{stats?.experience_count}}</div>
                                </div>
                            </div>
                            <div fxFlex="65px" fxLayoutAlign="flex-end" class="col-5">
                                <div class="stat-icon experience">
                                    <i class="fal fa-atom-alt "></i>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </mat-card>
        </div>
        <div fxFlex class="col-md-6 col-lg-3">
            <mat-card fxFlex>

                <div class="stats-card">
                    <div class="card-body">
                        <div fxLayout class="row">
                            <div fxFlex>
                                <div class="numbers">
                                    <div class="card-category">Total Local Experiences</div>
                                    <div class="card-title">{{stats?.local_experience_count}}</div>
                                </div>
                            </div>
                            <div fxFlex="65px" fxLayoutAlign="flex-end" class="col-5">
                                <div class="stat-icon experience">
                                    <i class="fal fa-atom-alt "></i>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </mat-card>
        </div>


    </div>
    <div fxFlex fxLayout fxLayoutGap="30px" class="row">
        <mat-card fxFlex>
            <mat-card-header>
                <div fxFlex fxLayout="row" class="chartHeader">
                    <div fxFlex>
                        <div class="card-category">Enrollments</div>
                        <div class="card-title">Students</div>
                    </div>
                </div>
            </mat-card-header>
            <div class=" chart-container">
                <canvas #studentsPie id="canvas" class="pieChart">{{ studentPieChart }}</canvas>
            </div>
        </mat-card>
        <mat-card fxFlex>
            <mat-card-header>
                <div fxFlex fxLayout="row" class="chartHeader">
                    <div fxFlex>
                        <div class="card-category">Enrollments</div>
                        <div class="card-title">Teachers</div>
                    </div>
                </div>
            </mat-card-header>
            <div class=" chart-container">
                <canvas #teachersPie id="canvas" class="pieChart">{{ teacherPieChart }}</canvas>
            </div>
        </mat-card>
        <mat-card fxFlex>
            <mat-card-header>
                <div fxFlex fxLayout="row" class="chartHeader">
                    <div fxFlex>
                        <div class="card-category">Enrollments</div>
                        <div class="card-title">Users</div>
                    </div>
                </div>
            </mat-card-header>
            <div class=" chart-container">
                <canvas #usersPie id="canvas" class="pieChart">{{ userPieChart }}</canvas>
            </div>
        </mat-card>
    </div>

</div>