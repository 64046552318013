<mat-toolbar color='primary'>
    <span>{{ data?.id ? "Edit" : "Add"}} User</span>
    <span fxFlex=""></span>
    <button mat-icon-button="" mat-dialog-close matTooltip="close">
        <mat-icon class="c-w">close</mat-icon>
    </button>
</mat-toolbar>
<div class="main-content-form">

    <form [formGroup]="form" (submit)="submit()" fxLayout="column" fxLayoutGap="2px" class="mat-padding">

        <div fxFlex fxLayoutAlign="flex-end center">
            <div fxFlex *ngIf='isRegionManager'>

                <mat-slide-toggle formControlName="active" class="example-margin">
                    Active
                </mat-slide-toggle>
            </div>
            <div fxFlex *ngIf='isSchool'>

                <mat-slide-toggle formControlName="status" class="example-margin">
                    Status
                </mat-slide-toggle>
            </div>
            <div fxFlex fxLayoutAlign="flex-end center">
                <mat-checkbox formControlName="verified">Verified</mat-checkbox>
            </div>
        </div>

        <mat-form-field fxFlex>
            <input matInput type="text" placeholder="Name" formControlName="name" required>
            <mat-error *ngIf="form.get('name')?.errors?.required">
                Name is <strong>required</strong>
            </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex>
            <input matInput type="email" placeholder="Email" formControlName="email" required>
            <mat-error *ngIf="form.get('email')?.errors?.email && !form.get('email')?.errors?.required">
                Please enter a valid email address
            </mat-error>
            <mat-error *ngIf="form.get('email')?.errors?.required">
                Email is <strong>required</strong>
            </mat-error>
        </mat-form-field>




        <div fxLayout="row" fxLayoutGap="24px">

            <!-- <mat-form-field fxFlex>
                <mat-select #typeTemp (selectionChange)="typeChange($event)" placeholder="Type" formControlName="type" required>
                    <mat-option [value]="'organization'">Organization</mat-option>
                    <mat-option [value]="'person'">Person</mat-option>
                </mat-select>
            </mat-form-field> -->

            <mat-form-field fxFlex>
                <mat-select placeholder="User Type" formControlName="user_type" (selectionChange)="userTypeSelected($event.value)" required>
                    <mat-option [value]="'admin'">Admin</mat-option>
                    <mat-option [value]="'student'">Student</mat-option>
                    <mat-option [value]="'teacher'">Teacher</mat-option>
                    <mat-option [value]="'school'">School</mat-option>
                    <mat-option [value]="'region_manager'">Region Manager</mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('user_type')?.errors?.required">
                    User Type is <strong>required</strong>
                </mat-error>
            </mat-form-field>
        </div>

        <div *ngIf='isRegionManager'>
            <!-- <div fxLayout="row" fxLayoutAlign="flex-end center">
                <mat-slide-toggle formControlName="active" class="example-margin">
                    Active
                </mat-slide-toggle>
            </div> -->
            <div fxLayout="row">
                <mat-form-field fxFlex>
                    <input matInput type="number" placeholder="Number of Schools" formControlName="number_of_schools">
                    <mat-error *ngIf="form.get('number_of_schools')?.errors?.required">
                        Number of Schools is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="row">
                <mat-form-field fxFlex>
                    <input matInput type="number" placeholder="Number of Users" formControlName="number_of_users">
                    <mat-error *ngIf="form.get('number_of_users')?.errors?.required">
                        Number of Users is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="row">
                <mat-form-field fxFlex>
                    <input matInput type="text" placeholder="Remarks" formControlName="remarks">
                    <mat-error *ngIf="form.get('remarks')?.errors?.required">
                        Remarks is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <input matInput [matDatepicker]="pickers" placeholder="Expires at" formControlName="expire_at">
                    <mat-datepicker-toggle matSuffix [for]="pickers"></mat-datepicker-toggle>
                    <mat-datepicker #pickers></mat-datepicker>
                </mat-form-field>
            </div>

        </div>
        <div *ngIf='isSchool'>
            <!-- <div fxLayout="row" fxLayoutAlign="flex-end center">
                <mat-slide-toggle formControlName="active" class="example-margin">
                    Active
                </mat-slide-toggle>
            </div> -->
            <div fxLayout="row">
                <mat-form-field fxFlex>
                    <input matInput type="number" placeholder="Number of Students" formControlName="number_students">
                    <mat-error *ngIf="form.get('number_students')?.errors?.required">
                        Number of Students is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="row">
                <mat-form-field fxFlex>
                    <input matInput type="number" placeholder="Number of Teachers" formControlName="number_teachers">
                    <mat-error *ngIf="form.get('number_teachers')?.errors?.required">
                        Number of Teachers is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxLayout="row">
                <mat-form-field fxFlex>
                    <input matInput type="number" placeholder="Amount" formControlName="amount">
                    <mat-error *ngIf="form.get('amount')?.errors?.required">
                        Amount is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- <div fxLayout="row">
                <mat-form-field fxFlex>
                    <input matInput type="text" placeholder="Region Manager" formControlName="region_manager_id">
                </mat-form-field>
            </div> -->
            <div fxLayout="row">
                <mat-form-field fxFlex>
                    <input matInput placeholder="Select Region Manager  " aria-label="User" [matAutocomplete]="auto" formControlName="user">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="selectedUser">
                        <mat-option *ngFor="let user of filteredRegionManagers" [value]="user">
                            <span>{{user?.name}} , {{user?.email}} </span>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field>
                    <input matInput [matDatepicker]="pickers" placeholder="Expires at" formControlName="expired_at">
                    <mat-datepicker-toggle matSuffix [for]="pickers"></mat-datepicker-toggle>
                    <mat-datepicker #pickers></mat-datepicker>
                </mat-form-field>
            </div>

        </div>
        <!-- <div fxLayout="row">
        <mat-form-field fxFlex>
            <mat-select placeholder="Current Login Device" formControlName="current_login_device" required>
                <mat-option [value]="'android'">Android</mat-option>
                <mat-option [value]="'ios'">IOS</mat-option>
            </mat-select>
        </mat-form-field>
    </div> -->

        <div fxLayout="row">
            <mat-form-field fxFlex>
                <input matInput placeholder="Select Country" aria-label="Country" [matAutocomplete]="auto" formControlName="country">
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)='getLanguages($event.option.value.id)' [displayWith]="selectedCountry">
                    <mat-option *ngFor="let country of filteredCountries" [value]="country">
                        <span>{{ country.name }}</span>
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="form.get('country')?.errors?.required">
                    Country is <strong>required</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row" *ngIf='showLanguage'>
            <mat-form-field fxFlex>
                <mat-label>Select Language</mat-label>
                <mat-select formControlName="language_id">
                    <mat-option *ngFor="let language of filteredLanguages" [value]="language.id">{{language.name}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('language_id')?.errors?.required">
                    Language is <strong>required</strong>
                </mat-error>
            </mat-form-field>

        </div>




        <div fxLayout="row">
            <mat-form-field fxFlex>
                <input matInput type="text" placeholder="Contact Number" formControlName="contact_number">
            </mat-form-field>
        </div>
        <div *ngIf="data?.id" fxLayout="row">
            <mat-slide-toggle fxFlex [checked]="changePassword" (change)="toggleChangePassword($event)" class="example-margin">Change Password
            </mat-slide-toggle>
        </div>
        <div *ngIf="!data?.id || changePassword" fxLayout="row" fxLayoutGap="24px">
            <!-- <div fxLayout="row"  fxLayoutGap="24px"> -->
            <mat-form-field fxFlex>
                <input matInput type="password" placeholder="Password" formControlName="password">
                <mat-error *ngIf="form.get('password')?.errors?.required">
                    Password is <strong>required</strong>
                </mat-error>
                <mat-error *ngIf="form.hasError('whitespace')">
                    No whitespace allowed is <strong>required</strong>
                </mat-error>
                <mat-error *ngIf="form.get('password')?.errors?.minlength">
                    Password should contain atleast <strong>6 characters</strong>
                </mat-error>
            </mat-form-field>


        </div>

        <!-- <div *ngIf="data?.id" fxLayout="row">
        <mat-form-field fxFlex>
            <mat-select placeholder="Status" formControlName="status">
                <mat-option [value]="1">active</mat-option>
                <mat-option [value]="0">inactive</mat-option>
            </mat-select>
        </mat-form-field>
    </div> -->

        <div fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="10px">

            <button type="button" (click)="form.reset()" mat-raised-button="" color="accent">
                Reset
            </button>
            <button [disabled]="form.invalid" mat-raised-button="" color="primary">
                Save
            </button>
        </div>
    </form>
</div>