<div fxLayout="column" fxLayoutGap="30px" class="dashboard-stat">
    <div fxFlex fxLayout>
        <mat-card fxFlex style="position: relative; 
            width: 100%;">
            <mat-card-header>
                <div fxFlex fxLayout="row" fxLayout.xs="column" class="chartHeader">
                    <div fxFlex>
                        <div class="card-category">Total Enrollments</div>
                        <div class="card-title"><span *ngIf="enrollment_user_type =='student'">Students</span>
                            <span *ngIf="enrollment_user_type =='teacher'">Teachers</span>
                            <span *ngIf="enrollment_user_type =='all'">Users</span>

                        </div>
                    </div>
                    <div fxFlex fxLayout fxLayout.xs="column" fxLayoutGap="20px" class="filter-container" fxLayoutAlign="flex-end">
                        <div>
                            <mat-form-field fxFlex>
                                <mat-select placeholder="User Type" [(value)]="enrollment_user_type" (selectionChange)="submitUserTypeEnrollment($event.value)">
                                    <mat-option value="student">Student</mat-option>
                                    <mat-option value="teacher">Teacher</mat-option>
                                    <mat-option value="all">All</mat-option>

                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field fxFlex>
                                <mat-select placeholder="Filter by" [(value)]="enrollment_criteria" (selectionChange)="submitCriteriaEnrollment($event.value)">
                                    <mat-option value="daily">Daily</mat-option>
                                    <mat-option value="monthly">Monthly</mat-option>
                                    <mat-option value="yearly">Yearly</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div *ngIf="enrollment_criteria=='daily'">
                            <mat-form-field fxFlex>
                                <mat-select placeholder="Month" [(value)]="selectedDailyMonth" (selectionChange)="submitDailyMonthYear($event.value)">
                                    <mat-option [value]="1">January</mat-option>
                                    <mat-option [value]="2">February</mat-option>
                                    <mat-option [value]="3">March</mat-option>
                                    <mat-option [value]="4">April</mat-option>
                                    <mat-option [value]="5">May</mat-option>
                                    <mat-option [value]="6">June</mat-option>
                                    <mat-option [value]="7">July</mat-option>
                                    <mat-option [value]="8">August</mat-option>
                                    <mat-option [value]="9">September</mat-option>
                                    <mat-option [value]="10">October</mat-option>
                                    <mat-option [value]="11">November</mat-option>
                                    <mat-option [value]="12">December</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div *ngIf="enrollment_criteria=='daily' && selectedDailyMonth">
                            <form [formGroup]="dailyYearForm" (submit)="submitDailyMonthYear($event.value)" fxLayout="row" style="align-items: flex-end;" fxLayoutGap="20px">
                                <mat-form-field fxFlex>
                                    <input matInput placeholder="Year" type="number" formControlName="selectedDailyYear" (change)="submitDailyMonthYear($event.value)">
                                </mat-form-field>
                            </form>
                        </div>
                        <div *ngIf="enrollment_criteria=='monthly'">
                            <form [formGroup]="monthlyYearForm" (submit)="submitMonthlyYear($event.value)" fxLayout="row" style="align-items: flex-end;" fxLayoutGap="20px">
                                <mat-form-field fxFlex>
                                    <input matInput placeholder="Year" type="number" formControlName="selectedMonthlyYear" (change)="submitMonthlyYear($event.value)">
                                </mat-form-field>
                            </form>
                        </div>
                        <div *ngIf="enrollment_criteria=='yearly'">
                            <form [formGroup]="yearlyForm" (submit)="submitYearly($event.value)" fxLayout="row" style="align-items: flex-end;" fxLayoutGap="20px">
                                <mat-form-field fxFlex="60px">
                                    <input matInput placeholder="From Year" type="number" formControlName="fromYear" (change)="submitYearly($event.value)">
                                </mat-form-field>
                                <mat-form-field fxFlex="60px">
                                    <input matInput placeholder="To Year" type="number" formControlName="toYear" (change)="submitYearly($event.value)">
                                </mat-form-field>
                            </form>
                        </div>
                    </div>
                </div>
            </mat-card-header>
            <div class="chart-container" style="position: relative; height:250px; width:100%">
                <canvas #enrollmentCanvas id="canvas">{{ enrollmentChart }}</canvas>
            </div>
        </mat-card>
    </div>
    <div fxFlex fxLayout fxLayoutGap="30px" class="row">
        <div fxFlex class="col-md-6 col-lg-3">
            <mat-card fxFlex>

                <div class="stats-card">
                    <div class="card-body">
                        <div fxLayout class="row">
                            <div fxFlex>
                                <div class="numbers">
                                    <div class="card-category">Total Schools</div>
                                    <div class="card-title">{{stats?.total_schools}}</div>
                                </div>
                            </div>
                            <div fxFlex="65px" fxLayoutAlign="flex-end" class="col-5">
                                <div class="stat-icon student">
                                    <i class="fal fa-user-graduate "></i>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </mat-card>
        </div>
        <div fxFlex class="col-md-6 col-lg-3">
            <mat-card fxFlex>

                <div class="stats-card">
                    <div class="card-body">
                        <div fxLayout class="row">
                            <div fxFlex>
                                <div class="numbers">
                                    <div class="card-category">Total Paid Users</div>
                                    <div class="card-title">{{stats?.total_paid_users}}</div>
                                </div>
                            </div>
                            <div fxFlex="65px" fxLayoutAlign="flex-end" class="col-5">
                                <div class="stat-icon teacher">
                                    <i class="fal fa-chalkboard-teacher "></i>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </mat-card>
        </div>
        <div fxFlex class="col-md-6 col-lg-3">
            <mat-card fxFlex>

                <div class="stats-card">
                    <div class="card-body">
                        <div fxLayout class="row">
                            <div fxFlex>
                                <div class="numbers">
                                    <div class="card-category">Total Users in Your Schools</div>
                                    <div class="card-title">{{stats?.total_school_user}}</div>
                                </div>
                            </div>
                            <div fxFlex="65px" fxLayoutAlign="flex-end" class="col-5">
                                <div class="stat-icon school">
                                    <i class="fal fa-school "></i>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </mat-card>
        </div>
        <div fxFlex class="col-md-6 col-lg-3">
            <mat-card fxFlex>

                <div class="stats-card">
                    <div class="card-body">
                        <div fxLayout class="row">
                            <div fxFlex>
                                <div class="numbers">
                                    <div class="card-category">Total Users in Your Region</div>
                                    <div class="card-title">{{stats?.total_country_users}}</div>
                                </div>
                            </div>
                            <div fxFlex="65px" fxLayoutAlign="flex-end" class="col-5">
                                <div class="stat-icon region">
                                    <i class="fal fa-users"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </mat-card>
        </div>

    </div>
    <!-- <div fxFlex fxLayout fxLayoutGap="30px" class="row">
        <mat-card fxFlex style="position: relative; 
            width: 100%;">
            <mat-card-header>
                <div fxFlex fxLayout="row" class="chartHeader">
                    <div fxFlex>
                        <div class="card-category">Total School</div>
                        <div class="card-title">Revenues</div>
                    </div>
                    <div fxFlex="20%" fxLayoutAlign="flex-end" fxLayoutGap="30px">
                        <mat-form-field fxFlex>
                            <mat-select placeholder="Filter by" [(value)]="school_revenue_criteria" (selectionChange)="submitSchoolCriteriaRevenue($event.value)">
                                <mat-option value="daily">Daily</mat-option>
                                <mat-option value="monthly">Monthly</mat-option>
                                <mat-option value="yearly">Yearly</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </mat-card-header>
            <div class=" chart-container" style="position: relative; height:250px; width:100%">
                <canvas #schoolRevCanvas id="canvas">{{ schoolRevChart }}</canvas>
            </div>
            <mat-card-content>

            </mat-card-content>
        </mat-card>
        <mat-card fxFlex style="position: relative; 
        width: 100%;">
            <mat-card-header>
                <div fxFlex fxLayout="row" class="chartHeader">
                    <div fxFlex>
                        <div class="card-category">Total User</div>
                        <div class="card-title">Revenues</div>
                    </div>
                    <div fxFlex="20%" fxLayoutAlign="flex-end" fxLayoutGap="30px">
                        <mat-form-field fxFlex>
                            <mat-select placeholder="Filter by" [(value)]="user_revenue_criteria" (selectionChange)="submitUserCriteriaRevenue($event.value)">
                                <mat-option value="daily">Daily</mat-option>
                                <mat-option value="monthly">Monthly</mat-option>
                                <mat-option value="yearly">Yearly</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </mat-card-header>
            <div class=" chart-container" style="position: relative; height:250px; width:100%">
                <canvas #userRevCanvas id="canvas">{{ userRevChart }}</canvas>
            </div>
            <mat-card-content>
            </mat-card-content>
        </mat-card>
    </div> -->
    <div fxFlex fxLayout fxLayoutGap="30px" class="row">
        <mat-card fxFlex>
            <mat-card-header>
                <div fxFlex fxLayout="row" class="chartHeader">
                    <div fxFlex>
                        <div class="card-category">Enrollments</div>
                        <div class="card-title">Schools</div>
                    </div>
                </div>
            </mat-card-header>
            <div class=" chart-container">
                <canvas #schoolPie id="canvas" class="pieChart">{{ schoolPieChart }}</canvas>
            </div>
        </mat-card>
        <mat-card fxFlex>
            <mat-card-header>
                <div fxFlex fxLayout="row" class="chartHeader">
                    <div fxFlex>
                        <div class="card-category">Enrollments</div>
                        <div class="card-title">Users</div>
                    </div>
                </div>
            </mat-card-header>
            <div class=" chart-container">
                <canvas #myUsersPie id="canvas" class="pieChart">{{ myUsersPieChart }}</canvas>
            </div>
        </mat-card>
        <mat-card fxFlex>
            <mat-card-header>
                <div fxFlex fxLayout="row" class="chartHeader">
                    <div fxFlex>
                        <div class="card-category">Total Region</div>
                        <div class="card-title">Users</div>
                    </div>
                </div>
            </mat-card-header>
            <div class=" chart-container">
                <canvas #usersPie id="canvas" class="pieChart">{{ usersPieChart }}</canvas>
            </div>
        </mat-card>
    </div>
</div>