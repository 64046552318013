import { Component, OnInit, HostListener, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MediaMatcher } from '@angular/cdk/layout';
import { UserService } from '../services/user.service';
import { ThemeService } from '../services/theme.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-main-nav',
    templateUrl: './main-nav.component.html',
    styleUrls: ['./main-nav.component.scss'],
    animations: [

        trigger('list1', [
            state('in', style({
                opacity: 1,
                transform: 'translateX(0)'
            })),
            transition('void => *', [
                style({
                    opacity: 0,
                    transform: 'translateX(-10px)'
                }),
                animate(200)
            ]),
            transition('* => void', [
                animate(300, style({
                    transform: 'translateX(-40px)',
                    opacity: 0
                }))
            ])
        ]),
        trigger('searchbtn', [
            state('in', style({
                opacity: 1,
                width: '100%'
            })),
            transition('void => *', [
                style({
                    opacity: 0,
                    width: 0
                }),
                animate(400)
            ]),
            transition('* => void', [
                animate(400, style({
                    width: '0%',
                    // transform: 'translateX(20px)',
                }))
            ])
        ]),
        trigger('test', [
            state('initial', style({
                opacity: 1,
                paddingLeft: '55px'
            })),
            state('final', style({
                paddingLeft: '280px',
            })),
            transition('initial=>final', animate('100ms')),
            transition('final=>initial', animate('400ms')),
            // transition('void => *', [
            //     style({
            //         opacity: 0,
            //         width: 0
            //     }),
            //     animate(400)
            // ]),
            // transition('* => void', [
            //     animate(400, style({
            //         width: '0%',
            //         // transform: 'translateX(20px)',
            //     }))
            // ])
        ]),

    ]
})
export class MainNavComponent implements OnInit {
    divWidth = 44;
    sidenavWidth = 70;
    navContentMargin = 0;
    toggle = true;
    mouseOver = true;
    searchbar = false;
    currentState = 'initial';
    opened = false;
    public innerWidth: any;
    mobileQuery: MediaQueryList;
    private _mobileQueryListener: () => void;
    menu = [];
    title;
    userName;
    isDarkTheme: Observable<boolean>;

    constructor(private router: Router,
        changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,
        public userService: UserService,
        private themeService: ThemeService
    ) {
        this.userService.getPermissions();
        this.mobileQuery = media.matchMedia('(max-width: 1200px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener
        )
    }

    ngOnInit() {
        this.isDarkTheme = this.themeService.isDarkTheme;

        this.getMenu();
        this.userService.title.subscribe(res => {
            this.title = res;
        });
        this.userName = this.userService.getUserDetails();
        // this.innerWidth = window.innerWidth;
    }
    toggleDarkTheme(checked: boolean) {
        this.themeService.setDarkTheme(checked);
    }
    // @HostListener('window:resize', ['$event'])
    // onResize(event) {
    //     this.innerWidth = window.innerWidth;
    //     if(this.innerWidth <= 1200) {
    //     }

    // }
    getMenu() {
        this.userService.menu.subscribe(res => {
            this.menu = [];
            res.map(menu => {
                this.menu.push(menu);
            })
        });
    }
    setTitle(title) {
        this.userService.setTitle(title);
    }

    changeState() {
        this.currentState = this.currentState === 'initial' ? 'final' : 'initial';
    }
    search() {
        this.searchbar = true;
    }
    toggleSideNav() {
        this.toggle = !this.toggle;
        if (this.sidenavWidth >= 80) {
            this.sidenavWidth = 70;
            this.divWidth = 44;
            this.navContentMargin = 0;
        } else {
            this.sidenavWidth = 260;
            this.divWidth = 232;
            this.navContentMargin = 250;
        }
        this.changeState();
    }
    increase() {
        if (this.toggle !== false && this.mouseOver) {
            this.sidenavWidth = 260;
            this.divWidth = 232;
        }
    }

    decrease() {
        if (this.toggle !== false && this.mouseOver) {
            this.divWidth = 44;
            this.sidenavWidth = 70;
        }
    }
    responsiveSideNav() {
        // this.toggle = !this.toggle;
        this.mouseOver = false;

        this.sidenavWidth = 260;
        this.divWidth = 232;
        this.navContentMargin = 250;
        // this.changeState();
    }
    closeNav() {
        this.mouseOver = false;

        if (this.sidenavWidth >= 250) {
            this.sidenavWidth = 0;
            this.divWidth = 0;
            this.navContentMargin = 0;
        }
    }
    logOut() {
        this.userService.logout();
    }
}


