import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

export interface NxDataService<T> {
  list(params?: object, url?: string): Observable<any>;

  get(id: number, params?, newUrl?: string): Observable<any>;

  add(T): Observable<any>;

  save(T): Observable<any>;

  update(T): Observable<any>;

  delete(number, url?): Observable<any>;

  query(T): Observable<any>;

  invoiceStatus(T): string;

  print(id, type): void;

  viewFile(id, newUrl?): void;
}

export function NxDataService({url}): any {
    return function(target, propertyKey: string, descriptor: PropertyDescriptor) {
        target.prototype.list = function(params?, newUrl?) {
            const uri = newUrl ? newUrl : url;
            const _params = new HttpParams({
                fromObject: {
                ...params
                }
            });
            return this.http.get(`${uri}?${_params.toString()}`);
        };

        target.prototype.get = function (id: number, params?, uri?) {
            const _params = new HttpParams({
                fromObject: {
                ...params
                }
            });
            const newUrl = uri ? uri: url;
            return this.http.get(`${newUrl}/${id}?${_params.toString()}`);
        };

        target.prototype.add = function (data: any) {
            const uri = data.newUrl ? data.newUrl : url;
            return this.http.post(`${uri}`, data);
        };

        target.prototype.save = function(data: any)  {
            if(data.id) {
                return this.http.put(`${url}/${data.id}`, data);
            }
            return this.http.post(`${url}`, data);
        };

        target.prototype.update = function (data: any) {
            const uri = data.newUrl ? data.newUrl : url;
            return this.http.put(`${uri}/${data.id}`, data);
        };

        target.prototype.delete = function (id: number, newUrl?) {
            const uri = newUrl ? newUrl : url;
            return this.http.delete(`${uri}/${id}`);
        };
        return target;
    };
}

export interface NxResponse<T> {
  data: any | T[];
  message: string;
  success: boolean;
}


