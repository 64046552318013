import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Chart } from 'chart.js';
import * as moment from 'moment';
import { StatsService } from '../../services/stats.service';

@Component({
    selector: 'app-school',
    templateUrl: './school.component.html',
    styleUrls: ['./school.component.scss']
})
export class SchoolComponent implements OnInit {
    enrollchart;
    teacherPiechart;
    studentPiechart;
    userPiechart;
    enrollmentChart;
    studentPieChart;
    teacherPieChart;
    userPieChart;
    enrollments = [];
    data;
    stats;
    update = false;
    // revenue_user_type = 'student';
    // revenue_criteria = 'monthly';
    enrollment_user_type = 'student';
    enrollment_criteria = 'monthly';
    enrollments_labels;
    enrollments_values;
    selectedDailyMonth  = moment().month() + 1;;
    selectedDailyYear;
    dailyYearForm: FormGroup;
    monthlyYearForm: FormGroup;
    yearlyForm: FormGroup;
    // userRevenueForm: FormGroup
    // @ViewChild('canvas', { static: false }) canvasRef: ElementRef;
    @ViewChild('enrollmentCanvas') enrollmentCanvasRef: ElementRef;
    @ViewChild('teachersPie') teachersPieRef: ElementRef;
    @ViewChild('studentsPie') studentsPieRef: ElementRef;
    @ViewChild('usersPie') usersPieRef: ElementRef;

    constructor(private service: StatsService,
        private fb: FormBuilder,
        private sb: MatSnackBar
    ) { }

    ngOnInit() {
        // this.getEnrollments();
        this.getStats();
        this.dailyYearForm = this.fb.group({
            selectedDailyYear: [moment().year()],
        });
        this.monthlyYearForm = this.fb.group({
            selectedMonthlyYear: [moment().year()],
        });
        this.yearlyForm = this.fb.group({
            fromYear: [moment().subtract(5, 'years').year()],
            toYear: [moment().year()],
        });
        this.getMonthlyEnrollments();
        // this.displayTeachersPie();
        // this.get();
        // this.revenue_user_type = 'student';
        // this.revenue_criteria = 'monthly';
        // this.userRevenueForm = this.fb.group({
        //     user_type: ['student'],
        //     criteria: ['monthly'],
        // });

    }
    getDailyEnrollments(month?, year?) {
        this.service.getSchoolEnrollmentsDaily(this.enrollment_user_type, this.enrollment_criteria, month, year).subscribe(res => {
            this.enrollments_labels = res.data.lables;
            this.enrollments_values = res.data.values;
            this.callEnrollmentsChart();
        });
    }
    getMonthlyEnrollments(year?) {
        this.service.getSchoolEnrollmentsMonthly(this.enrollment_user_type, this.enrollment_criteria, year).subscribe(res => {
            this.enrollments_labels = res.data.lables;
            this.enrollments_values = res.data.values;
            this.callEnrollmentsChart();
        });
    }
    getYearlyEnrollments(from?, to?) {
        this.service.getSchoolEnrollmentsYearly(this.enrollment_user_type, this.enrollment_criteria, from, to).subscribe(res => {
            this.enrollments_labels = res.data.lables;
            this.enrollments_values = res.data.values;
            this.callEnrollmentsChart();
        });
    }

    submitDailyMonthYear(value) {
        console.log(this.dailyYearForm.value.selectedDailyYear);

        this.getDailyEnrollments(this.selectedDailyMonth, this.dailyYearForm.value.selectedDailyYear);
    }
    submitMonthlyYear(value) {
        this.getMonthlyEnrollments(this.monthlyYearForm.value.selectedMonthlyYear);
    }
    submitYearly() {
        this.getYearlyEnrollments(this.yearlyForm.value.fromYear, this.yearlyForm.value.toYear)
    }

    // getEnrollments() {
    //     this.service.getSchoolEnrollments(this.enrollment_user_type, this.enrollment_criteria).subscribe(res => {
    //         this.enrollments_labels = res.data.lables;
    //         this.enrollments_values = res.data.values;
    //         this.callEnrollmentsChart();
    //         // if (this.update) {
    //         //     this.enrollchart.data.datasets[0].data = this.enrollments_values;
    //         //     this.enrollchart.data.labels = this.enrollments_labels;

    //         //     this.enrollchart.update();
    //         // } else {
    //         //     this.callEnrollChart();

    //         // }

    //     });
    //     // this.service.getLocalizedExperiences().subscribe(res => {
    //     //     this.data = res.data.data;
    //     // });
    //     // this.service.getRevenues(this.revenue_user_type, this.revenue_criteria).subscribe(res => {
    //     //     // this.sb.open("Stats updated", 'Ok', {
    //     //     //     duration: 2000,
    //     //     // });
    //     // });
    // }
    getStats() {
        this.service.getSchoolStats().subscribe(res => {
            this.stats = res.data;
            this.callPieChart();
        });

    }

    // submitUserTypeRevenue(value) {
    //     this.revenue_user_type = value;
    //     this.service.getRevenues(this.revenue_user_type, this.revenue_criteria).subscribe(res => {
    //         // this.sb.open("Stats updated", 'Ok', {
    //         //     duration: 2000,
    //         // });
    //     });
    // }
    // submitCriteriaRevenue(value) {
    //     // this.revenue_criteria = value;
    //     this.service.getRevenues(this.revenue_user_type, this.revenue_criteria).subscribe(res => {
    //         // this.sb.open("Stats updated", 'Ok', {
    //         //     duration: 2000,
    //         // });
    //     });

    // }
    submitUserTypeEnrollment(value) {
        this.enrollment_user_type = value;
        this.update = true;
        if (this.enrollment_criteria == 'daily') {
            this.getDailyEnrollments(this.selectedDailyMonth, this.dailyYearForm.value.selectedDailyYear);

        } else if (this.enrollment_criteria == 'monthly') {
            this.getMonthlyEnrollments(this.monthlyYearForm.value.selectedMonthlyYear);

        } else {
            this.getYearlyEnrollments(this.yearlyForm.value.fromYear, this.yearlyForm.value.toYear)
        }

    }
    submitCriteriaEnrollment(value) {
        this.enrollment_criteria = value;
        this.update = true;

        if (this.enrollment_criteria == 'daily') {
            this.getDailyEnrollments(this.selectedDailyMonth, this.dailyYearForm.value.selectedDailyYear);

        } else if (this.enrollment_criteria == 'monthly') {
            this.getMonthlyEnrollments(this.monthlyYearForm.value.selectedMonthlyYear);

        } else {
            this.getYearlyEnrollments(this.yearlyForm.value.fromYear, this.yearlyForm.value.toYear)
        }
        // this.service.getRevenues(this.enrollment_user_type, this.enrollment_criteria).subscribe(res => {
        //     this.sb.open("Stats updated", 'Ok', {
        //         duration: 2000,
        //     });
        // });

    }



    // const ctxCanvas = this.canvasRef.nativeElement.getContext('2d');
    // this.chart = new Chart(ctxCanvas, {
    //     type: 'line',
    //     data: {
    //         labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    //         datasets: [
    //             {
    //                 data: [0, 0, 0, 0, 0, 0, 0, 23, 9, 7, 0, 0],
    //                 borderColor: "#3cba9f",
    //                 fill: false
    //             },
    //         ]
    //     },
    //     options: {
    //         responsive: true,
    //         maintainAspectRatio: false,

    //         title: {
    //             display: true,
    //             text: 'Total Revenues',
    //             position: 'top'
    //         },
    //         legend: {
    //             display: false,
    //             position: 'bottom'
    //         },
    //         scales: {
    //             xAxes: [{
    //                 display: true,

    //             }],
    //             yAxes: [{
    //                 display: true,
    //                 gridLines: {
    //                     drawTicks: false,
    //                     display: false
    //                 }

    //             }],
    //         }
    //     }
    // });
    callEnrollmentsChart() {

        if (this.enrollmentChart) {
            this.enrollmentChart.clear();
            this.enrollmentChart.destroy();
        }
        const ctxEnrollmentCanvas = this.enrollmentCanvasRef.nativeElement.getContext('2d');
        var gradientFill = ctxEnrollmentCanvas.createLinearGradient(0, 0, 0, 140);
        gradientFill.addColorStop(0, "rgba(255, 40, 21, 0.5)");
        gradientFill.addColorStop(1, "rgba(255, 40, 21, 0)");
        this.enrollmentChart = new Chart(ctxEnrollmentCanvas, {
            type: 'line',
            data: {
                labels: this.enrollments_labels,
                datasets: [
                    {
                        data: this.enrollments_values,
                        borderColor: "#ff2815",
                        backgroundColor: gradientFill,
                        borderWidth: 3,
                    }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        left: 10,
                        right: 10,
                        top: 0,
                        bottom: 0
                    }
                },
                animation: {
                    easing: "easeInOutQuart"
                },
                title: {
                    display: false,
                    text: 'Total Enrollments',
                    position: 'top'
                },
                legend: {
                    display: false,
                    position: 'bottom'
                },
                scales: {
                    xAxes: [{
                        display: true,
                        gridLines: {
                            zeroLineColor: "transparent",
                            color: 'rgba(0, 0, 0, 0.05)',
                        },
                        ticks: {
                            padding: 20,
                            fontColor: "rgba(0,0,0,0.5)",
                            fontStyle: "regular"
                        }
                    }],
                    yAxes: [{
                        display: true,
                        ticks: {
                            fontColor: "rgba(0,0,0,0.5)",
                            fontStyle: "regular",
                            maxTicksLimit: 5,
                            padding: 20
                        },
                        gridLines: {
                            drawTicks: false,
                            display: false
                        }


                    }],
                }
            }
        });
    }
    callPieChart() {

        //     const ctxteachersPie = this.teachersPieRef.nativeElement.getContext('2d');
        //     this.chart = new Chart(ctxteachersPie, {

        //     // new Chart(document.getElementById("pie-chart"), {
        //         type: 'pie',
        //         data: {
        //             labels: ["Africa", "Asia", "Europe", "Latin America", "North America"],
        //             datasets: [{
        //                 label: "Population (millions)",
        //                 backgroundColor: ["#3e95cd", "#8e5ea2", "#3cba9f", "#e8c3b9", "#c45850"],
        //                 data: [2478, 5267, 734, 784, 433]
        //             }]
        //         },
        //         options: {
        //             title: {
        //                 display: true,
        //                 text: 'Predicted world population (millions) in 2050'
        //             }
        //         }
        //     });
        // }
        if (this.teacherPieChart) {
            this.teacherPieChart.clear();
            this.teacherPieChart.destroy();
        }
        const ctxteachersPie = this.teachersPieRef.nativeElement.getContext('2d');
        var gradientFill = ctxteachersPie.createLinearGradient(0, 0, 0, 140);
        gradientFill.addColorStop(0, "rgba(255, 40, 21, 0.5)");
        gradientFill.addColorStop(1, "rgba(255, 40, 21, 0)");
        this.teacherPieChart = new Chart(ctxteachersPie, {
            type: 'pie',
            data: {
                labels: ["Enrolled", "Unused Enrollments",],
                datasets: [{
                    label: "Enrollments (millions)",
                    backgroundColor: ["#38003c", "#ff2815",],
                    data: [this.stats.total_teacher, this.stats.balance_teacher]
                }]
            },
            options: {
            }
        });

        if (this.studentPieChart) {
            this.studentPieChart.clear();
            this.studentPieChart.destroy();
        }
        const ctxstudentsPie = this.studentsPieRef.nativeElement.getContext('2d');
        var gradientFill = ctxstudentsPie.createLinearGradient(0, 0, 0, 140);
        gradientFill.addColorStop(0, "rgba(255, 40, 21, 0.5)");
        gradientFill.addColorStop(1, "rgba(255, 40, 21, 0)");
        this.studentPieChart = new Chart(ctxstudentsPie, {
            type: 'pie',
            data: {
                labels: ["Enrolled", "Unused Enrollments",],
                datasets: [{
                    label: "Enrollments (millions)",
                    backgroundColor: ["#38003c", "#ff2815",],
                    data: [this.stats.total_student, this.stats.balance_student]
                }]
            },
            options: {
            }
        });
        if (this.userPieChart) {
            this.userPieChart.clear();
            this.userPieChart.destroy();
        }
        const ctxusersPie = this.usersPieRef.nativeElement.getContext('2d');
        var gradientFill = ctxusersPie.createLinearGradient(0, 0, 0, 140);
        gradientFill.addColorStop(0, "rgba(255, 40, 21, 0.5)");
        gradientFill.addColorStop(1, "rgba(255, 40, 21, 0)");

        this.userPieChart = new Chart(ctxusersPie, {
            type: 'pie',
            data: {
                labels: ["Students", "Teachers",],
                datasets: [{
                    label: "Enrollments (millions)",
                    backgroundColor: ["#38003c", "#ff2815",],
                    data: [this.stats.total_student, this.stats.total_teacher]
                }]
            },
            options: {
            }
        });
    }


    callEnrollChart() {
        if (this.enrollchart) {
            this.enrollchart.clear();
            this.enrollchart.destroy();
        }
        const ctxEnrollmentCanvas = this.enrollmentCanvasRef.nativeElement.getContext('2d');
        var gradientFill = ctxEnrollmentCanvas.createLinearGradient(0, 0, 0, 140);
        gradientFill.addColorStop(0, "rgba(255, 40, 21, 0.5)");
        gradientFill.addColorStop(1, "rgba(255, 40, 21, 0)");
        var enrollChartData = {
            labels: this.enrollments_labels,
            datasets: [
                {
                    data: this.enrollments_values,
                    borderColor: "#ff2815",
                    backgroundColor: gradientFill,
                    borderWidth: 3,
                }]
        };
        this.enrollchart = new Chart(ctxEnrollmentCanvas, {
            type: 'line',
            data: enrollChartData,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        left: 10,
                        right: 10,
                        top: 0,
                        bottom: 0
                    }
                },
                animation: {
                    easing: "easeOutCirc"
                },
                title: {
                    display: false,
                    text: 'Total Enrollments',
                    position: 'top'
                },
                legend: {
                    display: false,
                    position: 'bottom'
                },
                scales: {
                    xAxes: [{
                        display: true,
                        gridLines: {
                            zeroLineColor: "transparent",
                            color: 'rgba(0, 0, 0, 0.05)',
                        },
                        ticks: {
                            padding: 20,
                            fontColor: "rgba(0,0,0,0.5)",
                            fontStyle: "regular"
                        }
                    }],
                    yAxes: [{
                        display: true,
                        ticks: {
                            fontColor: "rgba(0,0,0,0.5)",
                            fontStyle: "regular",
                            maxTicksLimit: 5,
                            padding: 20
                        },
                        gridLines: {
                            drawTicks: false,
                            display: false
                        }


                    }],
                }
            }
        });
        this.update = true;
    }


}
