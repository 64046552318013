import { Injectable } from '@angular/core';
import { UserService } from '../services/user.service';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RouteResolver implements Resolve<any>{

    constructor(private service: UserService) {
    }

    resolve(): Observable<any>|Promise<any>|any {
        return this.service.loadPermissions();
    }
}
