<div class="mainPage" (click)="sidenav.close()" *ngIf=" mobileQuery.matches && sidenav.opened">
</div>
<div [ngClass]="{'dark-theme': isDarkTheme | async}">
    <mat-toolbar fxLayout class="topBar">
        <div fxFlex fxLayout fxLayoutAlign="start">
            <div fxflex fxLayout class="menu-title" style="float:right;">
                <button (click)="toggleSideNav()" class="menu-button notResponsive" [disableRipple]="true"
                    mat-icon-button>
                    <mat-icon color="accent" mat-font-icon="fa-" class="fa">
                        <i class="fas fa-ellipsis-h-alt"></i>
                    </mat-icon>
                </button>
                <button (click)="sidenav.toggle();responsiveSideNav() " class="menu-button responsive"
                    [disableRipple]="true" mat-icon-button>
                    <mat-icon color="accent" mat-font-icon="fa-" class="fa">
                        <i class="fas fa-ellipsis-h-alt"></i>
                    </mat-icon>
                </button>
                <div fxFlex class="title">
                    <a>{{title}}</a>

                </div>
                <!-- <mat-slide-toggle>Dark theme</mat-slide-toggle> -->
            </div>

            <div fxFlex fxLayout fxLayoutAlign="end" style="float:right;">
                <!-- <app-search [@searchbtn] *ngIf="searchbar" style="width:100%;" [searchbar]="searchbar"
                    (focusout)="searchbar = false"></app-search> -->
                <!-- <div class="search" fxflex fxLayoutAlign="center" fxLayout style="float:right;">
                    <mat-form-field class="w-100" appearance="outline">
                        <input matInput placeholder="Search" formControlName="masterSearch" #sInput autocomplete="off" autofill="off">
                        <mat-icon *ngIf="sInput.value.length > 0" matSuffix class="hover" (click)="clear()">clear</mat-icon>
                        <mat-icon *ngIf="sInput.value.length == 0" class="hover" matSuffix (click)="submit()">search</mat-icon>
                    </mat-form-field>
                </div> -->
                <ul fxLayout fxLayoutGap="5px" class="navigation-items">
                    <li>
                        <!-- <button *ngIf="!searchbar" mat-icon-button (click)="search()">
                            <mat-icon>search</mat-icon>
                        </button> -->
                    </li>

                    <li>
                        <!-- <button mat-icon-button>
                            <mat-icon>notifications_none</mat-icon>
                        </button> -->
                    </li>

                    <li class="avator">
                        <div matRipple [matMenuTriggerFor]="profile" matTooltip="Profile">
                            <img src="/assets/images/logo.png" alt="avator">
                            <i fxFlex class="fal fa-chevron-down"></i>
                            <div class="profile-menu">
                                <mat-menu #profile="matMenu" xPosition="after">
                                    <div class="profile-name">
                                        <button mat-menu-item>{{userName? userName : 'User'}}</button>
                                    </div>
                                    <mat-divider class="w-100"></mat-divider>
                                    <button mat-menu-item>My profile</button>
                                    <!-- <button mat-menu-item>
                                        <mat-slide-toggle [checked]="isDarkTheme | async"
                                            (change)="toggleDarkTheme($event.checked)">Dark theme</mat-slide-toggle>
                                    </button> -->

                                    <mat-divider class="w-12"></mat-divider>
                                    <button mat-menu-item class="logged-out" (click)="logOut()">Log out</button>
                                </mat-menu>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </mat-toolbar>

    <mat-sidenav-container class="sideContent">
        <mat-sidenav #sidenav [mode]="mobileQuery.matches ? 'over' : 'side'"
            [class.example-is-mobile]="mobileQuery.matches" class="example-sidenav"
            [ngStyle]="{ 'width.px': sidenavWidth }" [opened]="mobileQuery.matches ? false : true"
            (mouseenter)="increase()" (mouseleave)="decrease()">
            <div fxLayout class="sidenav-header">
                <div fxflex class="logodiv">
                    <img src="/assets/images/logo.png" alt="">
                    <div [@list1] fxflex class="sidenav-title" *ngIf="sidenavWidth> 250">
                        <h3>Crafter</h3>
                    </div>
                </div>

            </div>
            <mat-nav-list class="nav-list">


                <mat-divider [ngStyle]="{ 'width.px': divWidth }" class="mat-small-div"></mat-divider>
                <mat-list-item *ngFor="let menu of menu" routerLink="{{menu.link}}" routerLinkActive="active"
                    [routerLinkActiveOptions]="{exact: true}"
                    (click)="mobileQuery.matches ? sidenav.toggle() : '' ; setTitle(menu.name);">
                    <i class="fal {{menu.icon}} sideNavIcon"></i>
                    <div fxFlex="10"></div>
                    <div [@list1] *ngIf="sidenavWidth> 80" class="sidenav-item">
                        <h5>{{menu.name}}</h5>
                    </div>
                </mat-list-item>

                <!-- <mat-list-item [routerLinkActiveOptions]="{exact: true}" routerLink="" routerLinkActive="active"
                (click)="mobileQuery.matches ? sidenav.toggle() : '' ">
                <i class="fal fa-compass sideNavIcon"></i>
                <div fxFlex="10"></div>
                <div [@list1] *ngIf="sidenavWidth> 80" class="sidenav-item">
                    <h5>Dashboard</h5>
                </div>
            </mat-list-item>


            <mat-list-item routerLink="/country" routerLinkActive="active"
                (click)="mobileQuery.matches ? sidenav.toggle() : '' ">
                <i class="fal fa-globe-asia sideNavIcon"></i>
                <div fxFlex="10"></div>
                <div [@list1] *ngIf="sidenavWidth> 80" class="sidenav-item">
                    <h5>Country</h5>
                </div>
            </mat-list-item>
            <mat-list-item routerLink="/master/language" routerLinkActive="active"
                (click)="mobileQuery.matches ? sidenav.toggle() : '' ">
                <i class="fal fa-language sideNavIcon"></i>
                <div fxFlex="10"></div>
                <div [@list1] *ngIf="sidenavWidth> 80" class="sidenav-item">
                    <h5>Languages</h5>
                </div>
            </mat-list-item>

            <mat-list-item routerLink="/experience" routerLinkActive="active"
                (click)="mobileQuery.matches ? sidenav.toggle() : '' ">
                <i class="fal fa-atom-alt sideNavIcon"></i>
                <div fxFlex="10"></div>
                <div [@list1] *ngIf="sidenavWidth> 80" class="sidenav-item">
                    <h5>Experiences</h5>
                </div>
            </mat-list-item>

            <mat-list-item routerLink="/subject" routerLinkActive="active"
                (click)="mobileQuery.matches ? sidenav.toggle() : '' ">
                <i class="fal fa-book-alt sideNavIcon"></i>
                <div fxFlex="10"></div>
                <div [@list1] *ngIf="sidenavWidth> 80" class="sidenav-item">
                    <h5>Subjects</h5>
                </div>
            </mat-list-item>

            <mat-list-item routerLink="/master/lesson" routerLinkActive="active"
                (click)="mobileQuery.matches ? sidenav.toggle() : '' ">
                <i class="fal fa-chalkboard-teacher sideNavIcon"></i>
                <div fxFlex="10"></div>
                <div [@list1] *ngIf="sidenavWidth> 80" class="sidenav-item">
                    <h5>Lessons</h5>
                </div>
            </mat-list-item>

            <mat-list-item routerLink="/master/school" routerLinkActive="active"
                (click)="mobileQuery.matches ? sidenav.toggle() : '' ">
                <i class="fal fa-school sideNavIcon"></i>
                <div fxFlex="10"></div>
                <div [@list1] *ngIf="sidenavWidth> 80" class="sidenav-item">
                    <h5>School</h5>
                </div>
            </mat-list-item>
            <mat-list-item routerLink="/subscription-package" routerLinkActive="active"
                (click)="mobileQuery.matches ? sidenav.toggle() : '' ">
                <i class="material-icons sideNavIcon">subscriptions</i>
                <div fxFlex="10"></div>
                <div [@list1] *ngIf="sidenavWidth> 80" class="sidenav-item">
                    <h5>Subscriptions</h5>
                </div>
            </mat-list-item>
            <mat-list-item routerLink="/master/users" routerLinkActive="active"
                (click)="mobileQuery.matches ? sidenav.toggle() : '' ">
                <i class="fal fa-users sideNavIcon"></i>
                <div fxFlex="10"></div>
                <div [@list1] *ngIf="sidenavWidth> 80" class="sidenav-item">
                    <h5>Users</h5>
                </div>
            </mat-list-item>
            <mat-list-item routerLink="/master/setting" [routerLinkActiveOptions]="{exact: true} "
                routerLinkActive="active" (click)="mobileQuery.matches ? sidenav.toggle() : '' ">
                <i class="fal fa-cog sideNavIcon"></i>
                <div fxFlex="10"></div>
                <div [@list1] *ngIf="sidenavWidth> 80" class="sidenav-item">
                    <h5>Settings</h5>
                </div>
            </mat-list-item>
            <mat-list-item routerLink="/delivery/delivery-status" routerLinkActive="active"
                (click)="mobileQuery.matches ? sidenav.toggle() : '' ">
                <i class="fal fa-envelope-square sideNavIcon"></i>
                <div fxFlex="10"></div>
                <div [@list1] *ngIf="sidenavWidth> 80" class="sidenav-item">
                    <h5>Post CMS</h5>
                </div>
            </mat-list-item>
            <mat-list-item routerLink="/delivery" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active"
                (click)="mobileQuery.matches ? sidenav.toggle() : '' ">
                <i class="fal fa-folder-open sideNavIcon"></i>
                <div fxFlex="10"></div>
                <div [@list1] *ngIf="sidenavWidth> 80" class="sidenav-item">
                    <h5>Categories CMS</h5>
                </div>
            </mat-list-item>
            <mat-list-item routerLink="/role-management" routerLinkActive="active"
                (click)="mobileQuery.matches ? sidenav.toggle() : '' ">
                <i class="fal fa-user-cog sideNavIcon"></i>
                <div fxFlex="10"></div>
                <div [@list1] *ngIf="sidenavWidth> 80" class="sidenav-item">
                    <h5>Role Management</h5>
                </div>
            </mat-list-item> -->
            </mat-nav-list>
        </mat-sidenav>
        <!--  -->
        <mat-sidenav-content>
            <div class="example-sidenav-content">
                <div [@test]=currentState class="test">
                    <router-outlet></router-outlet>
                </div>
                <!-- <app-dashboard></app-dashboard> -->
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>