import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { StatsService } from '../../services/stats.service';
import { Chart } from 'chart.js';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';

@Component({
    selector: 'app-region-manager',
    templateUrl: './region-manager.component.html',
    styleUrls: ['./region-manager.component.scss']
})
export class RegionManagerComponent implements OnInit {
    chart;
    enrollmentChart;
    schoolRevChart;
    userRevChart;
    schoolPieChart;
    myUsersPieChart
    usersPieChart;
    enrollments_labels = [];
    enrollments_values = [];
    paid_labels = [];
    paid_values = [];
    revenue_user_labels = [];
    revenue_user_values = [];
    revenue_school_labels = [];
    revenue_school_values = [];
    data;
    stats;
    // revenue_user_type = 'student';
    school_revenue_criteria = 'monthly';
    user_revenue_criteria = 'monthly';
    enrollment_user_type = 'all';
    enrollment_criteria = 'monthly';
    // userRevenueForm: FormGroup
    selectedDailyMonth = moment().month() + 1;
    selectedDailyYear;
    dailyYearForm: FormGroup;
    monthlyYearForm: FormGroup;
    yearlyForm: FormGroup;

    @ViewChild('userRevCanvas') userRevCanvasRef: ElementRef;
    @ViewChild('schoolRevCanvas') schoolRevCanvasRef: ElementRef;
    @ViewChild('enrollmentCanvas') enrollmentCanvasRef: ElementRef;
    @ViewChild('schoolPie') schoolPieRef: ElementRef;
    @ViewChild('usersPie') usersPieRef: ElementRef;
    @ViewChild('myUsersPie') myUsersPieRef: ElementRef;

    constructor(private service: StatsService,
        private fb: FormBuilder,
        private sb: MatSnackBar
    ) { }

    ngOnInit() {
        // this.getEnrollments();
        this.getUserRevenues()
        this.getSchoolRevenues()
        this.getStats();
        this.dailyYearForm = this.fb.group({
            selectedDailyYear: [moment().year()],
        });
        this.monthlyYearForm = this.fb.group({
            selectedMonthlyYear: [moment().year()],
        });
        this.yearlyForm = this.fb.group({
            fromYear: [moment().subtract(5, 'years').year()],
            toYear: [moment().year()],
        });
        this.getMonthlyEnrollments();

        // this.revenue_user_type = 'student';
        // this.revenue_criteria = 'monthly';
        // this.userRevenueForm = this.fb.group({
        //     user_type: ['student'],
        //     criteria: ['monthly'],
        // });

    }
    getStats() {
        this.service.getRegionManagerStats().subscribe(res => {
            this.stats = res.data;
            this.callPieChart();

        });
    }
    getDailyEnrollments(month?, year?) {
        this.service.getRegionManagerPaidSubscriptionStatsDaily(this.enrollment_user_type, this.enrollment_criteria, month, year).subscribe(res => {
            this.paid_labels = res.data.lables;
            this.paid_values = res.data.values;
            this.service.getRegionManagerSubscriptionStatsDaily(this.enrollment_user_type, this.enrollment_criteria, month, year).subscribe(res => {
                this.enrollments_labels = res.data.lables;
                this.enrollments_values = res.data.values;
                this.callEnrollmentsChart();
            });
        });
    }
    getMonthlyEnrollments(year?) {
        this.service.getRegionManagerPaidSubscriptionStatsMonthly(this.enrollment_user_type, this.enrollment_criteria, year).subscribe(res => {
            this.paid_labels = res.data.lables;
            this.paid_values = res.data.values;
            this.service.getRegionManagerSubscriptionStatsMonthly(this.enrollment_user_type, this.enrollment_criteria, year).subscribe(res => {
                this.enrollments_labels = res.data.lables;
                this.enrollments_values = res.data.values;
                this.callEnrollmentsChart();
            });
        });
    }
    getYearlyEnrollments(from?, to?) {
        this.service.getRegionManagerPaidSubscriptionStatsYearly(this.enrollment_user_type, this.enrollment_criteria, from, to).subscribe(res => {
            this.paid_labels = res.data.lables;
            this.paid_values = res.data.values;
            this.service.getRegionManagerSubscriptionStatsYearly(this.enrollment_user_type, this.enrollment_criteria, from, to).subscribe(res => {
                this.enrollments_labels = res.data.lables;
                this.enrollments_values = res.data.values;
                this.callEnrollmentsChart();
            });
        });
    }

    submitDailyMonthYear(value) {
        console.log(this.dailyYearForm.value.selectedDailyYear);

        this.getDailyEnrollments(this.selectedDailyMonth, this.dailyYearForm.value.selectedDailyYear);
    }
    submitMonthlyYear(value) {
        this.getMonthlyEnrollments(this.monthlyYearForm.value.selectedMonthlyYear);
    }
    submitYearly() {
        this.getYearlyEnrollments(this.yearlyForm.value.fromYear, this.yearlyForm.value.toYear)
    }

    // getEnrollments() {
    //     this.service.getRegionManagerPaidSubscriptionStats(this.enrollment_user_type, this.enrollment_criteria).subscribe(res => {
    //         this.paid_labels = res.data.lables;
    //         this.paid_values = res.data.values;
    //         this.service.getRegionManagerSubscriptionStats(this.enrollment_user_type, this.enrollment_criteria).subscribe(res => {
    //             this.enrollments_labels = res.data.lables;
    //             this.enrollments_values = res.data.values;
    //             this.callEnrollmentsChart();
    //         });
    //     });
    // }
    // this.service.getLocalizedExperiences().subscribe(res => {
    //     this.data = res.data.data;
    // });
    getUserRevenues() {
        this.service.getRegionManagerUsersRevenues(this.user_revenue_criteria).subscribe(res => {
            this.revenue_user_labels = res.data.lables;
            this.revenue_user_values = res.data.values;
            this.callUserRevenueChart();

            // this.sb.open("Stats updated", 'Ok', {
            //     duration: 2000,
            // });
        });
    }
    getSchoolRevenues() {
        this.service.getRegionManagerSchoolRevenues(this.school_revenue_criteria).subscribe(res => {
            this.revenue_school_labels = res.data.lables;
            this.revenue_school_values = res.data.values;
            this.callSchoolRevenueChart();

            // this.sb.open("Stats updated", 'Ok', {
            //     duration: 2000,
            // });
        });
    }
    // submitUserTypeRevenue(value) {
    //     this.revenue_user_type = value;
    //     this.getRevenues()
    //     // this.service.getRevenues(this.revenue_user_type, this.revenue_criteria).subscribe(res => {
    //     //     this.sb.open("Stats updated", 'Ok', {
    //     //         duration: 2000,
    //     //     });
    //     // });
    //     // this.callChart();

    // }
    submitUserCriteriaRevenue(value) {
        this.user_revenue_criteria = value;
        this.getUserRevenues()
    }
    submitSchoolCriteriaRevenue(value) {
        this.school_revenue_criteria = value;
        this.getSchoolRevenues()
    }
    submitUserTypeEnrollment(value) {
        this.enrollment_user_type = value;
        // this.service.getRevenues(this.enrollment_user_type, this.enrollment_criteria).subscribe(res => {
        //     this.sb.open("Stats updated", 'Ok', {
        //         duration: 2000,
        //     });
        // });
        // this.callChart();
        if (this.enrollment_criteria == 'daily') {
            this.getDailyEnrollments(this.selectedDailyMonth, this.dailyYearForm.value.selectedDailyYear);

        } else if (this.enrollment_criteria == 'monthly') {
            this.getMonthlyEnrollments(this.monthlyYearForm.value.selectedMonthlyYear);

        } else {
            this.getYearlyEnrollments(this.yearlyForm.value.fromYear, this.yearlyForm.value.toYear)
        }
    }
    submitCriteriaEnrollment(value) {
        this.enrollment_criteria = value;
        // this.service.getRevenues(this.enrollment_user_type, this.enrollment_criteria).subscribe(res => {
        //     this.sb.open("Stats updated", 'Ok', {
        //         duration: 2000,
        //     });
        // });
        if (this.enrollment_criteria == 'daily') {
            this.getDailyEnrollments(this.selectedDailyMonth, this.dailyYearForm.value.selectedDailyYear);

        } else if (this.enrollment_criteria == 'monthly') {
            this.getMonthlyEnrollments(this.monthlyYearForm.value.selectedMonthlyYear);

        } else {
            this.getYearlyEnrollments(this.yearlyForm.value.fromYear, this.yearlyForm.value.toYear)
        }
    }
    callUserRevenueChart() {
        if (this.userRevChart) {
            this.userRevChart.clear();
            this.userRevChart.destroy();
        }
        const ctxUserRevCanvas = this.userRevCanvasRef.nativeElement.getContext('2d');
        var gradientFill = ctxUserRevCanvas.createLinearGradient(0, 0, 0, 140);
        gradientFill.addColorStop(0, "rgba(255, 40, 21, 0.5)");
        gradientFill.addColorStop(1, "rgba(255, 40, 21, 0)");

        this.userRevChart = new Chart(ctxUserRevCanvas, {
            type: 'line',
            data: {
                labels: this.revenue_user_labels,
                datasets: [
                    {
                        data: this.revenue_user_values,
                        borderColor: "#ff2815",
                        backgroundColor: gradientFill,
                        borderWidth: 3,

                    },
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        left: 10,
                        right: 10,
                        top: 0,
                        bottom: 0
                    }
                },
                animation: {
                    easing: "easeInOutQuart"
                },
                title: {
                    display: false,
                    text: 'Total User Revenues',
                    position: 'top'
                },
                legend: {
                    display: false,
                    position: 'bottom'
                },
                scales: {
                    xAxes: [{
                        display: true,
                        gridLines: {
                            zeroLineColor: "transparent",
                            color: 'rgba(0, 0, 0, 0.05)',
                        },
                        ticks: {
                            padding: 20,
                            fontColor: "rgba(0,0,0,0.5)",
                            fontStyle: "regular"
                        }
                    }],
                    yAxes: [{
                        display: true,
                        ticks: {
                            fontColor: "rgba(0,0,0,0.5)",
                            fontStyle: "regular",
                            maxTicksLimit: 5,
                            padding: 20
                        },
                        gridLines: {
                            drawTicks: false,
                            display: false
                        }
                    }],
                }
            }
        });
    }

    callSchoolRevenueChart() {
        if (this.schoolRevChart) {
            this.schoolRevChart.clear();
            this.schoolRevChart.destroy();
        }
        const ctxSchoolRevCanvas = this.schoolRevCanvasRef.nativeElement.getContext('2d');
        var gradientFill = ctxSchoolRevCanvas.createLinearGradient(0, 0, 0, 140);
        gradientFill.addColorStop(0, "rgba(255, 40, 21, 0.5)");
        gradientFill.addColorStop(1, "rgba(255, 40, 21, 0)");

        this.schoolRevChart = new Chart(ctxSchoolRevCanvas, {
            type: 'line',
            data: {
                labels: this.revenue_school_labels,
                datasets: [
                    {
                        data: this.revenue_school_values,
                        borderColor: "#ff2815",
                        backgroundColor: gradientFill,
                        borderWidth: 3,

                    },
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        left: 10,
                        right: 10,
                        top: 0,
                        bottom: 0
                    }
                },
                animation: {
                    easing: "easeInOutQuart"
                },
                title: {
                    display: false,
                    text: 'Total School Revenues',
                    position: 'top'
                },
                legend: {
                    display: false,
                    position: 'bottom'
                },
                scales: {
                    // xAxes: [{
                    //     display: true,

                    // }],
                    xAxes: [{
                        display: true,
                        gridLines: {
                            zeroLineColor: "transparent",
                            color: 'rgba(0, 0, 0, 0.05)',
                        },
                        ticks: {
                            padding: 20,
                            fontColor: "rgba(0,0,0,0.5)",
                            fontStyle: "regular"
                        }
                    }],
                    yAxes: [{
                        display: true,
                        ticks: {
                            fontColor: "rgba(0,0,0,0.5)",
                            fontStyle: "regular",
                            maxTicksLimit: 5,
                            padding: 20
                        },
                        gridLines: {
                            drawTicks: false,
                            display: false
                        }
                    }],
                }
            }
        });
    }

    callEnrollmentsChart() {
        if (this.enrollmentChart) {
            this.enrollmentChart.clear();
            this.enrollmentChart.destroy();
        }

        const ctxEnrollmentCanvas = this.enrollmentCanvasRef.nativeElement.getContext('2d');
        var gradientFill = ctxEnrollmentCanvas.createLinearGradient(0, 0, 0, 140);
        gradientFill.addColorStop(0, "rgba(255, 40, 21, 0.5)");
        gradientFill.addColorStop(1, "rgba(255, 40, 21, 0)");
        var gradientFill2 = ctxEnrollmentCanvas.createLinearGradient(0, 0, 0, 140);
        gradientFill2.addColorStop(0, "rgba(56, 0, 60, 0.5)");
        gradientFill2.addColorStop(1, "rgba(56, 0, 60, 0)");
        this.enrollmentChart = new Chart(ctxEnrollmentCanvas, {
            type: 'line',
            data: {
                labels: this.enrollments_labels,
                datasets: [
                    {
                        label: "Users in Region",
                        data: this.enrollments_values,
                        borderColor: "#ff2815",
                        backgroundColor: gradientFill,
                        borderWidth: 3,
                    },
                    {
                        label: "Paid Subscribed Users",
                        data: this.paid_values,
                        borderColor: "#38003c",
                        backgroundColor: gradientFill2,
                        borderWidth: 3,
                    }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        left: 10,
                        right: 10,
                        top: 0,
                        bottom: 0
                    }
                },
                animation: {
                    easing: "easeInOutQuart"
                },
                title: {
                    display: false,
                    text: 'Total Enrollments',
                    position: 'top'
                },
                legend: {
                    display: true,
                    position: 'top',
                    labels: {
                        boxWidth: 8,
                        usePointStyle: true
                    },

                },
                scales: {
                    xAxes: [{
                        display: true,
                        gridLines: {
                            zeroLineColor: "transparent",
                            color: 'rgba(0, 0, 0, 0.05)',
                        },
                        ticks: {
                            padding: 20,
                            fontColor: "rgba(0,0,0,0.5)",
                            fontStyle: "regular"
                        }
                    }],
                    yAxes: [{
                        display: true,
                        ticks: {
                            fontColor: "rgba(0,0,0,0.5)",
                            fontStyle: "regular",
                            maxTicksLimit: 5,
                            padding: 20
                        },
                        gridLines: {
                            drawTicks: false,
                            display: false
                        }
                    }],
                }
            }
        });
    }


    callPieChart() {


        if (this.schoolPieChart) {
            this.schoolPieChart.clear();
            this.schoolPieChart.destroy();
        }
        const ctxsSchoolPie = this.schoolPieRef.nativeElement.getContext('2d');
        var gradientFill = ctxsSchoolPie.createLinearGradient(0, 0, 0, 140);
        gradientFill.addColorStop(0, "rgba(255, 40, 21, 0.5)");
        gradientFill.addColorStop(1, "rgba(255, 40, 21, 0)");
        this.schoolPieChart = new Chart(ctxsSchoolPie, {
            type: 'pie',
            data: {
                labels: ["Enrolled", "Unused Enrollments",],
                datasets: [{
                    label: "Schools",
                    backgroundColor: ["#38003c", "#ff2815",],
                    data: [this.stats.total_schools, this.stats.balance_schools]
                }]
            },
            options: {
                legend: {
                    display: true,
                    position: 'top',
                    labels: {
                        boxWidth: 10,
                        usePointStyle: true
                    },

                },
            }
        });

        if (this.myUsersPieChart) {
            this.myUsersPieChart.clear();
            this.myUsersPieChart.destroy();
        }
        const ctxMyUsersPie = this.myUsersPieRef.nativeElement.getContext('2d');
        var gradientFill = ctxMyUsersPie.createLinearGradient(0, 0, 0, 140);
        gradientFill.addColorStop(0, "rgba(255, 40, 21, 0.5)");
        gradientFill.addColorStop(1, "rgba(255, 40, 21, 0)");
        this.myUsersPieChart = new Chart(ctxMyUsersPie, {
            type: 'pie',
            data: {
                labels: ["Your Users", "Your School Users", "Unused Enrollments",],
                datasets: [{
                    label: "Users",
                    backgroundColor: ["#38003c", "#ff2815", "#09c6f3"],
                    data: [this.stats.total_region_manager_users, this.stats.total_school_user, this.stats.balance_users]
                }]
            },
            options: {
                legend: {
                    display: true,
                    position: 'top',
                    labels: {
                        boxWidth: 10,
                        usePointStyle: true
                    },

                },
            }
        });
        if (this.usersPieChart) {
            this.usersPieChart.clear();
            this.usersPieChart.destroy();
        }
        const ctxusersPie = this.usersPieRef.nativeElement.getContext('2d');
        var gradientFill = ctxusersPie.createLinearGradient(0, 0, 0, 140);
        gradientFill.addColorStop(0, "rgba(255, 40, 21, 0.5)");
        gradientFill.addColorStop(1, "rgba(255, 40, 21, 0)");

        this.usersPieChart = new Chart(ctxusersPie, {
            type: 'pie',
            data: {
                labels: ["Your Users", "Other Users",],
                datasets: [{
                    label: "Your Users",
                    backgroundColor: ["#38003c", "#ff2815"],
                    data: [this.stats.total_region_manager_users + this.stats.total_school_user, this.stats.total_country_users - (this.stats.total_region_manager_users + this.stats.total_school_user)]
                }]
            },
            options: {
                legend: {
                    display: true,
                    position: 'top',
                    labels: {
                        boxWidth: 10,
                        usePointStyle: true
                    },

                },
            }
        });
    }
}
