import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NxDataService } from '../decorators/NxDataService';
export interface ExperienceService extends NxDataService<any> {
    test;
}
@Injectable({
  providedIn: 'root'
})
@NxDataService({
    url: '/api/experiences'
})
export class ExperienceService {

    constructor(public http: HttpClient) { }
    public getAttachables(id) {
        return this.http.get<any>(`/api/experiences/${id}/get_attachables`);
    }
    public getLocalizedExperiences(id) {
        return this.http.get<any>(`/api/experiences/experience_group/${id}`);
    }
    public addCurriculum(data) {
        return this.http.get<any>(`/api/experiences/add_curriculum/${data.group_code}/${data.curriculum_id}`);
    }
    public createLocalizedExperienceGroup(data) {
        return this.http.post<any>(`/api/experiences/create_localized_experience_group/`,data);
    }
    public updateGroup(data) {
        return this.http.post<any>(`/api/experiences/update_experience_group/`,data);
    }
}


