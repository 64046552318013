import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainNavComponent } from './main-nav/main-nav.component';
import { AuthGuard } from './guard/auth.guard';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RoleGuardGuard } from './guard/role-guard.guard';
import { RouteResolver } from './resolver/route.resolver';
import { StatsComponent } from './stats/stats.component';

const routes: Routes = [
    {
        path: '',
        component: MainNavComponent,
        data: {
            expectedRole: '*'
        },
        resolve: {
          permissions: RouteResolver,
        },
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: StatsComponent,
                data: {
                    expectedRole: 'admin'
                }
            },
            {
                path: 'experience',
                // canActivateChild: [RoleGuardGuard],
                // canActivate: [RoleGuardGuard],
                data: {
                    expectedRole: 'admin'
                },
                loadChildren: () => import('./experience/experience.module').then(m => m.ExperienceModule)

            },
            {
                path: 'master',
                // canActivateChild: [RoleGuardGuard],

                data: {
                    expectedRole: 'admin'
                },
                loadChildren: () => import('./master/master.module').then(m => m.MasterModule)
            },
            {
                path: 'country',
                // canActivateChild: [RoleGuardGuard],
                // canActivate: [RoleGuardGuard],
                data: {
                    expectedRole: 'admin'
                },

                loadChildren: () => import('./country/country.module').then(m => m.CountryModule)
            },
            {
                path: 'subject',
                // canActivateChild: [RoleGuardGuard],
                // canActivate: [RoleGuardGuard],
                data: {
                    expectedRole: 'admin'
                },

                loadChildren: () => import('./subject/subject.module').then(m => m.SubjectModule)
            },
            {
                path: 'subscription-package',
                // canActivateChild: [RoleGuardGuard],
                // canActivate: [RoleGuardGuard],
                data: {
                    expectedRole: 'admin'
                },

                loadChildren: () => import('./subscription-package/subscription-package.module').then(m => m.SubscriptionPackageModule)
            },
            {
                path: 'teacher',
                // canActivateChild: [RoleGuardGuard],

                data: {
                    expectedRole: 'admin'
                },

                loadChildren: () => import('./teacher/teacher.module').then(m => m.TeacherModule)
            },
            {
                path: 'student',
                // canActivateChild: [RoleGuardGuard],
                // canActivate: [RoleGuardGuard],
                data: {
                    expectedRole: 'admin'
                },

                loadChildren: () => import('./student/student.module').then(m => m.StudentModule)
            },
            {
                path: 'role-management',
                // canActivateChild: [RoleGuardGuard],
                canActivate: [RoleGuardGuard],
                data: {
                    expectedRole: 'admin'
                },

                loadChildren: () => import('./acl/acl.module').then(m => m.AclModule)
            },
            {
                path: 'region-manager',
                // canActivateChild: [RoleGuardGuard],
                // canActivate: [RoleGuardGuard],
                data: {
                    expectedRole: 'admin'
                },
                loadChildren: () => import('./region-manager/region-manager.module').then(m => m.RegionManagerModule)
            },
            {
                path: 'school',
                // canActivateChild: [RoleGuardGuard],
                // canActivate: [RoleGuardGuard],
                data: {
                    expectedRole: 'admin'
                },
                loadChildren: () => import('./school/school.module').then(m => m.SchoolModule)
            },
            {
                path: 'dashboard',
                // canActivateChild: [RoleGuardGuard],
                // canActivate: [RoleGuardGuard],
                data: {
                    expectedRole: 'Region Manager'
                },
                loadChildren: () => import('./region-manager-dashboard/region-manager-dashboard.module').then(m => m.RegionManagerDashboardModule)
            },
            {
                // canActivate: [RoleGuardGuard],
                path: 'school-dashboard',
                data: {
                    expectedRole: 'School'
                },
                loadChildren: () => import('./school-dashboard/school-dashboard.module').then(m => m.SchoolDashboardModule)
            },
        ]
    },
    {
        path: 'login',
        component: LoginComponent,
        pathMatch: 'full'
    },
    { path: '**', component: PageNotFoundComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
