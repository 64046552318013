import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserService } from '../services/user.service';

@Directive({
    selector: '[permissioned]'
})
export class PermissionDirective {
    private hasView = false;

    constructor(
        private templateRef: TemplateRef<any>,
        private userService: UserService,
        private viewContainer: ViewContainerRef) { }

    @Input() set permissioned(permission: any) {



        this.userService.permissions.subscribe(permissions => {
            const hasPermission = permissions.includes(permission);
            if (hasPermission) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
        })


    }
}
