import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { StatsService } from '../../services/stats.service';
import { Chart } from 'chart.js';
import { CountryService } from '../../services/country.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as moment from 'moment';

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
    expChart;
    enrollChart;
    userRevenueChart;
    countryRevChart;
    data;
    enrollment_user_type = 'student';
    enrollment_criteria = 'monthly';
    countryList;
    enrollments_labels = [];
    enrollments_values = [];
    revenue_user_labels = [];
    revenue_user_values = [];
    revenue_country_labels = [];
    revenue_country_values = [];
    localized_exp_labels = [];
    localized_exp_values = [];
    user_revenue_user_type = 'student';
    user_revenue_criteria = 'monthly';
    country_revenue_country = 1;
    country_revenue_criteria = 'monthly';
    localized_experiences_criteria = 'languages';
    selectedDailyMonthUserRevenues = moment().month() + 1;
    selectedDailyMonthCountryRevenues = moment().month() + 1;
    selectedDailyMonth = moment().month() + 1;
    selectedDailyYear;
    dailyYearForm: FormGroup;
    monthlyYearForm: FormGroup;
    yearlyForm: FormGroup;
    revenueDailyYearForm: FormGroup;
    revenueMonthlyYearForm: FormGroup;
    revenueYearlyForm: FormGroup;
    countryDailyYearForm: FormGroup;
    countryMonthlyYearForm: FormGroup;
    countryYearlyForm: FormGroup;

    @ViewChild('adminEnrollmentCanvas') adminEnrollmentCanvasRef: ElementRef;
    @ViewChild('adminUserRevenueCanvas') adminUserRevenueCanvasRef: ElementRef;
    @ViewChild('adminCountryRevenueCanvas') adminCountryRevenueCanvasRef: ElementRef;
    @ViewChild('adminLocalizedExperienceCanvas') adminLocalizedExperienceCanvasRef: ElementRef;

    constructor(private service: StatsService,
        private sb: MatSnackBar,
        private countryService: CountryService,
        private fb: FormBuilder

    ) { }

    ngOnInit() {
        // this.getEnrollments();
        // this.getUserRevenues();
        // this.getCountryRevenues();
        this.getCountries();
        this.getLocalizedExperiences();
        this.dailyYearForm = this.fb.group({
            selectedDailyYear: [moment().year()],
        });
        this.monthlyYearForm = this.fb.group({
            selectedMonthlyYear: [moment().year()],
        });
        this.yearlyForm = this.fb.group({
            fromYear: [moment().subtract(5, 'years').year()],
            toYear: [moment().year()],
        });
        this.revenueDailyYearForm = this.fb.group({
            selectedDailyYear: [moment().year()],
        });
        this.revenueMonthlyYearForm = this.fb.group({
            selectedMonthlyYear: [moment().year()],
        });
        this.revenueYearlyForm = this.fb.group({
            fromYear: [moment().subtract(5, 'years').year()],
            toYear: [moment().year()],
        });
        this.countryDailyYearForm = this.fb.group({
            selectedDailyYear: [moment().year()],
        });
        this.countryMonthlyYearForm = this.fb.group({
            selectedMonthlyYear: [moment().year()],
        });
        this.countryYearlyForm = this.fb.group({
            fromYear: [moment().subtract(5, 'years').year()],
            toYear: [moment().year()],
        });
        this.getMonthlyEnrollments();
        this.getMonthlyUserRevenues();
        this.getMonthlyCountryRevenues();

    }


    getCountries() {
        this.countryService.list({ all: true }).subscribe(res => {
            this.countryList = res.data;
        });
    }


    getDailyEnrollments(month?, year?) {
        this.service.getEnrollmentsDaily(this.enrollment_user_type, this.enrollment_criteria, month, year).subscribe(res => {
            this.enrollments_labels = res.data.lables;
            this.enrollments_values = res.data.values;
            this.callEnrollmentsChart();
        });
    }
    getMonthlyEnrollments(year?) {
        this.service.getEnrollmentsMonthly(this.enrollment_user_type, this.enrollment_criteria, year).subscribe(res => {
            this.enrollments_labels = res.data.lables;
            this.enrollments_values = res.data.values;
            this.callEnrollmentsChart();
        });
    }
    getYearlyEnrollments(from?, to?) {
        this.service.getEnrollmentsYearly(this.enrollment_user_type, this.enrollment_criteria, from, to).subscribe(res => {
            this.enrollments_labels = res.data.lables;
            this.enrollments_values = res.data.values;
            this.callEnrollmentsChart();
        });
    }

    getDailyUserRevenues(month?, year?) {
        this.service.getUserRevenuesDaily(this.user_revenue_user_type, this.user_revenue_criteria, month, year).subscribe(res => {
            this.revenue_user_labels = res.data.lables;
            this.revenue_user_values = res.data.values;
            this.callUserRevenuesChart();
        });
    }
    getMonthlyUserRevenues(year?) {
        this.service.getUserRevenuesMonthly(this.user_revenue_user_type, this.user_revenue_criteria, year).subscribe(res => {
            this.revenue_user_labels = res.data.lables;
            this.revenue_user_values = res.data.values;
            this.callUserRevenuesChart();
        });
    }
    getYearlyUserRevenues(from?, to?) {
        this.service.getUserRevenuesYearly(this.user_revenue_user_type, this.user_revenue_criteria, from, to).subscribe(res => {
            this.revenue_user_labels = res.data.lables;
            this.revenue_user_values = res.data.values;
            this.callUserRevenuesChart();
        });
    }
    getDailyCountryRevenues(month?, year?) {
        this.service.getCountryRevenuesDaily(this.country_revenue_country, this.country_revenue_criteria, month, year).subscribe(res => {
            this.revenue_country_labels = res.data.lables;
            this.revenue_country_values = res.data.values;
            this.callCountryRevenuesChart();
        });
    }
    getMonthlyCountryRevenues(year?) {
        this.service.getCountryRevenuesMonthly(this.country_revenue_country, this.country_revenue_criteria, year).subscribe(res => {
            this.revenue_country_labels = res.data.lables;
            this.revenue_country_values = res.data.values;
            this.callCountryRevenuesChart();
        });
    }
    getYearlyCountryRevenues(from?, to?) {
        this.service.getCountryRevenuesYearly(this.country_revenue_country, this.country_revenue_criteria, from, to).subscribe(res => {
            this.revenue_country_labels = res.data.lables;
            this.revenue_country_values = res.data.values;
            this.callCountryRevenuesChart();
        });
    }




    // getUserRevenues() {
    //     this.service.getRevenues(this.user_revenue_user_type, this.user_revenue_criteria).subscribe(res => {
    //         this.revenue_user_labels = res.data.lables;
    //         this.revenue_user_values = res.data.values;
    //         this.callUserRevenuesChart();
    //     });
    // }


    // getEnrollments() {
    //     this.service.getEnrollments(this.enrollment_user_type, this.enrollment_criteria).subscribe(res => {
    //         this.enrollments_labels = res.data.lables;
    //         this.enrollments_values = res.data.values;
    //         this.callEnrollmentsChart();
    //     });
    // }

    // getCountryRevenues() {
    //     this.service.getCountryRevenues(this.country_revenue_country, this.country_revenue_criteria).subscribe(res => {
    //         this.revenue_country_labels = res.data.lables;
    //         this.revenue_country_values = res.data.values;
    //         this.callCountryRevenuesChart();
    //     });
    // }
    getLocalizedExperiences() {
        this.service.getLocalizedExperiences(this.localized_experiences_criteria).subscribe(res => {
            this.localized_exp_labels = res.data.lables;
            this.localized_exp_values = res.data.values;
            this.callLocalizedExpChart();
        });
    }

    submitDailyMonth(value) {
        this.selectedDailyMonth = value;
        if (this.dailyYearForm.value.selectedDailyYear) {
            this.getDailyEnrollments(value, this.dailyYearForm.value.selectedDailyYear);
        } else {
            this.getDailyEnrollments(value);
        }
    }
    submitDailyMonthYear(value) {
        console.log(this.dailyYearForm.value.selectedDailyYear);

        this.getDailyEnrollments(this.selectedDailyMonth, this.dailyYearForm.value.selectedDailyYear);
    }
    submitMonthlyYear(value) {
        this.getMonthlyEnrollments(this.monthlyYearForm.value.selectedMonthlyYear);
    }
    submitYearly() {
        this.getYearlyEnrollments(this.yearlyForm.value.fromYear, this.yearlyForm.value.toYear)
    }



    submitDailyMonthUserRevenues(value) {
        this.selectedDailyMonthUserRevenues = value;
        if (this.revenueDailyYearForm.value.selectedDailyYear) {
            this.getDailyUserRevenues(value, this.revenueDailyYearForm.value.selectedDailyYear);
        } else {
            this.getDailyUserRevenues(value);
        }
    }
    submitDailyMonthYearUserRevenues(value) {

        this.getDailyUserRevenues(this.selectedDailyMonthUserRevenues, this.revenueDailyYearForm.value.selectedDailyYear);
    }
    submitMonthlyYearUserRevenues(value) {
        this.getMonthlyUserRevenues(this.revenueMonthlyYearForm.value.selectedMonthlyYear);
    }
    submitYearlyUserRevenues() {
        this.getYearlyUserRevenues(this.revenueYearlyForm.value.fromYear, this.revenueYearlyForm.value.toYear)
    }


    submitDailyMonthCountryRevenues(value) {
        this.selectedDailyMonthCountryRevenues = value;
        if (this.countryDailyYearForm.value.selectedDailyYear) {
            this.getDailyCountryRevenues(value, this.countryDailyYearForm.value.selectedDailyYear);
        } else {
            this.getDailyCountryRevenues(value);
        }
    }
    submitDailyMonthYearCountryRevenues(value) {

        this.getDailyCountryRevenues(this.selectedDailyMonthCountryRevenues, this.countryDailyYearForm.value.selectedDailyYear);
    }
    submitMonthlyYearCountryRevenues(value) {
        this.getMonthlyCountryRevenues(this.countryMonthlyYearForm.value.selectedMonthlyYear);
    }
    submitYearlyCountryRevenues() {
        this.getYearlyCountryRevenues(this.countryYearlyForm.value.fromYear, this.countryYearlyForm.value.toYear)
    }




    submitUserTypeEnrollment(value) {
        this.enrollment_user_type = value;
        if (this.enrollment_criteria == 'daily') {
            this.getDailyEnrollments(this.selectedDailyMonth, this.dailyYearForm.value.selectedDailyYear);

        } else if (this.enrollment_criteria == 'monthly') {
            this.getMonthlyEnrollments(this.monthlyYearForm.value.selectedMonthlyYear);

        } else {
            this.getYearlyEnrollments(this.yearlyForm.value.fromYear, this.yearlyForm.value.toYear)
        }
    }
    submitCriteriaEnrollment(value) {
        this.enrollment_criteria = value;
        if (this.enrollment_criteria == 'daily') {
            this.getDailyEnrollments(this.selectedDailyMonth, this.dailyYearForm.value.selectedDailyYear);

        } else if (this.enrollment_criteria == 'monthly') {
            this.getMonthlyEnrollments(this.monthlyYearForm.value.selectedMonthlyYear);

        } else {
            this.getYearlyEnrollments(this.yearlyForm.value.fromYear, this.yearlyForm.value.toYear)
        }
    }
    submitUserTypeUserRevenue(value) {
        this.user_revenue_user_type = value;
        if (this.user_revenue_criteria == 'daily') {
            this.getDailyUserRevenues(this.selectedDailyMonthUserRevenues, this.revenueDailyYearForm.value.selectedDailyYear);

        } else if (this.user_revenue_criteria == 'monthly') {
            this.getMonthlyUserRevenues(this.revenueMonthlyYearForm.value.selectedMonthlyYear);

        } else {
            this.getYearlyUserRevenues(this.revenueYearlyForm.value.fromYear, this.revenueYearlyForm.value.toYear)
        }
    }
    submitCriteriaUserRevenue(value) {
        this.user_revenue_criteria = value;
        console.log(value);

        if (this.user_revenue_criteria == 'daily') {
            this.getDailyUserRevenues(this.selectedDailyMonthUserRevenues, this.revenueDailyYearForm.value.selectedDailyYear);

        } else if (this.user_revenue_criteria == 'monthly') {
            this.getMonthlyUserRevenues(this.revenueMonthlyYearForm.value.selectedMonthlyYear);

        } else {
            this.getYearlyUserRevenues(this.revenueYearlyForm.value.fromYear, this.revenueYearlyForm.value.toYear)
        }
    }
    submitCountryCountryRevenue(value) {
        this.country_revenue_country = value;
        if (this.country_revenue_criteria == 'daily') {
            this.getDailyCountryRevenues(this.selectedDailyMonthCountryRevenues, this.countryDailyYearForm.value.selectedDailyYear);

        } else if (this.country_revenue_criteria == 'monthly') {
            this.getMonthlyCountryRevenues(this.countryMonthlyYearForm.value.selectedMonthlyYear);

        } else {
            this.getYearlyCountryRevenues(this.countryYearlyForm.value.fromYear, this.countryYearlyForm.value.toYear)
        }
    }
    submitCriteriaCountryRevenue(value) {
        this.country_revenue_criteria = value;
        if (this.country_revenue_criteria == 'daily') {
            this.getDailyCountryRevenues(this.selectedDailyMonthCountryRevenues, this.countryDailyYearForm.value.selectedDailyYear);

        } else if (this.country_revenue_criteria == 'monthly') {
            this.getMonthlyCountryRevenues(this.countryMonthlyYearForm.value.selectedMonthlyYear);

        } else {
            this.getYearlyCountryRevenues(this.countryYearlyForm.value.fromYear, this.countryYearlyForm.value.toYear)
        }
    }
    submitCriteriaLocalizedExp(value) {
        this.localized_experiences_criteria = value;
        this.getLocalizedExperiences();
    }
    callEnrollmentsChart() {
        if (this.enrollChart) {
            this.enrollChart.clear();
            this.enrollChart.destroy();
        }
        const ctxAdminEnrollmentCanvas = this.adminEnrollmentCanvasRef.nativeElement.getContext('2d');
        var gradientFill = ctxAdminEnrollmentCanvas.createLinearGradient(0, 0, 0, 140);
        gradientFill.addColorStop(0, "rgba(255, 40, 21, 0.5)");
        gradientFill.addColorStop(1, "rgba(255, 40, 21, 0)");

        this.enrollChart = new Chart(ctxAdminEnrollmentCanvas, {
            type: 'line',
            data: {
                labels: this.enrollments_labels,
                datasets: [
                    {
                        data: this.enrollments_values,
                        borderColor: "#ff2815",
                        backgroundColor: gradientFill,
                        borderWidth: 3,
                    }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        left: 10,
                        right: 10,
                        top: 0,
                        bottom: 0
                    }
                },
                animation: {
                    easing: "easeInOutQuart"
                },
                title: {
                    display: false,
                    text: 'Total Enrollments',
                    position: 'top'
                },
                legend: {
                    display: false,
                    position: 'bottom'
                },
                scales: {
                    xAxes: [{
                        display: true,
                        gridLines: {
                            zeroLineColor: "transparent",
                            color: 'rgba(0, 0, 0, 0.05)',
                        },
                        ticks: {
                            padding: 20,
                            fontColor: "rgba(0,0,0,0.5)",
                            fontStyle: "regular"
                        }
                    }],
                    yAxes: [{
                        display: true,
                        ticks: {
                            fontColor: "rgba(0,0,0,0.5)",
                            fontStyle: "regular",
                            maxTicksLimit: 5,
                            padding: 20
                        },
                        gridLines: {
                            drawTicks: false,
                            display: false
                        }


                    }],
                }
            }
        });
    }
    callUserRevenuesChart() {
        if (this.userRevenueChart) {
            this.userRevenueChart.clear();
            this.userRevenueChart.destroy();
        }
        const ctxAdminUserRevenueCanvas = this.adminUserRevenueCanvasRef.nativeElement.getContext('2d');
        var gradientFill = ctxAdminUserRevenueCanvas.createLinearGradient(0, 0, 0, 140);
        gradientFill.addColorStop(0, "rgba(255, 40, 21, 0.5)");
        gradientFill.addColorStop(1, "rgba(255, 40, 21, 0)");

        this.userRevenueChart = new Chart(ctxAdminUserRevenueCanvas, {
            type: 'line',
            data: {
                labels: this.revenue_user_labels,
                datasets: [
                    {
                        label: "Revenue ($)",
                        data: this.revenue_user_values,
                        borderColor: "#ff2815",
                        backgroundColor: gradientFill,
                        borderWidth: 3,
                    }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        left: 10,
                        right: 10,
                        top: 0,
                        bottom: 0
                    }
                },
                animation: {
                    easing: "easeInOutQuart"
                },
                title: {
                    display: false,
                    text: 'Total Revenues',
                    position: 'top'
                },
                legend: {
                    display: false,
                    position: 'bottom'
                },
                scales: {
                    xAxes: [{
                        display: true,
                        gridLines: {
                            zeroLineColor: "transparent",
                            color: 'rgba(0, 0, 0, 0.05)',
                        },
                        ticks: {
                            padding: 20,
                            fontColor: "rgba(0,0,0,0.5)",
                            fontStyle: "regular"
                        }
                    }],
                    yAxes: [{
                        display: true,
                        ticks: {
                            fontColor: "rgba(0,0,0,0.5)",
                            fontStyle: "regular",
                            maxTicksLimit: 5,
                            padding: 20
                        },
                        gridLines: {
                            drawTicks: false,
                            display: false
                        }


                    }],
                }
            }
        });
    }
    callCountryRevenuesChart() {
        if (this.countryRevChart) {
            this.countryRevChart.clear();
            this.countryRevChart.destroy();
        }
        const ctxAdminCountryRevenueCanvas = this.adminCountryRevenueCanvasRef.nativeElement.getContext('2d');
        var gradientFill = ctxAdminCountryRevenueCanvas.createLinearGradient(0, 0, 0, 140);
        gradientFill.addColorStop(0, "rgba(255, 40, 21, 0.5)");
        gradientFill.addColorStop(1, "rgba(255, 40, 21, 0)");


        this.countryRevChart = new Chart(ctxAdminCountryRevenueCanvas, {
            type: 'line',
            data: {
                labels: this.revenue_country_labels,
                datasets: [
                    {
                        label: "Revenue ($)",
                        data: this.revenue_country_values,
                        borderColor: "#ff2815",
                        backgroundColor: gradientFill,
                        borderWidth: 3,
                    }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        left: 10,
                        right: 10,
                        top: 0,
                        bottom: 0
                    }
                },
                animation: {
                    easing: "easeInOutQuart"
                },
                title: {
                    display: false,
                    text: 'Total Country Revenues',
                    position: 'top'
                },
                legend: {
                    display: false,
                    position: 'bottom'
                },
                scales: {
                    xAxes: [{
                        display: true,
                        gridLines: {
                            zeroLineColor: "transparent",
                            color: 'rgba(0, 0, 0, 0.05)',
                        },
                        ticks: {
                            padding: 20,
                            fontColor: "rgba(0,0,0,0.5)",
                            fontStyle: "regular"
                        }
                    }],
                    yAxes: [{
                        display: true,
                        ticks: {
                            fontColor: "rgba(0,0,0,0.5)",
                            fontStyle: "regular",
                            maxTicksLimit: 5,
                            padding: 20
                        },
                        gridLines: {
                            drawTicks: false,
                            display: false
                        }


                    }],
                }
            }
        });
    }
    callLocalizedExpChart() {
        if (this.expChart) {
            this.expChart.clear();
            this.expChart.destroy();
        }
        const ctxAdminLocalizedExperienceCanvas = this.adminLocalizedExperienceCanvasRef.nativeElement.getContext('2d');
        var gradientFill = ctxAdminLocalizedExperienceCanvas.createLinearGradient(0, 0, 0, 140);
        gradientFill.addColorStop(0, "rgba(255, 40, 21, 0.5)");
        gradientFill.addColorStop(1, "rgba(255, 40, 21, 0)");

        this.expChart = new Chart(ctxAdminLocalizedExperienceCanvas, {
            type: 'bar',
            data: {
                labels: this.localized_exp_labels,
                datasets: [
                    {
                        label: "Localized Experiences",
                        data: this.localized_exp_values,
                        borderColor: "#ff2815",
                        backgroundColor: gradientFill,
                        borderWidth: 3,
                    }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        left: 10,
                        right: 10,
                        top: 0,
                        bottom: 0
                    }
                },
                animation: {
                    easing: "easeInOutQuart"
                },
                title: {
                    display: false,
                    text: 'Total Localized',
                    position: 'top'
                },
                legend: {
                    display: false,
                    position: 'bottom'
                },
                scales: {
                    xAxes: [{
                        display: true,
                        gridLines: {
                            zeroLineColor: "transparent",
                            color: 'rgba(0, 0, 0, 0.05)',
                        },
                        ticks: {
                            padding: 20,
                            fontColor: "rgba(0,0,0,0.5)",
                            fontStyle: "regular"
                        }
                    }],
                    yAxes: [{
                        display: true,
                        ticks: {
                            fontColor: "rgba(0,0,0,0.5)",
                            fontStyle: "regular",
                            maxTicksLimit: 5,
                            padding: 20,
                            beginAtZero: true

                        },
                        gridLines: {
                            drawTicks: false,
                            display: false
                        }


                    }],
                }
            }
        });
    }
}
