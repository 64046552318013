import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';
import { StatsService } from '../services/stats.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    // chart;
    // enrollments = [];
    // data;
    // revenue_user_type = 'student';
    // revenue_criteria = 'monthly';
    // userRevenueForm: FormGroup
    // @ViewChild('canvas', { static: false }) canvasRef: ElementRef;
    // @ViewChild('canvas3', { static: false }) canvasRef3: ElementRef;

    // constructor(private service: StatsService,
    //     private fb: FormBuilder,
    //     private sb: MatSnackBar
    // ) { }

    ngOnInit() {
    //     this.get();
    //     this.revenue_user_type = 'student';
    //     this.revenue_criteria = 'monthly';
    //     this.userRevenueForm = this.fb.group({
    //         user_type: ['student'],
    //         criteria: ['monthly'],
    //     });

    }
    // get() {
    //     this.service.getEnrollments(this.revenue_user_type, this.revenue_criteria).subscribe(res => {
    //         this.enrollments = res.data.data;
    //         console.log(res.data);
    //         this.callEnrollmentChart();
    //     });

    //     this.service.getLocalizedExperiences().subscribe(res => {
    //         // this.data = res.data.data;
    //         console.log(res.data);
    //     });
    //     this.service.getRevenues(this.revenue_user_type, this.revenue_criteria).subscribe(res => {
    //         console.log(res);
    //         this.sb.open("Stats updated", 'Ok', {
    //             duration: 2000,
    //         });
    //         this.revenueChart();

    //     });

    // }
    // submitUserTypeRevenue(value) {
    //     console.log('status changed', value);
    //     this.revenue_user_type = value;
    //     console.log('params', this.revenue_user_type, this.revenue_criteria);
    //     this.service.getRevenues(this.revenue_user_type, this.revenue_criteria).subscribe(res => {
    //         console.log(res);
    //         this.sb.open("Stats updated", 'Ok', {
    //             duration: 2000,
    //         });
    //     });
    // }
    // submitCriteriaRevenue(value) {
    //     console.log('status changed', value);
    //     this.revenue_criteria = value;
    //     console.log(this.revenue_user_type, this.revenue_criteria);
    //     this.service.getRevenues(this.revenue_user_type, this.revenue_criteria).subscribe(res => {
    //         console.log(res);
    //         this.sb.open("Stats updated", 'Ok', {
    //             duration: 2000,
    //         });
    //     });

    // }

    // callEnrollmentChart() {

    //     const ctxCanvas = this.canvasRef.nativeElement.getContext('2d');
    //     this.chart = new Chart(ctxCanvas, {
    //         type: 'line',
    //         data: {
    //             labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    //             datasets: [
    //                 {
    //                     data: [0, 0, 0, 0, 0, 0, 0, 23, 9, 7, 0, 0],
    //                     borderColor: "#3cba9f",
    //                     fill: false
    //                 },
    //             ]
    //         },
    //         options: {
    //             responsive: true,
    //             maintainAspectRatio: false,

    //             title: {
    //                 display: true,
    //                 text: 'Total Revenues',
    //                 position: 'top'
    //             },
    //             legend: {
    //                 display: false,
    //                 position: 'bottom'
    //             },
    //             scales: {
    //                 xAxes: [{
    //                     display: true,

    //                 }],
    //                 yAxes: [{
    //                     display: true,
    //                     gridLines: {
    //                         drawTicks: false,
    //                         display: false
    //                     }

    //                 }],
    //             }
    //         }
    //     });
    // }
    // revenueChart() {
    //     const ctxCanvas3 = this.canvasRef3.nativeElement.getContext('2d');
    //     var gradientFill = ctxCanvas3.createLinearGradient(0, 0, 0, 140);
    //     gradientFill.addColorStop(0, "rgba(56, 0, 60, 0.5)");
    //     gradientFill.addColorStop(1, "white");
    //     this.chart = new Chart(ctxCanvas3, {
    //         type: 'line',
    //         data: {
    //             labels: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    //             datasets: [
    //                 {
    //                     data: [1, 2, 3, 1.5, 4, 6, 2, 2.5],
    //                     borderColor: "#38003c",
    //                     backgroundColor: gradientFill,
    //                     borderWidth: 4,
    //                 }]
    //         },
    //         options: {
    //             responsive: true,
    //             maintainAspectRatio: false,
    //             animation: {
    //                 easing: "easeInOutBack"
    //             },
    //             title: {
    //                 display: true,
    //                 text: 'Custom Chart Title',
    //                 position: 'top'
    //             },
    //             legend: {
    //                 display: false,
    //                 position: 'bottom'
    //             },
    //             scales: {
    //                 xAxes: [{
    //                     display: true,
    //                     gridLines: {
    //                         zeroLineColor: "transparent"
    //                     },
    //                     ticks: {
    //                         padding: 20,
    //                         fontColor: "rgba(0,0,0,0.5)",
    //                         fontStyle: "bold"
    //                     }
    //                 }],
    //                 yAxes: [{
    //                     display: true,
    //                     ticks: {
    //                         fontColor: "rgba(0,0,0,0.5)",
    //                         fontStyle: "bold",
    //                         maxTicksLimit: 5,
    //                         padding: 20
    //                     },
    //                     gridLines: {
    //                         drawTicks: false,
    //                         display: false
    //                     }


    //                 }],
    //             }
    //         }
    //     });
    // }
}
