<form [formGroup]="form" (submit)="submit()">
    <div class="modal">
        <div class="header">
            <mat-icon class="icon" color="accent">error_outline</mat-icon>
            <h2 class="title">{{ dialogData.title }}</h2>
        </div>
        <div class="content">
            <div style="margin-bottom: 1rem">{{ dialogData.description }}</div>
            <mat-form-field *ngIf="data?.with_remarks" style="width: 75%" appearance="outline">
                <textarea matInput placeholder="Remarks" formControlName="remarks"></textarea>
                <mat-error *ngIf="form.get('remarks')?.errors?.required">
                    Remarks is <strong>required</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="action" fxLayoutGap="16px">
            <button [disabled]="form.invalid" mat-raised-button="" color="primary">
                Yes, delete it
            </button>
            <button type="button" mat-dialog-close  mat-raised-button="" color="accent">
                Cancel
            </button>
        </div>
    </div>
</form>
