<mat-toolbar color='primary'>
    <span>{{ data?.id ? "Edit" : "Add"}} Bulk User</span>
    <span fxFlex=""></span>
    <button mat-icon-button="" mat-dialog-close matTooltip="close">
        <mat-icon class="c-w">close</mat-icon>
    </button>
</mat-toolbar>

<form [formGroup]="form" (submit)="submit()" fxLayout="column" fxLayoutGap="10px" class="mat-padding">

    <div fxLayout="row">
        <mat-form-field fxFlex>
            <input matInput type="text" placeholder="File Name" formControlName="batch_name">
            <mat-error *ngIf="form.get('batch_name')?.errors?.required">
                File name is <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </div>

    <div fxLayout="row">
        <!-- <button mat-mini-fab type="button" (click)="fileInput.click()">
            <mat-icon>add</mat-icon>
        </button>
        <span>Add Users</span>
        <input style="display: none" #fileInput type="file" (change)="select($event)" accept="text/csv" /> -->



        <!-- <button *ngIf="selectedFile.length" mat-icon-button (click)="uploadFile()"><mat-icon>cloud_upload</mat-icon></button>
        <button style="margin-top: -16px" mat-icon-button (click)="fileInput.click()"><mat-icon>add</mat-icon></button> -->
        <input #fileInput type="file" (change)="select($event)" accept="text/csv," formControlName="bulk_users" />

        <!-- <span class="file-info">{{ fileInfo }}</span> -->

    </div>
    <div fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="10px">
        <button type="button" (click)="form.reset()" mat-raised-button="" color="accent">
            Reset
        </button>
        <button [disabled]="form.invalid" mat-raised-button="" color="primary">
            Save
        </button>
    </div>
</form>