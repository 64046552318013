import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileManagerComponent } from '../file-manager.component';
import { FileManagerService } from '../file-manager.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.styl']
})
export class ImageComponent implements OnInit {
    token;

    public image;
    @Output() deleted = new EventEmitter();

    constructor(public service: FileManagerService,
        private authService: AuthService,
    ) {
    }

    @Input() id: number;
    @Input() size: string;

    ngOnInit() {
        this.getURL();
    }

    getURL() {
        this.token = this.authService.getToken();


    }


    delete(id) {
        this.service.delete(id, '/api/attachables').subscribe(res => {
            this.deleted.emit();
        });
    }

}
