export enum LayoutActionTypes {
    SHOW_LOADER = '[Layout] Show Loader',
    HIDE_LOADER = '[Layout] Hide Loader',
    SHOW_SEARCH = '[Layout] Show Search',
    HIDE_SEARCH = '[Layout] Hide Search',
    SET_USER = '[Layout] Set User',
    SET_MENU = '[Layout] Set Menu',
    SET_PROFILE = '[Layout] Set Profile',
    DOWNLOAD_URL = '[Layout] download url',
}

export class ShowLoader {
    static readonly type = LayoutActionTypes.SHOW_LOADER;
}

export class HideLoader {
    static readonly type = LayoutActionTypes.HIDE_LOADER;
}

export class ShowSearch {
    static readonly type = LayoutActionTypes.SHOW_SEARCH;
}

export class HideSearch {
    static readonly type = LayoutActionTypes.HIDE_SEARCH;
}

export class SetUser {
    static readonly type = LayoutActionTypes.SET_USER;
    constructor(public payload: string) {
    }
}

export class SetMenu {
    static readonly type = LayoutActionTypes.SET_MENU;

    constructor(public payload: string) {
    }
}

export class SetProfile {
    static readonly type = LayoutActionTypes.SET_PROFILE;

    constructor(public payload) {
    }
}

export class SetDownloadUrl {
    static readonly type = LayoutActionTypes.DOWNLOAD_URL;
    constructor(public payload: string) {
    }
}
