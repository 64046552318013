import {Action, State, StateContext} from '@ngxs/store';
import {HideLoader, HideSearch, SetUser, ShowLoader, ShowSearch, SetProfile, SetMenu, SetDownloadUrl} from '../actions/layout.action';

export interface LayoutStateI {
    show_loader?: boolean;
    show_search?: boolean;
    user?: any;
    menu?: any;
    profile?: any;
    download_url?: any;
}

const DefaultLayoutState: LayoutStateI = {
    show_loader: false,
    show_search: false,
    user: null,
    menu: [],
    profile: null
};
​
@State<LayoutStateI>({
    name: 'layout',
    defaults: DefaultLayoutState
})
export class LayoutState {
    @Action(ShowLoader)
    showLoader({patchState}: StateContext<LayoutStateI>) {
        patchState({show_loader: true});
    }

    @Action(HideLoader)
    hideLoader({patchState}: StateContext<LayoutStateI>) {
        patchState({show_loader: false});
    }

    @Action(ShowSearch)
    showSearch({patchState}: StateContext<LayoutStateI>) {
        patchState({show_search: true});
    }

    @Action(HideSearch)
    hideSearch({patchState}: StateContext<LayoutStateI>) {
        patchState({show_search: false});
    }

    @Action(SetUser)
    setUser({patchState}: StateContext<LayoutStateI>, {payload}: SetUser) {
        patchState({user: payload});
    }

    @Action(SetMenu)
    setMenu({patchState}: StateContext<LayoutStateI>, {payload}: SetMenu) {
        patchState({menu: payload});
    }

    @Action(SetProfile)
    setAddress({patchState}: StateContext<LayoutStateI>, {payload}: SetProfile) {
        patchState({profile: payload});
    }

    @Action(SetDownloadUrl)
    setDownloadUrl({patchState}: StateContext<LayoutStateI>, {payload}: SetDownloadUrl) {
        patchState({download_url: payload});
    }
}
