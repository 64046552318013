import { Component, OnInit, Inject } from '@angular/core';
import { FileManagerService } from '../file-manager/file-manager.service';
import { ExperienceService } from '../../services/experience.service';
import { FormBuilder, Validators, FormGroup, FormControl, FormArray } from '@angular/forms';
import { DeleteDialogComponent } from '../delete/delete-dialog/delete-dialog.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-uploader-form',
    templateUrl: './uploader-form.component.html',
    styleUrls: ['./uploader-form.component.scss']
})
export class UploaderFormComponent implements OnInit {
    public uploadedFile;
    public files;
    images;
    attachables;
    form: FormGroup;


    constructor(public dialogRef: MatDialogRef<UploaderFormComponent>,

        @Inject(MAT_DIALOG_DATA) public data: any,
        public fileManagerService: FileManagerService,
        public dialog: MatDialog,
        public service: ExperienceService,
        private sb: MatSnackBar,
        private fb: FormBuilder

    ) { }
    ngOnInit() {
        if (this.data.id) {
            this.getAttachables(this.data.id)

        }
        this.form = this.fb.group({
            crc: [0, Validators.required],
            size: [0, Validators.required],
            version: [0, Validators.required],
            title: [null, Validators.required],
            status: [false,],
            featured: [false,],
        });

    }
    get_files(e) {
        this.sb.open('File was uploaded successfully', 'Ok', {
            duration: 2000,
        });
        this.uploadedFile = e.data;
        this.getAttachables(this.data.id);
        if (e.data.platform == 'ios') {
            this.attachables.asset_bundle_ios = e.data;

        }
        else if (e.data.platform == 'android') {
            this.attachables.asset_bundle_android = e.data;

        }
        const params = {
            attachable_type: 'Experience',
            attachable_id: this.data.id,
        };
        this.fileManagerService.list(params, '/api/attachables/').subscribe(res => {
            this.files = res.data;
        });
    }
    togglePlatform(platform) {
        if (this.attachables.asset_bundle_android) {
            this.attachables.asset_bundle_android.edit_ready = false;
        }
        if (this.attachables.asset_bundle_ios) {
            this.attachables.asset_bundle_ios.edit_ready = false;
        }
        if (this.attachables.image) {
            this.attachables.image.edit_ready = false;
        }
        // this.attachables.asset_bundle_android.edit_ready = false;
        // this.attachables.asset_bundle_ios.edit_ready = false
        // this.attachables.image.edit_ready = false;

    }
    ImageUploaded(ev) {
        this.data.images = ev.data;

        this.sb.open('Image was uploaded successfully', 'Ok', {
            duration: 2000,
        });
        if (ev.data.type == 'image') {
            this.attachables.image = ev.data;

        }
    }
    read_edit(file) {
        file.edit_ready = true;
        this.form.patchValue(file);

    }

    save_edit(file) {


        this.fileManagerService.updateDetails(file.id, this.form.value).subscribe(res => {
            this.dialogRef.close(res);
            file.edit_ready = false;
        });
    }
    getAttachables(id) {
        this.service.getAttachables(id).subscribe(res => {
            this.attachables = res.data;
            if (this.attachables.asset_bundle_ios) {
                // this.form.addControl('id', new FormControl(this.data.id));
                this.form.patchValue(this.attachables.asset_bundle_ios);
            }
            else if (this.attachables.asset_bundle_android) {
                // this.form.addControl('id', new FormControl(this.data.id));
                this.form.patchValue(this.attachables.asset_bundle_android);
            }
        });

    }

    delete_file(file) {
        const dialogRef = this.dialog.open(DeleteDialogComponent, {
            width: '500px',
            data: {
                api: 'attachables',
                with_remarks: false,
                id: file.id,
                title: 'Do you want to delete this',
                description: 'This will remove the attachment'
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            this.getAttachables(this.data.id);
            if (result.message) {
                this.sb.open(result.message, 'Ok', {
                    duration: 2000,
                });
            }

        });

    }
    getImages(id) {
        this.fileManagerService.getImages(id).subscribe(res => {
            this.attachables = res.data;
        });
    }
    submit(file) {
        this.fileManagerService.updateDetails(file.id, this.form.value).subscribe(res => {
            this.dialogRef.close(res);
            file.edit_ready = false;
        });
    }

}

