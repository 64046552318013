import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { delay, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RoleGuardGuard implements CanActivate {
    roles;
    path: ActivatedRouteSnapshot[];
    route: ActivatedRouteSnapshot;

    constructor(public auth: AuthService, public router: Router, public userService: UserService) {
        this.userService.getPermissions();
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        console.log(next);
        // return true;
        if (next.data.expectedRole === '*') {
            return true;
        }
        return this.userService.roles
            .pipe(
                // tap(i => console.log(i)),
                // map(roles => {
                //     if (!roles.length) {
                //         console.log(roles+"asasa");
                //         return this.userService.getPermissions();
                //     }
                //     console.log('permission loaded');
                //
                //     return roles;
                // }),
                // delay(5000),
                map((roles: any[]) => {
                    console.log(roles);
                    const can = roles.includes(next.data.expectedRole);
                    console.log(can);
                    console.log(this.userService.user_type);
                    if (can) {
                        return true;
                    }
                    // if (this.userService.user_type === 'this.userService.user_type') {
                    //     this.router.navigateByUrl('/dashboard');
                    //     return can;


                    // }
                    // if (this.userService.user_type === 'school') {
                    //     this.router.navigateByUrl('/school-dashboard');
                    //     return can;

                    // }
                    // if (this.userService.user_type === 'admin') {
                    //     this.router.navigateByUrl('/');
                    //     return can;

                    // }

                    this.router.navigateByUrl('/');
                    return can;

                })
            );
        return false;
        // if (this.auth.isLoggedIn() && this.roles.length == 0) {
        //     this.userService.roles.subscribe(res => {
        //         this.roles = res;
        //         // res.map(roles => {
        //         //     this.roles.push(roles);
        //         // })
        //         console.log('roles not fetched yet', this.roles);

        //     });
        // }
        // if (!this.auth.isLoggedIn() || !this.roles.includes(next.data.expectedRole)
        // ) {
        //     console.log('not allowed');
        //     this.router.navigate(['login']);
        //     return false;
        // }
        // return true;
        // if (this.auth.isLoggedIn()) {
        //     if (this.roles.length == 0) {
        //         this.userService.roles.subscribe(res => this.roles = res)
        //     }
        //     if (this.roles.includes(next.data.expectedRole)) {
        //         console.log('canView');
        //         return true;
        //     }
        // }
        // else {
        //     console.log('not allowed');
        //     return false;
        // }

    }


}

//     // canActivate(
//     //     route: ActivatedRouteSnapshot,
//     //     state: RouterStateSnapshot
//     // ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
//     // route: ActivatedRouteSnapshot): boolean
//     {
//         // let menuLinks = [];
//         // this.userService.menu
//         // .subscribe(menus => {
//         //     menus.map(m => {
//         //         menuLinks.push(m.link)
//         //     })

//         //     if(menuLinks.length) {
//         //         return menuLinks.includes(next.data.expectedRole);
//         //     }
//         //     return true;
//         // });
//         console.log(state);
//         // by MANDHu
//         // this.userService.roles.subscribe(roles => {
//         //     if(roles.length) {
//         //         console.log(roles)
//         //         console.log(next.data.expectedRole)
//         //         const can = roles.includes(next.data.expectedRole);
//         //         console.log(can);
//         //         this.router.navigate([state.url])
//         //         return can;
//         //         // if(!can) {
//         //         //     return false;
//         //         // }
//         //         // this.router.navigate(['/']);
//         //     }
//         // });


//         // return true;
//         // this will be passed from the route config
//         // on the data property
//         // const expectedRole = next.data.expectedRole;
//         // // const token = localStorage.getItem('token');
//         // // decode the token to get its payload
//         // // const tokenPayload = decode(token);
//         // this.userService.roles.subscribe(res => {
//         //     this.roles = res;
//         //     // res.map(roles => {
//         //     //     this.roles.push(roles);
//         //     // })
//         //     console.log(this.roles);

//         // });


//         if (this.auth.isLoggedIn() && this.roles.length == 0) {
//             this.userService.roles.subscribe(res => {
//                 this.roles = res;
//                 // res.map(roles => {
//                 //     this.roles.push(roles);
//                 // })
//                 console.log('roles not fetched yet', this.roles);

//             });
//         }

//         // // this.roles.includes(expectedRole);
//         // // console.log(this.roles.includes(expectedRole));
//         if (!this.auth.isLoggedIn() || !this.roles.includes(next.data.expectedRole)
//         ) {
//             console.log('not allowed');
//             // this.router.navigate(['login']);
//             // return false;
//         }
//         return true;
//     }

