import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { DeleteButtonComponent } from './delete/delete-button/delete-button.component';
import { DeleteDialogComponent } from './delete/delete-dialog/delete-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ImageComponent } from './file-manager/image/image.component';
import { FileManagerComponent } from './file-manager/file-manager.component';
import { PhotoComponent } from './file-manager/photo/photo.component';
import { RouterModule } from '@angular/router';
import { SearchComponent } from './search/search.component';
import { FilterPipe } from '../pipes/filter.pipe';
import { StrReplacePipe } from '../pipes/str-replace.pipe';
import { MasterSearchComponent } from './master-search/master-search.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import { FilePondModule, registerPlugin } from 'ngx-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import { UploaderFormComponent } from './uploader-form/uploader-form.component';
import { FilesComponent } from './file-manager/files/files.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { PermissionDirective } from '../directives/permission.directive';
import { AddFollowingFormComponent } from './add-following-form/add-following-form.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { BulkUserComponent } from './bulk-user/bulk-user.component';
import { UploadBulkUsersFormComponent } from './upload-bulk-users-form/upload-bulk-users-form.component';
import { UsersListFormComponent } from './users-list-form/users-list-form.component';
import { ShimmerLoadingComponent } from './shimmer-loading/shimmer-loading.component';
import { EmptyContainerComponent } from './empty-container/empty-container.component';
import {CdkScrollableModule} from '@angular/cdk/scrolling';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginImageResize,
    FilePondPluginFileValidateType);

const modules = [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FlexLayoutModule,
    FilePondModule,
    RouterModule,
    NgxMatSelectSearchModule,
    AngularEditorModule,


];

@NgModule({
    declarations: [
        DeleteButtonComponent,
        DeleteDialogComponent,
        ImageComponent,
        FileManagerComponent,
        PhotoComponent,
        SearchComponent,
        FilterPipe,
        FilesComponent,
        StrReplacePipe,
        MasterSearchComponent,
        UploaderFormComponent,
        PermissionDirective,
        AddFollowingFormComponent,
        PageNotFoundComponent,
        BulkUserComponent,
        UploadBulkUsersFormComponent,
        UsersListFormComponent,
        ShimmerLoadingComponent,
        EmptyContainerComponent,


    ],
    imports: [modules],
    exports: [
        ...modules,
        DeleteButtonComponent,
        DeleteDialogComponent,
        ImageComponent,
        FileManagerComponent,
        FilesComponent,
        PhotoComponent,
        SearchComponent,
        FilterPipe,
        StrReplacePipe,
        MasterSearchComponent,
        PermissionDirective,
        PageNotFoundComponent,
        UploadBulkUsersFormComponent,
        UsersListFormComponent,
        ShimmerLoadingComponent,
        EmptyContainerComponent,
        CdkScrollableModule

    ],
    entryComponents: [
        DeleteDialogComponent,
        FileManagerComponent,
        UploaderFormComponent,
        AddFollowingFormComponent,
        UploadBulkUsersFormComponent,
        UsersListFormComponent,

    ]
})
export class SharedModule { }
