import {Injectable} from '@angular/core';
import {NxDataService} from '../../decorators/NxDataService';
import {HttpClient} from '@angular/common/http';


export interface FileManagerService extends NxDataService<any> {
    test;
}

@Injectable({
    providedIn: 'root'
})
@NxDataService({
    url: '/api/attachables'
})

export class FileManagerService {
    constructor(public http: HttpClient) {
    }
    public getImages(id) {
        return this.http.get<any>(`/api/attachables/${id}/download_attachment`);
    }

    public updateDetails(id,data) {
        return this.http.put(`/api/attachables/${id}/details`, data);
    }
}
