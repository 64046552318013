import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { NxDataService, NxResponse } from '../decorators/NxDataService';
import { Router } from '@angular/router';
import { BehaviorSubject, pipe } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

export interface UserService extends NxDataService<any> {
    test;
}
@Injectable({
    providedIn: 'root'
})

@NxDataService({
    url: '/api/users'
})
export class UserService {

    title: BehaviorSubject<string> = new BehaviorSubject<string>('Dashboard');
    permissions: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    menu: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    countries: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    roles: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    user_type;
    constructor(private http: HttpClient,
        private router: Router) {
    }

    static get user() {
        return JSON.parse(localStorage.getItem('user'));
    }

    login(data) {
        return this.http.post<NxResponse<any>>('/api/dashboardLogin', data);
    }

    switchOffice(officeId) {
        return this.http.post<NxResponse<any>>('/api/users/switch_office', { officeId });
    }

    register(data) {
        return this.http.post('/api/register', data);
    }

    updateAttendance(data) {
        return this.http.post<NxResponse<any>>(`/api/users/${data._id}/attendance`, { ...data });
    }

    togglePresence(item) {
        item.toggle_presence = true;
        return this.http.patch('/api/staff/' + item.id, item);
    }

    selectCategory(item, category) {
        item.set_category = category;
        return this.http.patch('/api/staff/' + item.id, item);
    }

    isLoggedIn() {
        return (localStorage.getItem('token') !== null) && (localStorage.getItem('token') !== 'null');
    }

    logout(): void {
        localStorage.setItem('token', null);
        this.menu = new BehaviorSubject<string[]>([]);
        this.permissions = new BehaviorSubject<string[]>([]);
        this.roles = new BehaviorSubject<string[]>([]);


        this.router.navigate(['/login']);
    }

    getPermissions() {
        this.loadPermissions()
            .subscribe((res: any) => {
                this.user_type = res.data.user.user_type;
                this.permissions.next(res.data.permissions);
                this.menu.next(res.data.menu);
                this.roles.next(res.data.roles);
                if (res.data.user.user_type == 'region_manager') {
                    this.countries.next(res.data.countries);
                }
                if (res.data.user.user_type == 'school') {
                    this.countries.next(res.data.countries);
                }
            });
    }

    loadPermissions() {
        return this.http.get<any>('/api/dashboardMe')
            .pipe(
                shareReplay()
            );
    }

    setTitle(title) {
        this.title.next(title);
    }
    public getUserDetails(): string {
        return localStorage.getItem('user');
    }
    public updateRegionManager(data, id) {
        if (data.id) {
            return this.http.put(`/api/region_managers/${id}`, data);
        }
    }
    public updateSchool(data, id) {
        if (data.id) {
            return this.http.put(`/api/schools/${id}`, data);
        }
    }

     public setUserType(user_type){
        this.user_type = user_type;
     }



}
